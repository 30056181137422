import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from "react-notifications";
import ActionCreators from "../../actions";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { ProvideSolution } from "../Forms";

import { Layout, Modal } from "antd";

class ProvideSolutionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visibleModal: false,
      customPriceSymbol: "$",
      error: "",
      pickUpAddress: "",
      pickUpCity: "",
      pickUpRegion: "",
      pickUpDate: "",
      pickUpCountry: "",
      deliveryAddress: "",
      deliveryCity: "",
      deliveryRegion: "",
      deliveryDate: "",
      deliveryCountry: "",
      deliveryPostal: "",
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    this.setState({
      lng: state.lng,
      lat: state.lat,
      category: state.category,
      categoryData: state.categoryData,
    });
  }

  handleSelect = (e) => {
    this.setState({
      customPriceSymbol: e,
    });
  };

  handleSubmit = async () => {
    const {
      lat,
      lng,
      category,
      pickUpAddress,
      pickUpCity,
      pickUpRegion,
      pickUpPostal,
      pickUpCountry,
      pickUpDate,
      deliveryAddress,
      deliveryCity,
      deliveryRegion,
      deliveryPostal,
      deliveryCountry,
      deliveryDate,
      categoryData,
      description,
      customPriceSymbol,
      customPrice,
    } = this.state;
    const params = {
      category: category,
      en: categoryData && categoryData.desp.en,
      fr: categoryData && categoryData.desp.fr,
      pickup_street_address: pickUpAddress,
      pickup_city: pickUpCity,
      display_price: `${customPriceSymbol} ${customPrice}`,
      pickup_region: pickUpRegion,
      pickup_date: pickUpDate,
      pickup_country: pickUpCountry,
      sub_category_price_dollar: categoryData && categoryData.dollar_price,
      sub_category_price_euro: categoryData && categoryData.euro_price,
      sub_category_price_fr: categoryData && categoryData.fr_price,
      pickup_postal_address: pickUpPostal,
      pickup_lat: lat,
      pickup_lng: lng,
      delivery_street_address: deliveryAddress,
      delivery_city: deliveryCity,
      delivery_region: deliveryRegion,
      delivery_date: deliveryDate,
      delivery_country: deliveryCountry,
      delivery_postal_address: deliveryPostal,
      user_role: "provideSolution",
      user: this.props.userId,
      auth_key: this.props.auth_key,
      description: description,
    };
    const data = new FormData();

    if (this.state.selectedFile) {
      Object.values(this.state.selectedFile).map((file) => {
        return data.append("file", file);
      });
    }
    data.append("new_solution", JSON.stringify(params));
    data.append("user", this.props.userId);
    data.append("auth_key", this.props.auth_key);
    if (
      category === "Equipments" ||
      category === "Space" ||
      category === "Services"
    ) {
      if (
        pickUpAddress.length < 0 ||
        pickUpCity.length < 0 ||
        pickUpRegion.length < 0 ||
        pickUpDate ||
        pickUpCountry.length < 0
      ) {
        await this.props.solution(data, NotificationManager);
        this.setState({
          visibleModal: true,
        });
      } else {
        this.setState({
          error: "Please fill the fields",
        });
      }
    } else {
      if (
        category.length < 0 ||
        pickUpAddress.length < 0 ||
        pickUpCity.length < 0 ||
        pickUpRegion.length < 0 ||
        pickUpDate ||
        pickUpCountry.length < 0 ||
        deliveryAddress.length < 0 ||
        deliveryCity.length < 0 ||
        deliveryRegion.length < 0 ||
        deliveryDate ||
        deliveryCountry.length < 0 ||
        deliveryPostal.length < 0
      ) {
        await this.props.solution(data, NotificationManager);
        this.setState({
          visibleModal: true,
        });
      } else {
        this.setState({
          error: "Please fill the fields",
        });
      }
    }
  };

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: moment(date).format("YYYY-MM-DD"),
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state);
      }
    );
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onFilesChange = (e) => {
    let files = e.target.files // create file object
    if (files.length > 4) {
      const msg = 'Only 4 images can be uploaded at a time';
      e.target.value = null;
      alert(msg);
      return false;
    }
    this.setState({ selectedFile: e.target.files });
  };

  disabledDates = (date) => {
    return date < moment(this.state.pickUpDate).endOf("day");
  };

  handleOk = () => {
    const Link = `mailto:?subject=Pickant App&body=Hi,I created a post in the pickantapp Link: ${process.env.REACT_APP_FRONTEND_URL}/supplierdetails/${this.props.solutionData && this.props.solutionData._id}/${this.props.solutionData && this.props.solutionData.user}`;
    if (window.innerWidth > 500) {
      window.open(Link);
    } else {
      if (navigator.share) {
        navigator
          .share({
            title: "Pickantapp",
            text: "Check out my post in pickantapp.",
            // url: `${process.env.REACT_APP_FRONTEND_URL}/supplierdetails/${this.props.solutionData && this.props.solutionData._id}/${this.props.solutionData && this.props.solutionData.user}`,
            url: `${process.env.REACT_APP_FRONTEND_URL}`,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    }
  };

  handleCancel = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout style={{ backgroundColor: "green" }}>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            headingTitle={
              localStorage.getItem("language") === "fr-ca"
                ? "Fournir une solution"
                : "Provide a Solution"
            }
          />
          <ProvideSolution
            {...this.state}
            error={this.state.error}
            handleSelect={this.handleSelect}
            loading={this.props.loading}
            onFilesChange={this.onFilesChange}
            handleChangeDate={this.handleChangeDate}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            category={this.state.category}
            disabledDates={this.disabledDates}
            selectedFile={this.state.selectedFile}
          />
        </Layout>
        {this.props.solutionData &&
          this.props.solutionData._id &&
          this.props.solutionData &&
          this.props.solutionData.user ? (
          <Modal
            title="Share"
            visible={this.state.visibleModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Share"
            cancelText="Back to Dashboard"
          >
            {this.props.loading && this.props.loading ? (
              <h3>
                <FormattedMessage id="loading..." defaultMessage="LOADING..." />
              </h3>
            ) : (
              <>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>Link: </p>
                {/* {process.env.REACT_APP_FRONTEND_URL}/supplierdetails/{this.props.solutionData && this.props.solutionData._id}/{this.props.solutionData && this.props.solutionData.user} */}
                {process.env.REACT_APP_FRONTEND_URL}
              </>
            )}
          </Modal>
        ) : null}
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userId: state.auth.currentUser._id,
    auth_key: state.auth.currentUser.auth_key,
    loading: state.solutions.loading,
    solutionData: state.solutions.solution,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProvideSolutionComponent);
