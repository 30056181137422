import React from 'react';
import { Modal, Button } from 'antd';
import { countries } from '../../../../mockData';
import { FormattedMessage } from 'react-intl';

import './styles.css';

const CountryModal = ({
  visible,
  handleModal,
  // handleChangecodeVerification,
  // handleSubmitCode,
  // handleCancelBtn,
  selectedCountry,
  selectCountry,
}) => {
  return (
    <div className="countryModal">
      <Modal
        title={<FormattedMessage id="selectCountry" defaultMessage="Select Country" />}
        visible={visible}
        footer={[
          <Button
            type="primary"
            disabled={selectedCountry === '' ? true : false}
            onClick={handleModal}
          >
            Submit
          </Button>,
        ]}
        closable={false}
        className="code-verification-modal wrapper"
      >
        <div className="scrolling">
          {countries.map((i, index) => (
            <div
              key={index}
              onClick={() => selectCountry(i.country_ca)}
              style={i.country === selectedCountry ? { backgroundColor: '#A1947A' } : null}
              className="country-border"
            >
              <h2
                style={i.country === selectedCountry ? { color: 'white' } : null}
                className="textAlignCenter"
              >
                {localStorage.getItem("language") === 'fr-ca' ?
                  i.country_fr :
                  i.country_ca
                }
              </h2>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default CountryModal;
