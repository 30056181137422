import React from "react";
import { Layout } from "antd";
import "./styles.css";

import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { SolutionModal } from "../CommonComponents/Modals";

const { Content } = Layout;

const YouPropose = ({
  toggle,
  collapsed,
  youPropose,
  handleModal,
  visible,
  handleClick,
  category
}) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          headingTitle={
            localStorage.getItem("language") === "fr-ca"
              ? "Vente/Achat"
              : "Sell/Buy"
          }
        />
        <Content className="youpropose-container content-style">
          <div className="list-container">
            <div
              onClick={() => handleModal("data", youPropose)}
              className="you-propose-container"
            >
              <div className="you-propose">
                <div className="img-container">
                  <img alt="" className="list-img" src={youPropose.img} />
                </div>
                <div className="item-desp">
                  {localStorage.getItem("language") === "fr-ca"
                    ? youPropose.desp.fr
                    : youPropose.desp.en}
                </div>
                <div className="item-price">
                  <div className="item-price-wrapper">= ???</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </Content>
      </Layout>
      <SolutionModal
        handleModal={handleModal}
        visible={visible}
        handleClick={handleClick}
        category="YouPropose"
        subCategoryId={category && category.id}
        categoryData={category && category}
      />
    </Layout>
  );
};

export default YouPropose;
