import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import delivery from "../../Assests/transparentLogos/bike.png";
import equipment from "../../Assests/transparentLogos/man_and_tractor.png";
import transport from "../../Assests/transparentLogos/car.png";
import home from "../../Assests/transparentLogos/house.png";
import services from "../../Assests/transparentLogos/setting.png";
import vegetables from "../../Assests/transparentLogos/vegetables.png";
import corn from "../../Assests/transparentLogos/corn.png";
import cow_and_hen_ from "../../Assests/transparentLogos/cow_and_hen_.png";
import fruits from "../../Assests/transparentLogos/fruits.png";
import hen from "../../Assests/transparentLogos/hen.png";
import leaf from "../../Assests/transparentLogos/leaf.png";
import seeds from "../../Assests/transparentLogos/seeds.png";

import {
  Layout,
  Icon,
  Dropdown,
  Menu,
  Avatar,
  Badge,
} from "antd";
import MediaQuery from "react-responsive";

import ActionCreators from "../../actions";
import DrawerNavigation from "./DrawerNavigation";
import orderImage from "../../Assests/My_Offers.png";
import "../../App.css";
import "./shared.css";
const { Header } = Layout;

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="/profile">View Profile</a>
    </Menu.Item>
    <Menu.Item key="1">
      <div
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        Logout
      </div>
    </Menu.Item>
  </Menu>
);

function readNotify(notifications) {
  var count = 0;
  notifications &&
    notifications.reverse().map((notification) => {
      if (notification.status == "true") {
        return count = count + 1;
      }
    });
  return count;
}

class TopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
  }

  handleNotificationRead = (id) => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const params = {
      id: id,
    };
    this.props.setNotificationRead(params, currentUser._id);
  };
  render() {
    return (
      <Fragment>
        <MediaQuery minDeviceWidth={500}>
          <Header className='header_'
            style={{
              width: this.props.headingTitle == 'About' ? '100%' : "85%",
            }}
          >
            <div className="trigger icon-style" />
            {this.props.headingTitle ? (
              <div className="heading-title">{this.props.headingTitle}</div>
            ) : null}
            {localStorage.getItem("currentUser") ? (
              <>
                <div className="optionsWrapper">
                  <div
                    className="mr-20"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Link to="/myorders" className="offer-cart_container">
                      <img
                        src={orderImage} alt=''
                        style={{ height: "10px", width: "10px" }}
                      />
                    </Link>
                    <Dropdown
                      overlayStyle={{ width: "400px" }}
                      overlay={
                        <Menu>
                          <div
                            style={{
                              backgroundColor: "white",
                              overflowY: "scroll",
                              height: "200px",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Mailbox{" "}
                              {
                                JSON.parse(localStorage.getItem("currentUser"))
                                  .firstName
                              }
                            </div>
                            {this.props.notifications &&
                              this.props.notifications.map(
                                (notification, index) => (
                                  <Menu.Item key={index}>
                                    <div
                                      onClick={() =>
                                        this.handleNotificationRead(
                                          notification._id
                                        )
                                      }
                                    >
                                      <div style={{ display: "flex" }}>
                                        <span>
                                          <Icon
                                            type="bell"
                                            style={{
                                              color: "rgba(0,0,0,.25)",
                                              fontSize: "20px",
                                              marginRight: "10px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                        </span>{" "}
                                        <span
                                          style={{
                                            fontWeight:
                                              notification.status == "false"
                                                ? 400
                                                : "bold",
                                          }}
                                        >
                                          {localStorage.getItem("language") ===
                                            "fr-ca"
                                            ? `Salut ${JSON.parse(
                                              localStorage.getItem(
                                                "currentUser"
                                              )
                                            ).firstName
                                            }, ${notification.message_fr}`
                                            : `Hi ${JSON.parse(
                                              localStorage.getItem(
                                                "currentUser"
                                              )
                                            ).firstName
                                            }, ${notification.message_en}`}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #c7c7c7",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                  </Menu.Item>
                                )
                              )}
                          </div>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Badge size={10} count={this.props.readNotification}>
                        <Avatar
                          shape="circle"
                          size={20}
                          icon="mail"
                          style={{
                            backgroundColor: "#FFFF",
                            marginLeft: "15px",
                            color: "grey",
                          }}
                        />
                      </Badge>
                    </Dropdown>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Avatar
                        shape="circle"
                        size={20}
                        icon="user"
                        style={{
                          backgroundColor: "#FFFF",
                          marginLeft: "15px",
                          color: "grey",
                        }}
                      />
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : (
              <div className="optionsWrapper" />
            )}
          </Header>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={500}>
          <Header 
          // className='header_'
            style={{
              background: "#A1947A",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "fixed",
              overflow: "scroll",
              zIndex: 999,
              width: "100%",
            }}
          >
            <DrawerNavigation
              {...this.props}
              collapsed={this.props.collapsed}
            />
            {this.props.check ? (
              <>
                <div className="heading-title" style={{ display: "flex" }}>
                  <div className="nav-center-image-container">
                    <img alt=''
                      src={
                        this.props.check === "delivery"
                          ? delivery
                          : this.props.check === "equipment"
                            ? equipment
                            : this.props.check === "space"
                              ? home
                              : this.props.check === "transport"
                                ? transport
                                : this.props.check === "services"
                                  ? services
                                  : this.props.check === "vegetables"
                                    ? vegetables
                                    : this.props.check === "cereal"
                                      ? corn
                                      : this.props.check === "fruits"
                                        ? fruits
                                        : this.props.check === "liveStock"
                                          ? cow_and_hen_
                                          : this.props.check === "inputCategory"
                                            ? seeds
                                            : this.props.check === "animal"
                                              ? hen
                                              : this.props.check === "herbal"
                                                ? leaf
                                                : null
                      }
                      className="nav-center-image"
                    />
                    <div className="nav-center-text">
                      {this.props.headingTitle}
                    </div>
                  </div>
                  {/* {this.props.headingTitle} */}
                </div>
              </>
            ) : (
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "6px",
                  display: "flex",
                }}
              >
                <div className="nav-center-image-container">
                  <div className="nav-center-text">
                    {this.props.headingTitle}
                  </div>
                </div>
                {/* {this.props.headingTitle} */}
              </div>
            )}
            {localStorage.getItem("currentUser") ? (
              <>
                <div className="optionsWrapper">
                  <div
                    className="mr-20"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Link to="/myorders" className="offer-cart_container">
                      <img
                        src={orderImage}
                        alt=''
                        style={{ height: "15px", width: "15px" }}
                      />
                    </Link>

                    <Dropdown
                      overlayStyle={{ width: "300px" }}
                      overlay={
                        <Menu>
                          <div
                            style={{
                              backgroundColor: "white",
                              overflowY: "scroll",
                              height: "200px",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Mailbox{" "}
                              {
                                JSON.parse(localStorage.getItem("currentUser"))
                                  .firstName
                              }
                            </div>

                            {this.props.notifications &&
                              this.props.notifications.map(
                                (notification, index) => (
                                  <Menu.Item key={index}>
                                    <div
                                      onClick={() =>
                                        this.handleNotificationRead(
                                          notification._id
                                        )
                                      }
                                    >
                                      <div style={{ display: "flex" }}>
                                        <span>
                                          <Icon
                                            type="bell"
                                            style={{
                                              color: "rgba(0,0,0,.25)",
                                              fontSize: "20px",
                                              marginRight: "10px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                        </span>{" "}
                                        <span
                                          style={{
                                            fontWeight:
                                              notification.status == "false"
                                                ? 400
                                                : "bold",
                                          }}
                                        >
                                          {localStorage.getItem("language") ===
                                            "fr-ca"
                                            ? `Salut ${JSON.parse(
                                              localStorage.getItem(
                                                "currentUser"
                                              )
                                            ).firstName
                                            }, ${notification.message_fr}`
                                            : `Hi ${JSON.parse(
                                              localStorage.getItem(
                                                "currentUser"
                                              )
                                            ).firstName
                                            }, ${notification.message_en}`}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #c7c7c7",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                  </Menu.Item>
                                )
                              )}
                          </div>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Badge size={5} count={this.props.readNotification}>
                        <Avatar
                          shape="circle"
                          size={25}
                          icon="mail"
                          style={{
                            backgroundColor: "#FFFF",
                            marginLeft: "10px",
                            color: "grey",
                          }}
                        />
                      </Badge>
                    </Dropdown>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Avatar
                        shape="circle"
                        size={25}
                        icon="user"
                        style={{
                          backgroundColor: "#FFFF",
                          marginLeft: "10px",
                          color: "grey",
                        }}
                      />
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : (
              <div className="optionsWrapper" />
            )}
          </Header>
        </MediaQuery>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    currentUser: state.auth.currentUser,
    notifications: state.notifications.notifications,
    readNotification: readNotify(state.notifications.notifications),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
