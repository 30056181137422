import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";

import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { PackageDetailForm } from "../Forms";

import { Layout } from "antd";

class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    const currentUser = this.props.currentUser;
    this.props.getSolution(
      this.props.match.params.userId,
      currentUser && currentUser.auth_key,
      this.props.match.params.id,
      "needSolution"
    );
    const userFromLS = JSON.parse(localStorage.getItem("currentUser"));
    this.props.visitedSolution({
      user_id: this.props.match.params.userId,
      userWhoSearched: userFromLS.firstName,
      userWhoSearchedNum: userFromLS.mobile_no,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  navigateToDetails = () => {
    this.props.history.push({
      pathname: `/clientneedsmatch/${this.props.solutionData && this.props.solutionData._id
        }`,
      state: {
        solutionData: this.props.solutionData,
      },
    });
  };

  render() {
    const { solutionData } = this.props;
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            headingTitle={
              localStorage.getItem("language") === "fr-ca"
                ? "Besoin d'un client"
                : "A Client's Need"
            }
          />
          <PackageDetailForm
            {...this.state}
            navigateToDetails={this.navigateToDetails}
            solutionData={solutionData}
          />
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    solutionData: state.solutions.solution,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
