import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import {
  // NotificationContainer,
  NotificationManager
} from "react-notifications";

import ActionCreators from "../../actions";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import "./styles.css";

import { Layout } from "antd";
import { Link } from "react-router-dom";
const { Content } = Layout;

class SubscriptionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleFreeTrail = () => {
    const params = {
      user_id: this.props.currentUser._id,
      auth_key: this.props.currentUser.auth_key,
      status: true,
      trail_complete: false
    };
    this.props.setFreeTrail(params, this.props.history, NotificationManager);
  };

  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <Content
            className="content-style"
            style={{
              padding: 10,
              background: "green",
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div style={{ color: "white" }}>
              <div className="details-container"></div>
              <div style={{ marginTop: "30px" }}>
                <div style={{ marginBottom: "10px", textAlign: "center" }}>
                  <FormattedMessage
                    id="subsMessage"
                    defaultMessage="This feature is available for subscribers only or during trial period"
                  />
                  <br />{" "}
                </div>
                <div style={{ color: "green" }} className="choose-plan-div">
                  <FormattedMessage
                    id="featureNow"
                    defaultMessage="Get this feature NOW!"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="packageBorderContainerSubs">
                    <Link
                      to="/subscriptions"
                      style={{ color: "white" }}
                      className="package-text"
                    >
                      <div style={{ textAlign: "center", fontSize: "13px" }}>
                        <FormattedMessage
                          id="Subscription"
                          defaultMessage="Subscription"
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "45px",
                          fontWeight: "bold",
                          marginTop: "-12px"
                        }}
                      >
                        12
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "15px",
                          marginTop: "-12px"
                        }}
                      >
                        <FormattedMessage id="months" defaultMessage="months" />
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize:
                            localStorage.getItem("currency") === "fr"
                              ? "18px"
                              : "25px"
                        }}
                      >
                        {localStorage.getItem("currency") === "dollar"
                          ? "13$"
                          : localStorage.getItem("currency") === "euro"
                          ? "12€"
                          : "7.900CFA"}
                      </div>
                    </Link>
                  </div>
                  {this.props.currentUser &&
                  this.props.currentUser.subscription.trail_complete ===
                    false ? (
                    <div
                      onClick={this.handleFreeTrail}
                      className="packageBorderContainerSubs"
                    >
                      <div className="package-text">
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize:
                              localStorage.getItem("language") === "fr-ca"
                                ? "21px"
                                : "25px"
                          }}
                        >
                          <FormattedMessage id="free" defaultMessage="Free" />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              display: "block",
                              fontSize: "12px"
                            }}
                          >
                            90{" "}
                            <FormattedMessage id="days" defaultMessage="days" />
                          </span>{" "}
                          <FormattedMessage
                            id="noCharge"
                            defaultMessage="no charge! to help you during"
                          />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                          <FormattedMessage
                            id="covid19"
                            defaultMessage="COVID19 times"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="accept-button-container">
                <Link to="/dashboard" className="accept-button-client">
                  <FormattedMessage id="no" defaultMessage="No Thanks" />
                </Link>
              </div>
              <div></div>
            </div>
          </Content>
        </Layout>
        {/* <NotificationContainer /> */}
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionScreen);
