import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import Propose from "../../Assests/You_Propose.png";
// import { useHistory } from 'react-router-dom'
import Home from "../../Assests/Home-icon.png";
import About from "../../Assests/About.png";
import Clients from "../../Assests/Clients.png";
import Currency from "../../Assests/Language_Currency.png";
import Logout from "../../Assests/Logout.png";
import Share from "../../Assests/Share_App.jpg";
import Profile from "../../Assests/Profile.png";
import PrivacyPolicy from "../../Assests/Security_Policy.png";
import Mapp from "../../Assests/Map.png";
import Suppliers from "../../Assests/Suppliers.png";
import MyOffers from "../../Assests/Offers.png";

import Delivery from "../../Assests/transparentLogos/bike.png";
import Equipment from "../../Assests/transparentLogos/man_and_tractor.png";
import Transport from "../../Assests/transparentLogos/car.png";
import Space from "../../Assests/transparentLogos/house.png";
import Services from "../../Assests/transparentLogos/setting.png";
import vegetables from "../../Assests/transparentLogos/vegetables.png";
import cereal from "../../Assests/transparentLogos/corn.png";
import liveStock from "../../Assests/transparentLogos/cow_and_hen_.png";
import fruits from "../../Assests/transparentLogos/fruits.png";
import animalFeed from "../../Assests/transparentLogos/hen.png";
import herbs from "../../Assests/transparentLogos/leaf.png";
import input from "../../Assests/transparentLogos/seeds.png";

import "./shared.css";

function shareApplication() {
  if (navigator.share) {
    navigator
      .share({
        title: "Pickantapp",
        text: "Check out pickantapp.",
        url: "https://www.pickantapp.com/",
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
}

function clearStorage() {
  localStorage.clear();
  window.location.reload();
}

const MenuItems = ({ collapsed, history }) => {
  // const his = useHistory();
  return (
    <Menu className="menu-container" theme="dark" mode="inline">
      <Menu.Item key="1">
        <Link className="sideNav-link" to="/dashboard">
          <img alt="" className="sideNav-img" src={Home} />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Accueil"
                : "Home"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="2">
        <Link className="sideNav-link" to="/delivery">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Delivery}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Livraison"
                : "Delivery"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="3">
        <Link className="sideNav-link" to="/equipment">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Equipment}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Équipement"
                : "Equipment"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="4">
        <Link style={{ color: "#A1947A" }} to="/space">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Space}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Espace"
                : "Space"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="5">
        <Link className="sideNav-link" to="/tranport">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Transport}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Transport"
                : "Transport"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="6">
        <Link className="sideNav-link" to="/services">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Services}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Prestations de service"
                : "Services"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="7">
        <Link className="sideNav-link" to="/vegetables">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={vegetables}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des légumes"
                : "Vegetables"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="8">
        <Link className="sideNav-link" to="/cereal">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={cereal}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Céréale"
                : "Cereal"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="9">
        <Link className="sideNav-link" to="/fruits">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={fruits}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des fruits"
                : "Fruits"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="10">
        <Link className="sideNav-link" to="/liveStock">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={liveStock}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Bétail"
                : "Live Stocks"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="11">
        <Link className="sideNav-link" to="/inputCategory">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={input}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des Intrants"
                : "Input"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="12">
        <Link className="sideNav-link" to="/animal">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={animalFeed}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Aliments de bétail"
                : "Animal feeds"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>

      <Menu.Item className="mobile-categories" key="13">
        <Link className="sideNav-link" to="/herbs">
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={herbs}
          />
          {!collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Herbes"
                : "Herbs"}
            </span>
          ) : null}
        </Link>
      </Menu.Item>
      <Menu.Item className="mobile-categories" key="14">
        <Link className="sideNav-link" to="/youpropose">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Propose} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Vente/Achat"
                  : "Sell/Buy"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="15">
        <Link className="sideNav-link"
          to="/about">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={About} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "À propos"
                  : "About"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="16">
        <Link className="sideNav-link" to="/map">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Mapp} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca" ? "Carte" : "Map"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="17">
        <Link className="sideNav-link" to="/clients">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Clients} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Clients"
                  : "Clients"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="18">
        <Link className="sideNav-link" to="/suppliers">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Suppliers} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Fournisseuses"
                  : "Suppliers"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="19">
        <Link className="sideNav-link" to="/myoffers">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={MyOffers} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Mes offres"
                  : "My Offers"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="20">
        <Link className="sideNav-link" to="/languageCurrency">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Currency} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Langue et devise"
                  : "Language & Currency "}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="21">
        <MediaQuery minDeviceWidth={500}>
          <a
            className="mailto"
            href="mailto:?subject=Pickant App&body=Hi,I found this website and thought you might like it https://www.pickantapp.com/"
          >
            <img alt="" className="sideNav-img" src={Share} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Partager l'application"
                  : "Share App"}
              </span>
            ) : null}
          </a>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={500}>
          <div
            onClick={() => shareApplication()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img alt="" className="sideNav-img" src={Share} />
            {!collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Partager l'application"
                  : "Share App"}
              </span>
            ) : null}
          </div>
        </MediaQuery>
      </Menu.Item>
      <Menu.Item key="22">
        <Link className="sideNav-link" to="/privacypolicy">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={PrivacyPolicy} />
            {!collapsed ? (
              <span className="heading-color">
                {" "}
                {localStorage.getItem("language") === "fr-ca"
                  ? "Politique de confidentialité"
                  : "Privacy Policy"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="23">
        <Link className="sideNav-link" to="/profile">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Profile} />
            {!collapsed ? (
              <span className="heading-color">
                {" "}
                {localStorage.getItem("language") === "fr-ca"
                  ? "Profil"
                  : "Profile"}
              </span>
            ) : null}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="24">
        <div
          onClick={() => clearStorage(history)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img alt="" className="sideNav-img" src={Logout} />
          {!collapsed ? (
            <span className="heading-color">
              {" "}
              {localStorage.getItem("language") === "fr-ca"
                ? "Se déconnecter"
                : "Logout"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
    </Menu>
  );
};

export default MenuItems;
