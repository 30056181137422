import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCfhh346w2Paz8vlxfl-RT36x1-2Zk7g2Q",
  authDomain: "pickant-app.firebaseapp.com",
  databaseURL: "https://pickant-app.firebaseio.com",
  projectId: "pickant-app",
  storageBucket: "pickant-app.appspot.com",
  messagingSenderId: "907462933060",
  appId: "1:907462933060:web:8145831f6af55e4afd9a11",
  measurementId: "G-K5T8LCWVC8",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
