import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import "./styles.css";

import { Layout } from "antd";
const { Content } = Layout;

class SupplierSolutionMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleAccept = () => {
    const { solutionData } = this.props.location.state;

    const params = {
      user_id: solutionData && solutionData.user._id,
      auth_key: this.props.currentUser.auth_key,
      accepted_by: this.props.currentUser._id,
      solution_id: solutionData && solutionData._id,
    };
    this.props.solutionAccepted(
      params,
      this.props.history,
      solutionData,
      "supplierSolutionMatch"
    );
  };

  render() {
    const { solutionData } = this.props.location?.state;
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <Content
            className="content-style"
            style={{
              padding: 10,
              background: "green",
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ color: "white" }}>
              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="solutionAccepted"
                    defaultMessage="Solution Accepted"
                  />
                  :
                </div>
                <div className="detail-text">
                  {localStorage.getItem("language") === "fr-ca"
                    ? solutionData.sub_category.fr
                    : solutionData.sub_category.en}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupAddress"
                    defaultMessage="Pickup Address"
                  />
                  :
                </div>
                <div className="detail-text">
                  {solutionData.pickup_street_address},
                  {solutionData.pickup_city},{solutionData.pickup_country}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupDateTime"
                    defaultMessage="Pickup Date and Time"
                  />
                  :
                </div>
                <div className="detail-text">{solutionData.pickup_date}</div>
              </div>
              {JSON.parse(localStorage.getItem("currentUser")).subscription
                .subscription_flag ? (
                <div>
                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="userEmail"
                        defaultMessage="User Email"
                      />
                      :
                    </div>
                    <div className="detail-text">{solutionData.user.email}</div>
                  </div>

                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="userMobile"
                        defaultMessage="User Mobile.no"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {solutionData.user.mobile_no}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="deliveryAddress"
                    defaultMessage="Delivery Address"
                  />
                  :
                </div>
                <div className="detail-text">
                  {solutionData.delivery_street_address},
                  {solutionData.delivery_city},{solutionData.delivery_country}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="desirableDeliveryAddress"
                    defaultMessage="Desirable Delivery Address"
                  />
                  :
                </div>
                <div className="detail-text">{solutionData.delivery_date}</div>
              </div>
              {solutionData.sub_category.en === "Your own proposition..." ? (
                <div className="details-container">
                  <div className="title-space">
                    <FormattedMessage id="price" defaultMessage="Price" />:
                  </div>
                  <div className="detail-text">
                    {solutionData.display_price}
                  </div>
                </div>
              ) : null}
              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="desciption"
                    defaultMessage="Description"
                  />
                  :
                </div>
                <div className="detail-text">{solutionData.description}</div>
              </div>

              <div>
                <FormattedMessage id="important" defaultMessage="Important" />!
              </div>
              <div>
                <FormattedMessage
                  id="solutionMatchMessage"
                  defaultMessage=" You accepted this offer, a notification/SMS was sent to
                the solution provider..."
                />
              </div>

              <div className="accept-button-container">
                <div
                  onClick={this.handleAccept}
                  className="accept-button-client"
                >
                  <FormattedMessage id="accept" defaultMessage="Accept" />
                </div>
              </div>

              <Link to="/suppliers" className="accept-button-container">
                <div className="accept-button-supplier-no">No</div>
              </Link>
              <div></div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    currentUser: state.auth.currentUser,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierSolutionMatch);
