import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
import { Layout } from "antd";

import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import ActionCreators from "../../actions";
import "./styles.css";
const { Content } = Layout;

class ClientOfferAccept extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  componentDidMount() {
    const paymentParams = queryString.parse(this.props.location.search);
    if(paymentParams.PayerID){
      this.props.successPaymentMessage({
        user_id: paymentParams.user_id,
        payerId: paymentParams.PayerID,
        paymentId: paymentParams.paymentId,
        total_price: paymentParams.total_price,
        currency: paymentParams.currency,
        payment_type: paymentParams.payment_type,
        accpeted_by_id: paymentParams.accpeted_by_id,
        solution_id: paymentParams.solution_id
      });
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const { solutionData } = this.props;
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <Content
            className="content-style"
            style={{
              padding: 10,
              background: "#FFFF",
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div style={{ color: "black" }}>
              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="serviceRequested"
                    defaultMessag="Service requested"
                  />
                  :
                </div>
                <div className="detail-text">
                  {localStorage.getItem("language") === "fr-ca"
                    ? solutionData && solutionData.sub_category.fr
                    : solutionData && solutionData.sub_category.en}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupAddress"
                    defaultMessag="Pickup Address"
                  />
                  :
                </div>
                <div className="detail-text">
                  {solutionData && solutionData.pickup_street_address},
                  {solutionData && solutionData.pickup_city},
                  {solutionData && solutionData.pickup_country}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupDateTime"
                    defaultMessag="Pickup Date and Time"
                  />
                  :
                </div>
                <div className="detail-text">
                  {solutionData && solutionData.pickup_date}
                </div>
              </div>

              {(solutionData && solutionData.category !== "Equipments") ||
              (solutionData && solutionData.category !== "Space") ||
              (solutionData && solutionData.category !== "Services") ? (
                <>
                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="deliveryAddress"
                        defaultMessag="Delivery Address"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {" "}
                      {solutionData && solutionData.delivery_street_address},
                      {solutionData && solutionData.delivery_city},
                      {solutionData && solutionData.delivery_country}
                    </div>
                  </div>

                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="desirableDeliveryAddress"
                        defaultMessag=" Desirable Delivery Address"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {solutionData && solutionData.delivery_date}
                    </div>
                  </div>
                </>
              ) : null}

              <div style={{ marginTop: "30px" }}>
                <div>
                  <FormattedMessage
                    id="thanksNote"
                    defaultMessag="Thank you for paying. Your Supplier phone number is:"
                  />
                  <span style={{ fontWeight: "bold" }}>
                    {solutionData && solutionData.user.mobile_no}
                  </span>
                </div>
                <div>
                  <FormattedMessage
                    id="youSupplierEmail"
                    defaultMessag="Your Supplier Email is:"
                  />
                  <span style={{ fontWeight: "bold" }}>
                    {solutionData && solutionData.user.email}
                  </span>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  console.log(state.solutions.solutionAccepte)
  return {
    solutionData: state.solutions.solutionAccepted,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientOfferAccept);
