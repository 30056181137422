import * as types from "../utils/constants";
import Api from "../services/api";
// const api = process.env.REACT_APP_PRODUCTION_URL;

function setNotificationMessages(notifications) {
  return {
    type: types.SET_NOTIFICATIONS,
    notifications: notifications,
  };
}

export function getNotifications(userId) {
  return (dispatch) =>
    Api.get(`notify/getUserNotif/${userId}`)
      .then((resp) => {
        dispatch(setNotificationMessages(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
}

export function setNotificationRead(params, userId) {
  return (dispatch) =>
    Api.put(`notify/updateNotification`, params)
      .then(() => {
        dispatch(getNotifications(userId));
      })
      .catch((err) => {
        console.log(err);
      });
}
