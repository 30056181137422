import React from "react";
import { Radio, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SetLanguage from "../../Shared/setLanguge";

import "./styles.css";

class IDAccountCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: "withOutID",
      modalVisible: false,
      // disForAlert
    };
  }

  handleOk = () => {
    this.setState({ modalVisible: false });
    this.props.history.push('/');
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };


  onChange = (e) => {
    this.setState({
      accountType: e.target.value,
    });
  };

  render() {
    const imgUrl = process.env.PUBLIC_URL + "/";
    console.log("SAIJ ", imgUrl);
    return (
      <div className="acc-creation-container">
        <div className="acc-wrapper">
          <div className="acc-creation-heading">
            <FormattedMessage
              id="idAccountCreation"
              defaultMessage="ID Account Creation"
            />
          </div>
          <div style={{ overflow: "hidden" }}>
            <FormattedMessage
              id="idAccountMessage"
              defaultMessage="
            En- If you wish that more people will make business with you please
            provide us with your full name and ID details and add a photo ID.
            Identified users get better rating and have more chances of doing
            business with others. We don't share your details with other users
            or any other.We don't share your details with other users or any
            other entity, and it servers us only for security purposes in-case
            of disputes.
            "
            />
          </div>

          <div className="acc-option-container">
            <div className="acc-option-wrapper">
              <Radio.Group
                onChange={this.onChange}
                value={this.state.accountType}
              >
                <div>
                  <Radio value="withOutID">
                    <FormattedMessage
                      id="createWithoutId"
                      defaultMessage="Account without ID"
                    />
                  </Radio>
                </div>
                <div>
                  <Radio value="withID">
                    <FormattedMessage
                      id="createWithId"
                      defaultMessage="Account with ID"
                    />
                  </Radio>
                </div>
                <div>
                  <Radio value="withGorFB">
                    <FormattedMessage
                      id="createWithoutGoogleorFacebook"
                      defaultMessage="Account via Google or Facebook"
                    />
                    <br />
                    <div
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        padding: "5px",
                      }}
                    >
                      <img
                        src={`${imgUrl}google.png`}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      />

                      <img
                        src={`${imgUrl}facebook.png`}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                  </Radio>
                </div>
              </Radio.Group>
              <div className="create-account-container">
                <Link
                  to={{
                    pathname: "/register",
                    state: {
                      id: this.state.accountType,
                    },
                  }}
                  style={{ padding: '5px 1rem 5px 1rem' }}
                  className="create-account-link borderbtn"
                >
                  <FormattedMessage
                    id="createAnAccount"
                    defaultMessage="Create An Account"
                  />
                </Link>
              </div>
              <br />
              <br />
              <button
                onClick={() => this.setState({ modalVisible: true })}
                className="leave-btn"
              >
                <span style={{ color: "#fff", fontWeight: 500 }}>
                  <FormattedMessage id="leaveText" defaultMessage="Leave" />
                </span>
              </button>
              <Modal
                title={
                  localStorage.getItem("language") === "fr-ca"
                    ? "Quitter?"
                    : "Leave?"
                }
                visible={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText={
                  localStorage.getItem("language") === "fr-ca"
                    ? "Quitter"
                    : "Leave"
                }
                cancelText={
                  localStorage.getItem("language") === "fr-ca"
                    ? "Gagner"
                    : "Gain"
                }
                okButtonProps={{
                  className: "ant-btn ant-btn-danger",
                }}
                cancelButtonProps={{
                  className: "ant-btn ant-btn-primary",
                }}
                footer={[
                  <Button key="leave" type="danger" onClick={this.handleOk}>
                    {localStorage.getItem("language") === "fr-ca"
                      ? "Quitter"
                      : "Leave"}
                  </Button>,
                  <Button key="gain" type="primary" onClick={this.handleCancel}>
                    {localStorage.getItem("language") === "fr-ca"
                      ? "Gagner"
                      : "Gain"}
                  </Button>,
                ]}
              >
                <div style={{ textAlign: "center", justifyContent: "center" }}>
                  {localStorage.getItem("language") === "fr-ca" ? (
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        Attendez!!
                      </p>
                      <p>
                        Saviez-vous que vous pouvez vraiment gagner de l'argent
                        avec nous?
                      </p>
                      <p>
                        Postez des offres sur notre carte et partagez-la avec
                        d'autres, et vous verrez ... Tout est à l'intérieur ...{" "}
                      </p>
                      <p>Inscrivez vous maintenant !!</p>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        Wait!!{" "}
                      </p>
                      <p>
                        Did you know that you can really earn money with us?
                      </p>
                      <p>
                        Post offers on our map and share it with others, and you
                        will see... All is inside...{" "}
                      </p>
                      <p>Register now!!</p>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <SetLanguage />
      </div>
    );
  }
}
export default IDAccountCreation;
