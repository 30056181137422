import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../actions";
import Profile from "../Components/Profile";

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  onFilesChange = e => {
    this.setState({ selectedFile: e.target.files }, () => {
      this.handleUpload();
    });
  };

  handleUpload = () => {
    const data = new FormData();
    Object.values(this.state.selectedFile).map(file => {
     return data.append("file", file);
    });
    data.append("user_id", this.props.currentUser._id);
    data.append("update_data", JSON.stringify({}));
    data.append("auth_key", this.props.currentUser.auth_key);
    this.props.updateUser(data, this.props.history);
  };

  render() {
    return (
      <Profile
        onFilesChange={this.onFilesChange}
        currentUser={this.props.currentUser}
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        handleUpload={this.handleUpload}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
