import { LOCALES } from "../constants";

export default {
  [LOCALES.FRENCH]: {
    "edit-file": "Modifiez {fichier} et enregistrez pour recharger.",
    hello: "Bonjour",
    sendCode: "Envoyer",
    acceptOffer: "Accepter l'offre",
    mobileNumber: "numéro de mobile (+171741XXXXX)",
    sendCodePlaceholder: "Entrez le numéro de mobile (+171741XXXXX)",
    verifyPasswordTitle: "Veuillez vérifier le mot de code",
    verifyCode: "Vérifiez le code",
    resetPasswordTitle: "Veuillez réinitialiser le mot de passe",
    password: "Mot de passe",
    confirmPassword: "Confirmez le mot de passe",
    resetPassword: "Réinitialisez le mot de passe",
    loginTitle: `S'identifier`,
    userName: `Nom d'utilisateur`,
    forgetPassword: "Mot de passe oublié?",
    login: `S'identifier`,
    createAnAccount: "Créer un compte",
    register: "S'inscrire",
    name: "Nom",
    familyName: "Nom de famille",
    email: "Email",
    mobile: "Mobile",
    physicalAddress: "Adresse physique",
    DropFiles: "Déposez des fichiers ici ou cliquez pour télécharger",
    loginIntoAccount: "Connectez-vous au compte",
    needSolution:
      " Poster en tant que ",
    provideSolution:
      " Poster en tant que ",
    noteforsolution: ` Les paiements des solutions sont effectués directement
                        entre les utilisateurs! Il est de votre responsabilité de remettre le
                        packages / équipements / espace uniquement aux personnes de confiance. Nous n'avons pas
                        responsabilité en cas de vol ou de mauvaise utilisation de l'équipement.  En publiant, vous donnez votre consentement pour que les utilisateurs qui se sont abonnés et / ou choisissent vos offres, reçoivent votre téléphone et votre e-mail!`,
    need: "Client",
    next: "Suivante",
    pickUpAddress: "Adresse de ramassage",
    address: "Adresse de rue",
    city: "ville",
    postalCode: "code postal",
    region: "Région",
    country: "Pays",
    description: "la description",
    publish: "Publier",
    listOfClients: "Liste des clients",
    categoryName: "Nom de catégorie",
    subCategoryName: "Nom de la sous-catégorie",
    pickupAddress: "adresse de ramassage",
    pickupCity: "ville de ramassage",
    pickupRegion: "région de ramassage",
    pickupCountry: "Pays de ramassage",
    pickupDate: "Date de ramassage",
    deliveryAddress: "adresse de livraison",
    deliveryCity: "ville de livraison",
    deliveryRegion: "région de livraison",
    deliveryCountry: "Pays de livraison",
    deliveryDate: "Date de livraison",
    subCategory: "Sous-catégorie",
    subCategoryFinal: "Des détails",
    userEmail: "Courriel de l'utilisateur",
    serviceRequested: "Service demandé",
    pickupAddressTime: "Date et heure de prise en charge",
    deliveryAddressTime: "livraison address and time",
    pickupDateTime: "Date et heure de ramassage",
    accept: "J'accepte",
    desirableDeliveryAddress: "Adresse de livraison souhaitée",
    thanksNote:
      "Merci d'avoir payé. Le numéro de téléphone de votre client est:",
    youSupplierEmail: "L'adresse e-mail de votre client est:",
    important: "Importante",
    solutionMatchMessage:
      "Vous avez accepté cette offre, une notification / SMS a été envoyée au fournisseur de solutions ...",
    "pickup/deliveryDate": "Date Ramassage/livraison",
    idAccountMessage: `Si vous souhaitez que davantage de personnes fassent affaire avec vous, 
                        veuillez nous communiquer votre nom, prénom et numéro de carte d'identité 
                        et ajouter une photo d'identité. Les utilisateurs identifiés obtiennent 
                        une meilleure note et ont plus de chances de faire des affaires avec 
                        d'autres personnes. Nous ne partageons pas vos données d'identification 
                        avec d'autres utilisateurs ou toute autre entité, et ils nous servent 
                        uniquement à des fins de sécurité en cas de litige.`,
    idAccountCreation: "Création d'un compte ID",
    createWithId: "Compte avec identifiant",
    createWithoutId: "Compte sans identifiant",
    createWithoutGoogleorFacebook: "Compte via Google ou Facebook",
    privacyPolicy: "Politique de confidentialité",
    save: "enregistrer",
    userBalance: "solde utilisateur",
    listOfSuppliers: "Liste des fournisseurs",
    to: "À",
    no: "Non Merci",
    pay: "Payer",
    from: "De",
    scannedIdMessage: "Veuillez télécharger votre carte d'identité scannée",
    successMessage: "Votre transcation ne peut pas être traitée!",
    subscriptionRedirectingMessage:
      "Rediriger vers le tableau de bord, veuillez sélectionner à nouveau le service",
    messagePerMonth:
      "-Obtenez un accès complet à tous les contacts des utilisateurs",
    messagePerMonth2: "pour juste 1.1$/1€/1,850CDF/660CFA par mois!",
    Subscription: "Abonnement",
    months: "mois",
    chooseAPlan: "Choisissez un plan",
    noCharge: "gratuit! pour vous aider pendant la",
    covid19: "période COVID19",
    payAsYouGo: "Payez au fur et à mesure!",
    only: "Juste",
    perDeal: "par transaction",
    subscriptionMessagePayOnly: "-Ne payez que",
    subscriptionMessage:
      " pour un abonnement annuel-  et obtenez un accès complet au numéro de téléphone et e-mail de tous les utilisateurs",
    payWithPaypal: "Payer avec PayPal",
    free: "Gratuite",
    days: "jours",
    delivery: "Livraison",
    choose: "Choisissez parmi les options prédéfinies suivantes ci-dessous",
    leaveText: "Quitter",
    policydesp: `'J'ai lu, compris et accepté les'`,
    policydesp2: "vous êtes d'accord avec notre",
    rulesForMemberShip: "règles d'adhésion",
    privacyMemberShip: "politique de confidentialité pour l'adhésion",
    "loading...": "Chargement...",
    or: "OU",
    or2: "-",
    price: "Prix",
    subsMessage:
      "Cette fonctionnalité est disponible pour les abonnés uniquement ou pendant la période d'essai",
    featureNow: "Obtenez cette fonctionnalité MAINTENANT!",
    "info-message-login": `Aller quelque part? Prenez un colis sur votre chemin. Avez-vous du matériel inutilisé? -Avez-vous du matériel ou des services à louer?
    Postez vos ressources inutilisées en vert / «Solution» ou vos «besoins» en bleu sur la carte
    Partagez-le avec tous vos contacts, soyez payé pour votre solution ou trouvez-en un pour votre besoin!`,
    "info-message-heading": "Bénéficiez de vos ressources:",
    "going-somewhere": "-Allez quelque part?",
    "take-package": "Prenez un colis sur votre chemin!",
    "unused-equipment": "-Avez-vous du matériel ou des services à louer?",
    "find-client": "Postez-le et trouvez des clients!",
    "post-green": "Postez vos ressources en vert-",//en tant que hatana hai
    "postgreenbelow": "en tant que ",
    "post-blue": "-Besoin de quelque chose? Postez vos besoins",
    inBlue: "en bleu en tant que ",
    shareWithContacts: "Partagez-le avec tous vos contacts",
    getPaid: "Soyez payé pour votre solution!",
    findSolutionInfo: "Trouvez des solutions pour votre besoin!",
    "need-message": "Client",
    "login-message": "S'inscrire",
    memberShipRules: "Règles d'adhésion",
    verifyNumber: "Vérifiez votre numéro avec le code SMS que vous avez reçu",
    verifyCodeAndRegister: "Vérifiez le code et enregistrez",
    search: "Chercher",
    byCountry: "Par pays",
    byCategory: "Par catégorie",
    bySubCategory: "Par sous-catégorie",
    mobileAndEmail: "Email/téléphone (par exemple +92123452)",
    infoLoginMessage:
      "Veuillez entrer le numéro de téléphone avec votre code de pays comme sur les appels internationaux",
    NoResult: "Aucun résultat",
    confirmationText: "Voulez-vous vraiment supprimer la solution?",
    confirmationTextOrder: "Voulez-vous vraiment supprimer la commande?",
    noDataFound: "Aucune donnée disponible",
    fillFields: "Veuillez remplir les champs",
    solutionBtn: "Solution",
    equipment: "Équipement",
    space: "Espace",
    transport: "Transport",
    services: "Prestations de services",
    vegetables: "Des légumes",
    cereal: "Céréale",
    fruits: "Des fruits",
    liveStock: "Bétail",
    inputs: "Des Intrants",
    animalFeed: "Aliments de bétail",
    herbs: "Herbes",
    home: "Accueil",
    profile: "Profil",
    animalFeeds: "Aliments de bétail",
    selectCountry: "Choisissez le pays",
    addressText: "Adresse",
    cityText: "Ville",
    pincodeText: "Postal",
    countryText: "Pays",
    regionText: "Région",
    dateText: "Date",
    priceText: "Prix",
    descText: "La description",
    detailText: "Des détails",
  },
};
