import { combineReducers } from "redux";
import authReducer from "./auth";
import solutionsReducer from "./solutions";
import notificationsReducer from "./notifications";
export default combineReducers(
  Object.assign({
    auth: authReducer,
    solutions: solutionsReducer,
    notifications: notificationsReducer
  })
);
