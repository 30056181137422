import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";

import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import ActionCreators from "../../actions";

import { Layout } from "antd";

class CancelUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    console.log(query.token);
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <div className="successurl">
            <FormattedMessage
              id="cancelMessage"
              defaultMessage="Your transcation cannot be processed!"
            />
          </div>
        </Layout>
      </Layout>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    solutionData: state.solutions.solution
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelUrl);
