import React from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import cross from "../../../../Assests/cancel-xxl.png";
import "./styles.css";

const SolutionModal = ({
  visible,
  handleModal,
  category,
  subCategoryId,
  categoryData,
}) => {
  return (
    <div>
      <Modal
        visible={visible}
        onOk={handleModal}
        onCancel={handleModal}
        closable={false}
        footer={null}
        className="solution-modal"
      >
        <div onClick={handleModal} className="solution-cross">
          <img alt="" src={cross} style={{ height: "23px" }} />
        </div>
        <div className="solution-wrapper">
          <p>
            {/*<FormattedMessage
              id="needSolution"
              defaultMessage="Want to sell/rent your stuff to others? Post it here in green"
            />*/}
            {localStorage.getItem("language") === "fr-ca" && (
              <FormattedMessage
                id="needSolution"
                defaultMessage="Poster en tant que "
              />
            )}
            {localStorage.getItem("language") === "en-us" && (
              <FormattedMessage
                id="needSolution"
                defaultMessage="Post as a"
              />
            )}
          </p>
          <Link
            to={{
              pathname: `/selectionMap/${category}/${subCategoryId}`,
              state: {
                url: "providesolutionform",
                categoryData: categoryData,
              },
            }}
            className="solution-btn-container"
          >
            {localStorage.getItem("language") === "fr-ca" && (
              <div className="solution-btn green">Fournisseur de solutions</div>
            )}
            {localStorage.getItem("language") === "en-us" && (
              <div className="solution-btn green">Solution Provider</div>
            )}
          </Link>
        </div>
        <div className="solution-wrapper2" style={{ textAlign: "center" }}>
          <p>
            {/*<FormattedMessage
              id="provideSolution"
              defaultMessage="Didn’t find what you need on the maps? Post a request here in blue!"
            />*/}
            {localStorage.getItem("language") === "fr-ca" && (
              <FormattedMessage
                id="provideSolution"
                defaultMessage="Poster en tant que "
              />
            )}
            {localStorage.getItem("language") === "en-us" && (
              <FormattedMessage
                id="provideSolution"
                defaultMessage="Post as a"
              />
            )}
          </p>

          {/* {
          JSON.parse(localStorage.getItem("currentUser")).subscription
            .subscription_flag ||
          (categoryData &&
            categoryData.desp.en !== "Your own proposition...") ? ( */}
          <Link
            to={{
              pathname: `/selectionMap/${category}/${subCategoryId}`,
              state: {
                url: "needSolutionform",
                categoryData: categoryData,
              },
            }}
            className="solution-btn-container"
          >
            <div className="solution-btn blue">
              <FormattedMessage id="need" defaultMessage="Client" />
            </div>
          </Link>
          {/* // ) : (
          //   <Link to="/subscriptionscreen" className="solution-btn-container">
          //     <div className="solution-btn green">Solution</div>
          //   </Link>
          // )} */}
        </div>

        <div className="solution-wrapper-notes">
          <div className="solution-notes">
            <b style={{ paddingRight: '5px' }}>Note: {" "}</b>
            <FormattedMessage
              id="noteforsolution"
              defaultMessage="
                Payments for the solutions are being made directly
                between the users! It is your responsibility to hand over the
                packages/equipment/space only to people you trust. We have no
                responsibility over theft or misuse of equipment. By publishing you give your consent for users who subscribed and/or choose your offers, to receive your phone and email!
                "
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SolutionModal;
