import React from "react";

import { ExchangeUserListItem } from "../CommonComponents";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import GoogleMaps from "../googleMaps";
import "./styles.css";

import SearchModal from "../CommonComponents/SearchModal";
import { Layout, Icon } from "antd";
import { FormattedMessage } from "react-intl";
const { Content } = Layout;

const Suppliers = ({
  toggle,
  collapsed,
  handleDetails,
  allProvideSolutions,
  handleChange,
  handleSubmit,
  onChangeSearchRadio,
  valueRadio,
  handleModal,
  visible,
}) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          headingTitle={
            localStorage.getItem("language") === "fr-ca"
              ? "Fournisseurs"
              : "A Solution of a Supplier"
          }
        />
        <Content
          className="content-style"
          style={{
            padding: 10,
            background: "#fff",
            height: "100vh",
            minHeight: "100vh",
          }}
        >
          <GoogleMaps allProvideSolutions={allProvideSolutions} height="50vh" />
          <div className="title-exchange-user">
            <FormattedMessage
              id="listOfSuppliers"
              defaultMessage="List of Suppliers"
            />
            <div onClick={handleModal} className="search-heading">
              <Icon
                type="search"
                style={{
                  color: "rgba(0,0,0,.25)",
                  fontSize: "20px",
                  marginRight: "5px",
                  marginLeft: "10px",
                }}
              />
              <div>
                <FormattedMessage id="search" defaultMessage="Search" />
              </div>
            </div>
          </div>
          <div className="exchange-list-item--container">

            {allProvideSolutions && allProvideSolutions.length !== 0 ?
              allProvideSolutions &&
              allProvideSolutions.map((solution) => {
                return (
                  <div key={solution._id}>
                    <ExchangeUserListItem
                      solution={solution}
                      deleteBtn={false}
                      handleDetails={handleDetails}
                    />
                  </div>
                );
              }) :
              <h2 style={{ display: 'flex', justifyContent: 'center', color: "#a1947a" }}><FormattedMessage id="NoResult" defaultMessage="No Results" /></h2>}
          </div>
        </Content>
      </Layout>
      <SearchModal
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        onChangeSearchRadio={onChangeSearchRadio}
        valueRadio={valueRadio}
        handleModal={handleModal}
        visible={visible}
      />
    </Layout>
  );
};

export default Suppliers;
