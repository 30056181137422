export const SET_CODE_MESSAGE = "SET_CODE_MESSAGE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SET_AUTH_SIGN_UP_ERROR = "SET_AUTH_SIGN_UP_ERROR"
export const SET_REGISTER_MOBILE = "SET_REGISTER_MOBILE";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS"
export const SET_EXPIRATION_ERROR = "SET_EXPIRATION_ERROR";
export const SET_NEED_SOLUTIONS = "SET_NEED_SOLUTIONS";
export const SET_PROVIDE_SOLUTIONS = "SET_PROVIDE_SOLUTIONS";
export const SET_SOLUTION = "SET_SOLUTION";
export const SET_USER_SOLUTIONS = "SET_USER_SOLUTIONS";
export const SET_USER_ACCEPTED_SOLUTIONS = "SET_USER_ACCEPTED_SOLUTIONS";
export const SOLUTION_ACCEPTED = "SOLUTION_ACCEPTED";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const IS_LOADING = "IS_LOADING";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE"
export const LOC_ENABLED_MODAL = 'LOC_ENABLED_MODAL'
export const TOOLTIP_OFF = 'TOOLTIP_OFF';
