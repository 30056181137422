import React from "react";
import { Layout } from "antd";

import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { ListItem } from "../CommonComponents";
import { SolutionModal } from "../CommonComponents/Modals";

const { Content } = Layout;

const InputCategory = ({
  toggle,
  collapsed,
  handleModal,
  visible,
  handleClick,
  inputCategory,
  category
}) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          check="inputCategory"
          headingTitle={
            localStorage.getItem("language") === "fr-ca"
              ? "Des Intrants"
              : "Inputs"
          }
        />
        <Content className="transport-container content-style">
          <div className="list-container">
            <div
              onClick={() => handleModal("data", inputCategory)}
              className="you-propose-container"
            >
            </div>
            {inputCategory.map(item => (
              <ListItem handleModal={handleModal} key={item.id} data={item} />
            ))}
          </div>
        </Content>
      </Layout>
      <SolutionModal
        handleModal={handleModal}
        visible={visible}
        handleClick={handleClick}
        category="Input"
        subCategoryId={category && category.id}
        categoryData={category && category}
      />
    </Layout>
  );
};

export default InputCategory;
