import smallBox from "./Assests/delivery/Small_Box.png";
import box from "./Assests/delivery/Box.png";
import bag from "./Assests/delivery/images.jpg";
import pallet from "./Assests/delivery/Pallet_full.jpg";
import suitcase from "./Assests/delivery/Suitcase.png";
import international from "./Assests/delivery/International.png";
import user from "./Assests/delivery/Users_free_offers.png";

import ag_machine from "./Assests/equipment/Ag_Machine_2.png";
import tractor from "./Assests/equipment/Tractor.jpg";
import water_pump from "./Assests/equipment/Water_pump.png";

import cow from "./Assests/services/Cow.png";
import services2 from "./Assests/services/Services_2.png";
import services1 from "./Assests/services/Services.png";

import hut from "./Assests/space/African_hut.jpg";
import bed from "./Assests/space/Bed.jpg";
import room from "./Assests/space/Room.jpg";
import storage2 from "./Assests/space/Storage_2.png";
import storage from "./Assests/space/Storage.png";
import villa from "./Assests/space/Villa.png";

import bigCar from "./Assests/transport/Share_ride_big_car.png";
import smallCar from "./Assests/transport/Share_ride_small_car.png";

const delivery = [
  {
    id: 1,
    img: smallBox,
    dollar_price: "2.20",
    euro_price: "1.99",
    fr_price: "1300",
    desp: {
      en: "Small box: 0.01-5Kg or up to 30cm * 30cm",
      fr: "Petite boîte: 0,01-5kg ou jusqu'à 30cm*30cm",
    },
  },
  {
    id: 2,
    img: box,
    dollar_price: "5.00",
    euro_price: "4.49",
    fr_price: "2950",
    desp: {
      en: "Big box: 5.1-50Kg or up to 80cm * 80cm",
      fr: "Grande boîte: 5,1-50kg ou jusqu'à 80cm*80cm",
    },
    // "en-US":{"desp":"Big box: 5.1-50Kg or up to 80cm * 80cm"},
    // "fa-ca":{"desp":"testing for api"}
  },
  {
    id: 3,
    img: bag,
    dollar_price: "7.00",
    euro_price: "6.25",
    fr_price: "4100",
    desp: {
      en: "5.1-50Kg or up to 80cm * 80cm",
      fr: "Grande boîte: 5,1-50kg ou jusqu'à 80cm*80cm",
    },
  },
  {
    id: 4,
    img: pallet,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "Pallet up to 1 ton",
      fr: "Colis jusqu'à 1 tonne",
    },
  },
  {
    id: 5,
    img: suitcase,
    dollar_price: "7.00",
    euro_price: "6.25",
    fr_price: "4100",
    desp: {
      en: "Suitcase",
      fr: "Valise",
    },
  },
  {
    id: 6,
    img: international,
    dollar_price: "5.00",
    euro_price: "4.49",
    fr_price: "2950",
    desp: {
      en: "Small box: 0.1-5kg to another country",
      fr: "Petite boîte: 0,01-5kg vers un autre pays",
    },
  },
  {
    id: 7,
    img: international,
    dollar_price: "5.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "Big box: 5.1-20kg to another country",
      fr: "Grande boîte: 5.1-20kg vers un autre pays",
    },
  },
  // {
  //   id: 8,
  //   img: user,
  //   dollar_price: "1.00",
  //   euro_price: "1.00",
  //   fr_price: "1",
  //   desp: {
  //     en: "you propose...",
  //     fr: "Vous proposez…"
  //   }
  // }
];

const equipment = [
  {
    id: 1,
    img: tractor,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "Tractor per day",
      fr: "Tracteur par jour",
    },
  },
  {
    id: 2,
    img: water_pump,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "Water pump per day",
      fr: "Pompe à eau par jour",
    },
  },

  {
    id: 3,
    img: ag_machine,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "AG Machine per day",
      fr: "AG Machine par jour",
    },
  },

  // {
  //   id: 4,
  //   img: user,
  //   dollar_price: "1.00",
  //   euro_price: "1.00",
  //   fr_price: "1",
  //   desp: {
  //     en: "You propose...",
  //     fr: "Vous proposez…"
  //   }
  // }
];

const space = [
  {
    id: 1,
    img: storage,
    dollar_price: "5.00",
    euro_price: "4.49",
    fr_price: "2950",
    desp: {
      en: "Storage per bag per 1 month",
      fr: "Stockage par sac par 1 mois",
    },
  },
  {
    id: 2,
    img: storage2,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8750",
    desp: {
      en: "Storage per package per 1 month",
      fr: "Stockage par colis par 1 mois",
    },
  },
  {
    id: 3,
    img: bed,
    dollar_price: "2.20",
    euro_price: "1.99",
    fr_price: "1300",
    desp: {
      en: "Bed per 1 night",
      fr: "Lit par 1 nuit",
    },
  },
  {
    id: 4,
    img: room,
    dollar_price: "5.00",
    euro_price: "4.49",
    fr_price: "2950",
    desp: {
      en: "Room per 1 night",
      fr: "Chambre par 1 nuit",
    },
  },
  {
    id: 5,
    img: hut,
    dollar_price: "7.00",
    euro_price: "6.25",
    fr_price: "4100",
    desp: {
      en: "Cabin per 1 night",
      fr: "Cabane par 1 mois",
    },
  },
  {
    id: 6,
    img: villa,
    dollar_price: "15.00",
    euro_price: "13.40",
    fr_price: "8790",
    desp: {
      en: "Villa per 1 night",
      fr: "Villa par 1 mois",
    },
  },
  // {
  //   id: 7,
  //   img: user,
  //   dollar_price: "1.00",
  //   euro_price: "1.00",
  //   fr_price: "1",
  //   desp: {
  //     en: "You propose...",
  //     fr: "Vous proposez…"
  //   }
  // }
];

const transport = [
  {
    id: 1,
    img: smallCar,
    dollar_price: "1.10",
    euro_price: "1.00",
    fr_price: "659",
    desp: {
      en: "Share a ride in small car",
      fr: "Covoiturage en petite voiture",
    },
  },
  {
    id: 2,
    img: bigCar,
    dollar_price: "0.55",
    euro_price: "0.50",
    fr_price: "330",
    desp: {
      en: "Share a ride in big car",
      fr: "Covoiturage en grosse voiture",
    },
  },
  // {
  //   id: 3,
  //   img: user,
  //   dollar_price: "1.00",
  //   euro_price: "1.00",
  //   fr_price: "1",
  //   desp: {
  //     en: "You propose...",
  //     fr: "Vous proposez…"
  //   }
  // }
];

const services = [
  {
    id: 1,
    img: services2,
    dollar_price: "3.10",
    euro_price: "2.90",
    fr_price: "1875",
    desp: {
      en: "Agro-Services per hour",
      fr: "Agro-Service par heure",
    },
  },
  {
    id: 2,
    img: cow,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "659",
    desp: {
      en: "Animal breeding services per hour",
      fr: "Agro-Service par heure",
    },
  },
  {
    id: 3,
    img: services1,
    dollar_price: "25.00",
    euro_price: "23.00",
    fr_price: "7500",
    desp: {
      en: "Agro-service per day",
      fr: "Agro-Service par jour",
    },
  },
  {
    id: 4,
    img: cow,
    dollar_price: "12.50",
    euro_price: "11.50",
    fr_price: "7500",
    desp: {
      en: "Animal breeding services per day",
      fr: "Service d'élevage par jour",
    },
  },
  // {
  //   id: 5,
  //   img: user,
  //   dollar_price: "1.00",
  //   euro_price: "1.00",
  //   fr_price: "1",
  //   desp: {
  //     en: "You propose...",
  //     fr: "Vous proposez…"
  //   }
  // }
];

const youPropose = {
  id: 1,
  img: user,
  dollar_price: "1.00",
  euro_price: "1.00",
  fr_price: "1",
  desp: {
    en: "Your own proposition...",
    fr: "Votre propre proposition...",
  },
};

const vegetables = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const cereal = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const fruits = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const liveStock = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const inputCategory = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const animal = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const leaf = [
  {
  
    id: 1,
    img: user,
    dollar_price: "1.00",
    euro_price: "1.00",
    fr_price: "1",
    desp: {
      en: "Your own proposition...",
      fr: "Votre propre proposition...",
    },
  }
]

const currencies = [
  {
    country: "Afghanistan",
    currencyName: "Afghani",
    currencyCode: "AFN",
    currencySymbol: "؋",
  },
  {
    country: "Albania",
    currencyName: "Lek",
    currencyCode: "ALL",
    currencySymbol: "Lek",
  },
  {
    country: "Algerian dinar",
    currencyName: null,
    currencyCode: "DZD",
    currencySymbol: "DZD",
  },
  {
    country: "Angolan kwanza",
    currencyName: null,
    currencyCode: "AOA",
    currencySymbol: "AOA",
  },
  {
    country: "Argentina",
    currencyName: "Peso",
    currencyCode: "ARS",
    currencySymbol: "$",
  },
  {
    country: "Argentine Austral",
    currencyName: null,
    currencyCode: "ARG",
    currencySymbol: "₳",
  },
  {
    country: "Armenian Dram Sign",
    currencyName: "Dram",
    currencyCode: "AMD",
    currencySymbol: "֏",
  },
  {
    country: "Aruba",
    currencyName: "Guilder",
    currencyCode: "AWG",
    currencySymbol: "ƒ",
  },
  {
    country: "Australia",
    currencyName: "Dollar",
    currencyCode: "AUD",
    currencySymbol: "$",
  },
  {
    country: "Azerbaijan",
    currencyName: "Manat",
    currencyCode: "AZN",
    currencySymbol: "₼",
  },
  {
    country: "Bahamas",
    currencyName: "Dollar",
    currencyCode: "BSD",
    currencySymbol: "$",
  },
  {
    country: "Barbados",
    currencyName: "Dollar",
    currencyCode: "BBD",
    currencySymbol: "$",
  },
  {
    country: "Belarus",
    currencyName: "Ruble",
    currencyCode: "BYR",
    currencySymbol: "p.",
  },
  {
    country: "Belize",
    currencyName: "Dollar",
    currencyCode: "BZD",
    currencySymbol: "BZ$",
  },
  {
    country: "Bengali Rupee Mark",
    currencyName: "Rupee",
    currencyCode: "Rupee",
    currencySymbol: "৲",
  },
  {
    country: "Bermuda",
    currencyName: "Dollar",
    currencyCode: "BMD",
    currencySymbol: "$",
  },
  {
    country: "Bitcoin Symbol",
    currencyName: "Bitcoin",
    currencyCode: "Bitcoin",
    currencySymbol: "₿",
  },
  {
    country: "Bolivia",
    currencyName: "Boliviano",
    currencyCode: "BOB",
    currencySymbol: "$b",
  },
  {
    country: "Bosnia and Herzegov",
    currencyName: "Cnaonvertible Marka",
    currencyCode: "BAM",
    currencySymbol: "KM",
  },
  {
    country: "Botswana pula",
    currencyName: "Pula",
    currencyCode: "BWP",
    currencySymbol: "BWP",
  },
  {
    country: "Brazil",
    currencyName: "Real",
    currencyCode: "BRL",
    currencySymbol: "R$",
  },
  {
    country: "British Pound",
    currencyName: "Pound",
    currencyCode: "GBP",
    currencySymbol: "£",
  },
  {
    country: "Brunei",
    currencyName: "Darussalam Dollar",
    currencyCode: "BND",
    currencySymbol: "$",
  },
  {
    country: "Bulgaria",
    currencyName: "Lev",
    currencyCode: "BGN",
    currencySymbol: "лв",
  },
  {
    country: "Burundi franc",
    currencyName: "Franc",
    currencyCode: "BIF",
    currencySymbol: "BIF",
  },
  {
    country: "Cambodia",
    currencyName: "Riel",
    currencyCode: "KHR",
    currencySymbol: "៛",
  },
  {
    country: "Canada",
    currencyName: "Dollar",
    currencyCode: "CAD",
    currencySymbol: "$",
  },
  {
    country: "Cape Verde",
    currencyName: "Escudo",
    currencyCode: "CVE",
    currencySymbol: "CVE",
  },
  {
    country: "Cayman",
    currencyName: "Dollar",
    currencyCode: "KYD",
    currencySymbol: "$",
  },
  {
    country: "Cent Sign",
    currencyName: "Cent",
    currencyCode: "Cent",
    currencySymbol: "¢",
  },
  {
    country: "CFA franc",
    currencyName: "Franc",
    currencyCode: "KMF",
    currencySymbol: "KMF",
  },
  {
    country: "CFA franc",
    currencyName: "Franc",
    currencyCode: "KMF",
    currencySymbol: "KMF",
  },
  {
    country: "CFA franc (Central Af",
    currencyName: "Franc",
    currencyCode: "XAF",
    currencySymbol: "XAF",
  },
  {
    country: "CFA franc (West Afri",
    currencyName: "Franc",
    currencyCode: "XOF",
    currencySymbol: "XOF",
  },
  {
    country: "Chile",
    currencyName: "Peso",
    currencyCode: "CLP",
    currencySymbol: "$",
  },
  {
    country: "China",
    currencyName: "Yuan Renminbi",
    currencyCode: "CNY",
    currencySymbol: "¥",
  },
  {
    country: "Colombia",
    currencyName: "Peso",
    currencyCode: "COP",
    currencySymbol: "$",
  },
  {
    country: "Colon Symbol",
    currencyName: null,
    currencyCode: "Colon",
    currencySymbol: "₡",
  },
  {
    country: "Costa Rica",
    currencyName: "Colon",
    currencyCode: "CRC",
    currencySymbol: "₡",
  },
  {
    country: "Croatia",
    currencyName: "Kuna",
    currencyCode: "HRK",
    currencySymbol: "kn",
  },
  {
    country: "Cruzeiro",
    currencyName: null,
    currencyCode: "CRU",
    currencySymbol: "₢",
  },
  {
    country: "Cuba",
    currencyName: "Peso",
    currencyCode: "CUP",
    currencySymbol: "₱",
  },
  {
    country: "Czech Republic",
    currencyName: "Koruna",
    currencyCode: "CZK",
    currencySymbol: "Kč",
  },
  {
    country: "Democratic Republic",
    currencyName: "Congolese franc",
    currencyCode: "CDF",
    currencySymbol: "CDF",
  },
  {
    country: "Denmark",
    currencyName: "Krone",
    currencyCode: "DKK",
    currencySymbol: "kr",
  },
  {
    country: "Djibouti",
    currencyName: "Djiboutian franc",
    currencyCode: "DJF",
    currencySymbol: "DJF",
  },
  {
    country: "Dominican Republic",
    currencyName: "Peso",
    currencyCode: "DOP",
    currencySymbol: "RD$",
  },
  {
    country: "Dutch Florin",
    currencyName: "Dutch Florin",
    currencyCode: "Florin",
    currencySymbol: "ƒ",
  },
  {
    country: "Egypt",
    currencyName: "Pound",
    currencyCode: "EGP",
    currencySymbol: "£",
  },
  {
    country: "El Salvador",
    currencyName: "Colon",
    currencyCode: "SVC",
    currencySymbol: "$",
  },
  {
    country: "Eritrean nakfa",
    currencyName: "Eritrea",
    currencyCode: "ERN",
    currencySymbol: "ERN",
  },
  {
    country: "Esperanto Spesmilo",
    currencyName: null,
    currencyCode: "Spesmilo",
    currencySymbol: "₷",
  },
  {
    country: "Estonia",
    currencyName: "Kroon",
    currencyCode: "EEK",
    currencySymbol: "kr",
  },
  {
    country: "Ethiopia",
    currencyName: "Ethiopian birr",
    currencyCode: "ETB",
    currencySymbol: "ETB",
  },
  {
    country: "Euro Member",
    currencyName: "Euro",
    currencyCode: "EUR",
    currencySymbol: "€",
  },
  {
    country: "Falkland Islands",
    currencyName: "Pound",
    currencyCode: "FKP",
    currencySymbol: "£",
  },
  {
    country: "Fiji",
    currencyName: "Dollar",
    currencyCode: "FJD",
    currencySymbol: "$",
  },
  {
    country: "French Franc",
    currencyName: "Franc",
    currencyCode: "Franc",
    currencySymbol: "₣",
  },
  {
    country: "Gambia",
    currencyName: "Dalasi",
    currencyCode: "GMD",
    currencySymbol: "GMD",
  },
  {
    country: "Georgia",
    currencyName: "Lari",
    currencyCode: "GEL",
    currencySymbol: "₾",
  },
  {
    country: "Germany",
    currencyName: "German Penny",
    currencyCode: "Penny",
    currencySymbol: "₰",
  },
  {
    country: "Ghana",
    currencyName: "Ghana",
    currencyCode: "GHC",
    currencySymbol: "¢",
  },
  {
    country: "Ghana",
    currencyName: "Ghanaian cedi",
    currencyCode: "GHS",
    currencySymbol: "GHS",
  },
  {
    country: "Ghana",
    currencyName: "Ghanaian cedi",
    currencyCode: "GHS",
    currencySymbol: "GHS",
  },
  {
    country: "Gibraltar",
    currencyName: "Pound",
    currencyCode: "GIP",
    currencySymbol: "£",
  },
  {
    country: "Greece",
    currencyName: "Drachma",
    currencyCode: "Drachma",
    currencySymbol: "₯",
  },
  {
    country: "Guatemala",
    currencyName: "Quetzal",
    currencyCode: "GTQ",
    currencySymbol: "Q",
  },
  {
    country: "Guernsey",
    currencyName: "Pound",
    currencyCode: "GGP",
    currencySymbol: "£",
  },
  {
    country: "Guinea",
    currencyName: "Franc",
    currencyCode: "GNF",
    currencySymbol: "GNF",
  },
  {
    country: "Gujarati",
    currencyName: "Rupee",
    currencyCode: "Rupee",
    currencySymbol: "૱",
  },
  {
    country: "Guyana",
    currencyName: "Dollar",
    currencyCode: "GYD",
    currencySymbol: "$",
  },
  {
    country: "Honduras",
    currencyName: "Lempira",
    currencyCode: "HNL",
    currencySymbol: "L",
  },
  {
    country: "Hong Kong",
    currencyName: "Dollar",
    currencyCode: "HKD",
    currencySymbol: "$",
  },
  {
    country: "Hungary",
    currencyName: "Forint",
    currencyCode: "HUF",
    currencySymbol: "Ft",
  },
  {
    country: "Iceland",
    currencyName: "Krona",
    currencyCode: "ISK",
    currencySymbol: "kr",
  },
  {
    country: "India",
    currencyName: "Rupee",
    currencyCode: "INR",
    currencySymbol: "₹",
  },
  {
    country: "Indonesia",
    currencyName: "Rupiah",
    currencyCode: "IDR",
    currencySymbol: "Rp",
  },
  {
    country: "Iran",
    currencyName: "Rial",
    currencyCode: "IRR",
    currencySymbol: "ریال",
  },
  {
    country: "Isle of Man",
    currencyName: "Pound",
    currencyCode: "IMP",
    currencySymbol: "£",
  },
  {
    country: "Israel",
    currencyName: "New Sheqel",
    currencyCode: "ILS",
    currencySymbol: "₪",
  },
  {
    country: "Jamaica",
    currencyName: "Dollar",
    currencyCode: "JMD",
    currencySymbol: "J$",
  },
  {
    country: "Japan",
    currencyName: "Yen",
    currencyCode: "JPY",
    currencySymbol: "¥",
  },
  {
    country: "Jersey",
    currencyName: "Pound",
    currencyCode: "JEP",
    currencySymbol: "£",
  },
  {
    country: "Kazakhstan",
    currencyName: "Tenge",
    currencyCode: "KZT",
    currencySymbol: "лв",
  },
  {
    country: "Kenya",
    currencyName: "Kenyan shilling",
    currencyCode: "KES",
    currencySymbol: "KES",
  },
  {
    country: "Khmer",
    currencyName: "Riel",
    currencyCode: "Riel",
    currencySymbol: "៛",
  },
  {
    country: "Korea (North)",
    currencyName: "Won",
    currencyCode: "KPW",
    currencySymbol: "₩",
  },
  {
    country: "Korea (South)",
    currencyName: "Won",
    currencyCode: "KRW",
    currencySymbol: "₩",
  },
  {
    country: "Kyrgyzstan",
    currencyName: "Som",
    currencyCode: "KGS",
    currencySymbol: "лв",
  },
  {
    country: "Laos",
    currencyName: "Kip",
    currencyCode: "LAK",
    currencySymbol: "₭",
  },
  {
    country: "Latvia",
    currencyName: "Lat",
    currencyCode: "LVL",
    currencySymbol: "Ls",
  },
  {
    country: "Lebanon",
    currencyName: "Pound",
    currencyCode: "LBP",
    currencySymbol: "£",
  },
  {
    country: "Lesotho",
    currencyName: "Loti",
    currencyCode: "LSL",
    currencySymbol: "LSL",
  },
  {
    country: "Liberia",
    currencyName: "Dollar",
    currencyCode: "LRD",
    currencySymbol: "$",
  },
  {
    country: "Libyan dinar",
    currencyName: null,
    currencyCode: "LYD",
    currencySymbol: "LYD",
  },
  {
    country: "Lilangeni",
    currencyName: null,
    currencyCode: "SZL",
    currencySymbol: "SZL",
  },
  {
    country: "Lithuania",
    currencyName: "Litas",
    currencyCode: "LTL",
    currencySymbol: "Lt",
  },
  {
    country: "Macedonia",
    currencyName: "Denar",
    currencyCode: "MKD",
    currencySymbol: "ден",
  },
  {
    country: "Malaga",
    currencyName: "Malagasy ariary",
    currencyCode: "MGA",
    currencySymbol: "MGA",
  },
  {
    country: "Malaga",
    currencyName: "Malawian kwacha",
    currencyCode: "MWK",
    currencySymbol: "MWK",
  },
  {
    country: "Malaysia",
    currencyName: " Ringgit",
    currencyCode: "MYR",
    currencySymbol: "RM",
  },
  {
    country: "Mauritius",
    currencyName: "Mauritian rupee",
    currencyCode: "MUR",
    currencySymbol: "MUR",
  },
  {
    country: "Mauritius",
    currencyName: "Rupee",
    currencyCode: "MUR",
    currencySymbol: "Rs",
  },
  {
    country: "Mexico",
    currencyName: "Peso",
    currencyCode: "MXN",
    currencySymbol: "$",
  },
  {
    country: "Mongolia",
    currencyName: "Tughrik",
    currencyCode: "MNT",
    currencySymbol: "₮",
  },
  {
    country: "Morocco",
    currencyName: "Moroccan dirham",
    currencyCode: "MAD",
    currencySymbol: "MAD",
  },
  {
    country: "Mozambique",
    currencyName: "Mozambican metical",
    currencyCode: "MZN",
    currencySymbol: "MZN",
  },
  {
    country: "Mozambique",
    currencyName: "Metical",
    currencyCode: "MZN",
    currencySymbol: "MT",
  },
  {
    country: "Namibia",
    currencyName: "Dollar",
    currencyCode: "NAD",
    currencySymbol: "$",
  },
  {
    country: "Nepal",
    currencyName: "Rupee",
    currencyCode: "NPR",
    currencySymbol: "Rs",
  },
  {
    country: "Netherlands",
    currencyName: "Antilles Guilder",
    currencyCode: "ANG",
    currencySymbol: "ƒ",
  },
  {
    country: "New Zealand",
    currencyName: "Dollar",
    currencyCode: "NZD",
    currencySymbol: "$",
  },
  {
    country: "Nicaragua",
    currencyName: "Cordoba",
    currencyCode: "NIO",
    currencySymbol: "C$",
  },
  {
    country: "Nigeria",
    currencyName: "Naira",
    currencyCode: "NGN",
    currencySymbol: "₦",
  },
  {
    country: "Norway",
    currencyName: "Krone",
    currencyCode: "NOK",
    currencySymbol: "kr",
  },
  {
    country: "Oman",
    currencyName: "Rial",
    currencyCode: "OMR",
    currencySymbol: "﷼",
  },
  {
    country: "Ouguiya",
    currencyName: null,
    currencyCode: "MRO",
    currencySymbol: "MRO",
  },
  {
    country: "Pakistan",
    currencyName: "Rupee",
    currencyCode: "PKR",
    currencySymbol: "Rs",
  },
  {
    country: "Panama",
    currencyName: "Balboa",
    currencyCode: "PAB",
    currencySymbol: "B/",
  },
  {
    country: "Paraguay",
    currencyName: "Guarani",
    currencyCode: "PYG",
    currencySymbol: "Gs",
  },
  {
    country: "Peru",
    currencyName: "Nuevo Sol",
    currencyCode: "PEN",
    currencySymbol: "S/",
  },
  {
    country: "Philippines",
    currencyName: "Peso",
    currencyCode: "PHP",
    currencySymbol: "₱",
  },
  {
    country: "Poland",
    currencyName: "Zloty",
    currencyCode: "PLN",
    currencySymbol: "zł",
  },
  {
    country: "Qatar",
    currencyName: "Riyal",
    currencyCode: "QAR",
    currencySymbol: "﷼",
  },
  {
    country: "Romania",
    currencyName: "New Leo",
    currencyCode: "RON",
    currencySymbol: "lei",
  },
  {
    country: "RTGS Dollar",
    currencyName: null,
    currencyCode: "ZBN",
    currencySymbol: "ZBN",
  },
  {
    country: "Russia",
    currencyName: "Ruble",
    currencyCode: "RUB",
    currencySymbol: "₽",
  },
  {
    country: "Rwanda",
    currencyName: "Rwandan franc",
    currencyCode: "RWF",
    currencySymbol: "RWF",
  },
  {
    country: "Saint Helena",
    currencyName: "Pound",
    currencyCode: "SHP",
    currencySymbol: "£",
  },
  {
    country: "São Tomé and Príncipe dobra",
    currencyName: null,
    currencyCode: "STD",
    currencySymbol: "STD",
  },
  {
    country: "Saudi Arabia",
    currencyName: "Rial",
    currencyCode: "SAR",
    currencySymbol: "﷼",
  },
  {
    country: "Serbia",
    currencyName: "Dinar",
    currencyCode: "RSD",
    currencySymbol: "Дин",
  },
  {
    country: "Seychelles",
    currencyName: "Rupee",
    currencyCode: "SCR",
    currencySymbol: "Rs",
  },
  {
    country: "Sierra Leone",
    currencyName: "Sierra Leonean leone",
    currencyCode: "SLL",
    currencySymbol: "SLL",
  },
  {
    country: "Singapore",
    currencyName: "Dollar",
    currencyCode: "SGD",
    currencySymbol: "$",
  },
  {
    country: "Solomon Islands",
    currencyName: "Dollar",
    currencyCode: "SBD",
    currencySymbol: "$",
  },
  {
    country: "Somalia",
    currencyName: "Shilling",
    currencyCode: "SOS",
    currencySymbol: "SOS",
  },
  {
    country: "South Africa",
    currencyName: "Rand",
    currencyCode: "ZAR",
    currencySymbol: "R",
  },
  {
    country: "South Africa",
    currencyName: "South African rand",
    currencyCode: "ZAR",
    currencySymbol: "ZAR",
  },
  {
    country: "South Sudan",
    currencyName: "Pound",
    currencyCode: "SSP",
    currencySymbol: "SSP",
  },
  {
    country: "Spain",
    currencyName: "Spanish Peseta",
    currencyCode: "Spain",
    currencySymbol: "₧",
  },
  {
    country: "Square Yuan",
    currencyName: null,
    currencyCode: "Yuan",
    currencySymbol: "㍐",
  },
  {
    country: "Sri Lanka",
    currencyName: "Rupee",
    currencyCode: "LKR",
    currencySymbol: "Rs",
  },
  {
    country: "Sudanese pound",
    currencyName: null,
    currencyCode: "SDG",
    currencySymbol: "SDG",
  },
  {
    country: "Suriname",
    currencyName: "Dollar",
    currencyCode: "SRD",
    currencySymbol: "$",
  },
  {
    country: "Sweden",
    currencyName: "Krona",
    currencyCode: "SEK",
    currencySymbol: "kr",
  },
  {
    country: "Switzerland",
    currencyName: "Franc",
    currencyCode: "CHF",
    currencySymbol: "CHF",
  },
  {
    country: "Syria",
    currencyName: "Pound",
    currencyCode: "SYP",
    currencySymbol: "£",
  },
  {
    country: "Taiwan",
    currencyName: "New Dollar",
    currencyCode: "TWD",
    currencySymbol: "NT$",
  },
  {
    country: "Tamil",
    currencyName: "Tamil Rupee",
    currencyCode: "Rupee",
    currencySymbol: "௹",
  },
  {
    country: "Tanzanian shilling",
    currencyName: "Tanzania",
    currencyCode: "TZS",
    currencySymbol: "TZS",
  },
  {
    country: "Tenge Sign",
    currencyName: null,
    currencyCode: "Tenge",
    currencySymbol: "₸",
  },
  {
    country: "Thailand",
    currencyName: "Bhat",
    currencyCode: "THB",
    currencySymbol: "฿",
  },
  {
    country: "Tunisia",
    currencyName: "Dinar",
    currencyCode: "TND",
    currencySymbol: "TND",
  },
  {
    country: "Turkey",
    currencyName: "Lira",
    currencyCode: "TRL",
    currencySymbol: "₺",
  },
  {
    country: "Tuvalu",
    currencyName: "Dollar",
    currencyCode: "TVD",
    currencySymbol: "$",
  },
  {
    country: "Uganda",
    currencyName: "Ugandan shilling",
    currencyCode: "UGX",
    currencySymbol: "UGX",
  },
  {
    country: "Ukraine",
    currencyName: "Hryvna",
    currencyCode: "UAH",
    currencySymbol: "₴",
  },
  {
    country: "United Kingdom",
    currencyName: "Pound",
    currencyCode: "GBP",
    currencySymbol: "£",
  },
  {
    country: "United States",
    currencyName: "Dollar",
    currencyCode: "USD",
    currencySymbol: "$",
  },
  {
    country: "Uruguay",
    currencyName: "Peso",
    currencyCode: "UYU",
    currencySymbol: "$U",
  },
  {
    country: "Uzbekistan",
    currencyName: "Som",
    currencyCode: "UZS",
    currencySymbol: "лв",
  },
  {
    country: "Venezuela",
    currencyName: "Bolivar Fuerte",
    currencyCode: "VEF",
    currencySymbol: "Bs",
  },
  {
    country: "Viet Nam",
    currencyName: "Dong",
    currencyCode: "VND",
    currencySymbol: "₫",
  },
  {
    country: "Yemen",
    currencyName: "Rial",
    currencyCode: "YER",
    currencySymbol: "﷼",
  },
  {
    country: "Zambia",
    currencyName: "Zambian kwacha",
    currencyCode: "ZMW",
    currencySymbol: "ZMW",
  },
  {
    country: "Zimbabwe",
    currencyName: "Dollar",
    currencyCode: "ZWD",
    currencySymbol: "Z$",
  },
];

const countrynumbers = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  // {
  //   name: "Aland Islands",
  //   dial_code: "+358",
  //   code: "AX",
  // },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  // {
  //   name: "Antarctica",
  //   dial_code: "+672",
  //   code: "AQ",
  // },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  // {
  //   name: "Canada",
  //   dial_code: "+1",
  //   code: "CA",
  // },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  // {
  //   name: "Christmas Island",
  //   dial_code: "+61",
  //   code: "CX",
  // },
  // {
  //   name: "Cocos (Keeling) Islands",
  //   dial_code: "+61",
  //   code: "CC",
  // },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo,DRC",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland or Aland Islands",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  // {
  //   name: "Guadeloupe",
  //   dial_code: "+590",
  //   code: "GP",
  // },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  // {
  //   name: "Guernsey",
  //   dial_code: "+44",
  //   code: "GG",
  // },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  // {
  //   name: "Guyana",
  //   dial_code: "+595",
  //   code: "GY",
  // },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  // {
  //   name: "Isle of Man",
  //   dial_code: "+44",
  //   code: "IM",
  // },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  // {
  //   name: "Jersey",
  //   dial_code: "+44",
  //   code: "JE",
  // },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea,DPR",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  // {
  //   name: "Mayotte",
  //   dial_code: "+262",
  //   code: "YT",
  // },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia,FSM",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island or Antarctica",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  // {
  //   name: "Norway",
  //   dial_code: "+47",
  //   code: "NO",
  // },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay or Guyana",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion or Mayotte",
    dial_code: "+262",
    code: "RE",
  },
  // {
  //   name: "Saint Barthelemy",
  //   dial_code: "+590",
  //   code: "BL",
  // },
  {
    name: "Saint Helena",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin or Guadeloupe or Saint Barthelemy",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia & GS ",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard Or Norway ",
    dial_code: "+47",
    code: "SJ ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];


const countries=[
  {
    country_ca:"Afghanistan",
    country_fr:"Afghanestan",
  },
  {
    country_ca:"Albania",
    country_fr:"Albanie",
  },
  {
    country_ca:"Algeria",
    country_fr:"Algérie",
  },
  {
    country_ca:"American Samoa",
    country_fr:"Samoa Américaines",
  },
  {
    country_ca:"Andorra",
    country_fr:"Andorre",
  },
  {
    country_ca:"Angola",
    country_fr:"Angola",
  },
  {
    country_ca:"Anguilla",
    country_fr:"Anguilla",
  },
  {
    country_ca:"Antigua and Barbuda",
    country_fr:"Antigua-et-Barbuda",
  },
  {
    country_ca:"Argentina",
    country_fr:"Argentine",
  },
  {
    country_ca:"Armenia",
    country_fr:"Arménie",
  },
  {
    country_ca:"Aruba",
    country_fr:"Aruba",
  },
  {
    country_ca:"Australia",
    country_fr:"Australie",
  },
  {
    country_ca:"Austria",
    country_fr:"Autriche",
  },
  {
    country_ca:"Azerbaijan",
    country_fr:"Azerbaïdjan",
  },
  {
    country_ca:"Bahamas",
    country_fr:"Bahamas",
  },
  {
    country_ca:"Bahrain",
    country_fr:"Bahreïn",
  },
  {
    country_ca:"Bangladesh",
    country_fr:"Bangladesh",
  },
  {
    country_ca:"Barbados",
    country_fr:"Barbade",
  },
  {
    country_ca:"Belarus",
    country_fr:"Bélarus",
  },
  {
    country_ca:"Belgium",
    country_fr:"Belgique",
  },
  {
    country_ca:"Belize",
    country_fr:"Belize",
  },
  {
    country_ca:"Benin",
    country_fr:"Bénin",
  },
  {
    country_ca:"Bermuda",
    country_fr:"Bermudes",
  },
  {
    country_ca:"Bhutan",
    country_fr:"Bhoutan",
  },
  {
    country_ca:"Bhutan",
    country_fr:"Bhoutan",
  },
  {
    country_ca:"Bolivia",
    country_fr:"Bolivie",
  },
  {
    country_ca:"Bosnia and Herzegovina",
    country_fr:"Bosnie-Herzégovine",
  },
  {
    country_ca:"Botswana",
    country_fr:"Botswana",
  },
  {
    country_ca:"Brazil",
    country_fr:"Brésil",
  },
  {
    country_ca:"Brunei Darussalam",
    country_fr:"Brunéi Darussalam",
  },
  {
    country_ca:"Bulgaria",
    country_fr:"Bulgarie",
  },
  {
    country_ca:"Burkina Faso",
    country_fr:"Burkina Faso",
  },
  {
    country_ca:"Burundi",
    country_fr:"Burundi",
  },
  {
    country_ca:"Cambodia",
    country_fr:"Cambodge",
  },
  {
    country_ca:"Cameroon",
    country_fr:"Cameroun",
  },
  {
    country_ca:"Canada",
    country_fr:"Canada",
  },
  {
    country_ca:"Cape Verde",
    country_fr:"Cap-Vert",
  },
  {
    country_ca:"Cayman Islands",
    country_fr:"Îles Caïmans",
  },
  {
    country_ca:"Central African Republic",
    country_fr:"Centrafricaine, République",
  },
  {
    country_ca:"Chad",
    country_fr:"Tchad",
  },
  {
    country_ca:"Chile",
    country_fr:"Chili",
  },
  {
    country_ca:"China",
    country_fr:"Chine",
  },
  {
    country_ca:"Christmas Island",
    country_fr:"Île Christmas",
  },
  {
    country_ca:"Cocos (Keeling) Islands",
    country_fr:"Îles Cocos (Keeling)",
  },
  {
    country_ca:"Colombia",
    country_fr:"Colombie",
  },
  {
    country_ca:"Comoros",
    country_fr:"Colombie",
  },
  {
    country_ca:"Democratic Republic of the Congo",
    country_fr:"Congo, La République Démocratique du",
  },
  {
    country_ca:"Congo, Republic of (Brazzaville)",
    country_fr:"République du Congo",
  },
  {
    country_ca:"Cook Islands",
    country_fr:"Îles Cook",
  },
  {
    country_ca:"Costa Rica",
    country_fr:"Costa Rica",
  },
  {
    country_ca:"Côte D'ivoire (Ivory Coast)",
    country_fr:"Côte D'ivoire",
  },
  {
    country_ca:"Croatia",
    country_fr:"Croatie",
  },
  {
    country_ca:"Cuba",
    country_fr:"Cuba",
  },
  {
    country_ca:"Cyprus",
    country_fr:"Chypre",
  },
  {
    country_ca:"Czech Republic",
    country_fr:"République Tchèque",
  },
  {
    country_ca:"Czech Republic",
    country_fr:"République Tchèque",
  },
  {
    country_ca:"Denmark",
    country_fr:"Danemark",
  },
  {
    country_ca:"Djibouti",
    country_fr:"Djibouti",
  },
  {
    country_ca:"Dominica",
    country_fr:"Dominique",
  },
  {
    country_ca:"Dominican Republic",
    country_fr:"Dominicaine, République",
  },
  {
    country_ca:"East Timor (Timor-Leste)",
    country_fr:"Timor-Leste (Timor Oriental)",
  },
  {
    country_ca:"Ecuador",
    country_fr:"Équateur",
  },
  {
    country_ca:"Egypt",
    country_fr:"Égypte",
  },
  {
    country_ca:"El Salvador",
    country_fr:"El Salvador",
  },
  {
    country_ca:"Equatorial Guinea",
    country_fr:"Guinée Équatoriale",
  },
  {
    country_ca:"Eritrea",
    country_fr:"Érythrée",
  },
  {
    country_ca:"Estonia",
    country_fr:"Estonie",
  },
  {
    country_ca:"Ethiopia",
    country_fr:"Éthiopie",
  },
  {
    country_ca:"Falkland Islands",
    country_fr:"Falkland, Îles (Malvinas)",
  },
  {
    country_ca:"Faroe Islands",
    country_fr:"Îles Féroé",
  },
  {
    country_ca:"Fiji",
    country_fr:"Fidji",
  },
  {
    country_ca:"Finland",
    country_fr:"Finlande",
  },
  {
    country_ca:"France",
    country_fr:"France",
  },
  {
    country_ca:"French Guiana",
    country_fr:"Guyane Française",
  },
  {
    country_ca:"French Polynesia",
    country_fr:"Polynésie Française",
  },
  {
    country_ca:"French Southern Territories",
    country_fr:"Terres Australes Françaises",
  },
  {
    country_ca:"Gabon",
    country_fr:"Gabon",
  },
  {
    country_ca:"The Gambia",
    country_fr:"Gambie",
  },
  {
    country_ca:"Georgia",
    country_fr:"Géorgie",
  },
  {
    country_ca:"Germany",
    country_fr:"Allemagne",
  },
  {
    country_ca:"Ghana",
    country_fr:"Ghana",
  },
  {
    country_ca:"Gibraltar",
    country_fr:"Gibraltar",
  },
  {
    country_ca:"Greece",
    country_fr:"Grèce",
  },
  {
    country_ca:"Greenland",
    country_fr:"Groenland",
  },
  {
    country_ca:"Grenada",
    country_fr:"Grenade",
  },
  {
    country_ca:"Guadeloupe",
    country_fr:"Guadeloupe",
  },
  {
    country_ca:"Guam",
    country_fr:"Guam",
  },
  {
    country_ca:"Guatemala",
    country_fr:"Guatemala",
  },
  {
    country_ca:"Guinea",
    country_fr:"Guinée",
  },
  {
    country_ca:"Guinea-Bissau",
    country_fr:"Guinée-Bissau",
  },
  {
    country_ca:"Guyana",
    country_fr:"Guyana",
  },
  {
    country_ca:"Haiti",
    country_fr:"Haïti",
  },
  {
    country_ca:"Holy See",
    country_fr:"Saint-Siège (État de la Cité du Vatican)",
  },
  {
    country_ca:"Honduras",
    country_fr:"Honduras",
  },
  {
    country_ca:"Hong Kong",
    country_fr:"Hong-Kong",
  },
  {
    country_ca:"Hungary",
    country_fr:"Hongrie",
  },
  {
    country_ca:"Iceland",
    country_fr:"Islande",
  },
  {
    country_ca:"India",
    country_fr:"Inde",
  },
  {
    country_ca:"Indonesia",
    country_fr:"Indonésie",
  },
  {
    country_ca:"Iran (Islamic Republic of)",
    country_fr:"République Islamique d' Iran",
  },
  {
    country_ca:"Iraq",
    country_fr:"Iraq",
  },
  {
    country_ca:"Ireland",
    country_fr:"Irlande",
  },
  {
    country_ca:"Israel",
    country_fr:"Israël",
  },
  {
    country_ca:"Italy",
    country_fr:"Italie",
  },
  {
    country_ca:"Ivory Coast",
    country_fr:"Côte D'ivoire",
  },
  {
    country_ca:"Jamaica",
    country_fr:"Jamaïque",
  },
  {
    country_ca:"Japan",
    country_fr:"Japon",
  },
  {
    country_ca:"Jordan",
    country_fr:"Jordanie",
  },
  {
    country_ca:"Kazakhstan",
    country_fr:"Kazakstan",
  },
  {
    country_ca:"Kenya",
    country_fr:"Kenya",
  },
  {
    country_ca:"Kiribati",
    country_fr:"Kiribati",
  },
  {
    country_ca:"Korea, Democratic People's Rep. (North Korea)",
    country_fr:"Corée, République Populaire Démocratique de (Corée du Nord)",
  },
  {
    country_ca:"Korea, Republic of (South Korea)",
    country_fr:"Corée, République de (Corée du Sud)",
  },
  {
    country_ca:"Kosovo",
    country_fr:"Kosovo",
  },
  {
    country_ca:"Kuwait",
    country_fr:"Koweït",
  },
  {
    country_ca:"Kyrgyzstan",
    country_fr:"Kirghizistan",
  },
  {
    country_ca:"Lao, People's Democratic Republic",
    country_fr:"Lao, République Démocratique Populaire",
  },
  {
    country_ca:"Latvia",
    country_fr:"Lettonie",
  },
  {
    country_ca:"Lebanon",
    country_fr:"Liban",
  },
  {
    country_ca:"Lesotho",
    country_fr:"Lesotho",
  },
  {
    country_ca:"Liberia",
    country_fr:"Libéria",
  },
  {
    country_ca:"Libya",
    country_fr:"Libye",
  },
  {
    country_ca:"Liechtenstein",
    country_fr:"Liechtenstein",
  },
  {
    country_ca:"Lithuania",
    country_fr:"Lituanie",
  },
  {
    country_ca:"Luxembourg",
    country_fr:"Luxembourg",
  },
  {
    country_ca:"Luxembourg",
    country_fr:"Luxembourg",
  },
  {
    country_ca:"Macau",
    country_fr:"Macao",
  },
  {
    country_ca:"Madagascar",
    country_fr:"Madagascar",
  },
  {
    country_ca:"Malawi",
    country_fr:"Malawi",
  },
  {
    country_ca:"Malaysia",
    country_fr:"Malaisie",
  },
  {
    country_ca:"Maldives",
    country_fr:"Maldives",
  },
  {
    country_ca:"Mali",
    country_fr:"Mali",
  },
  {
    country_ca:"Malta",
    country_fr:"Malte",
  },
  {
    country_ca:"Marshall Islands",
    country_fr:"Îles Marshall",
  },
  {
    country_ca:"Martinique",
    country_fr:"Martinique",
  },
  {
    country_ca:"Mauritania",
    country_fr:"Mauritanie",
  },
  {
    country_ca:"Mauritius",
    country_fr:"Île Maurice",
  },
  {
    country_ca:"Mayotte",
    country_fr:"Mayotte",
  },
  {
    country_ca:"Mexico",
    country_fr:"Mexique",
  },
  {
    country_ca:"Micronesia, Federal States of",
    country_fr:"États Fédérés de Micronésie",
  },
  {
    country_ca:"Moldova, Republic of",
    country_fr:"Moldova, République de",
  },
  {
    country_ca:"Monaco",
    country_fr:"Monaco",
  },
  {
    country_ca:"Mongolia",
    country_fr:"Mongolie",
  },
  {
    country_ca:"Montenegro",
    country_fr:"Monténégro",
  },
  {
    country_ca:"Montserrat",
    country_fr:"Montserrat",
  },
  {
    country_ca:"Morocco",
    country_fr:"Maroc",
  },
  {
    country_ca:"Mozambique",
    country_fr:"Mozambique",
  },
  {
    country_ca:"Mozambique",
    country_fr:"Mozambique",
  },
  {
    country_ca:"Myanmar, Burma",
    country_fr:"Myanmar, Birmanie",
  },
  {
    country_ca:"Namibia",
    country_fr:"Namibie",
  },
  {
    country_ca:"Nauru",
    country_fr:"Nauru",
  },
  {
    country_ca:"Nepal",
    country_fr:"Népal",
  },
  {
    country_ca:"Netherlands",
    country_fr:"Pays-Bas",
  },
  {
    country_ca:"Netherlands Antilles",
    country_fr:"Antilles Néerlandaises",
  },
  {
    country_ca:"New Caledonia",
    country_fr:"Nouvelle-Calédonie",
  },
  {
    country_ca:"New Zealand",
    country_fr:"Nouvelle-Zélande",
  },
  {
    country_ca:"Nicaragua",
    country_fr:"Nicaragua",
  },
  {
    country_ca:"Niger",
    country_fr:"Niger",
  },
  {
    country_ca:"Nigeria",
    country_fr:"Nigéria",
  },
  {
    country_ca:"Niue",
    country_fr:"Nioué",
  },
  {
    country_ca:"North Macedonia",
    country_fr:"Macédoine du Nord",
  },
  {
    country_ca:"Northern Mariana Islands",
    country_fr:"Îles Mariannes du Nord",
  },
  {
    country_ca:"Norway",
    country_fr:"Norvège",
  },
  {
    country_ca:"Oman",
    country_fr:"Oman",
  },
  {
    country_ca:"Pakistan",
    country_fr:"Pakistan",
  },
  {
    country_ca:"Palau",
    country_fr:"Palaos",
  },
  {
    country_ca:"Palestinian territories",
    country_fr:"Autorité Nationale Palestinienne",
  },
  {
    country_ca:"Panama",
    country_fr:"Panama",
  },
  {
    country_ca:"Papua New Guinea",
    country_fr:"Papouasie-Nouvelle-Guinée",
  },
  {
    country_ca:"Paraguay",
    country_fr:"Paraguay",
  },
  {
    country_ca:"Paraguay",
    country_fr:"Paraguay",
  },
  {
    country_ca:"Peru",
    country_fr:"Pérou",
  },
  {
    country_ca:"Philippines",
    country_fr:"Philippines",
  },
  {
    country_ca:"Pitcairn Island",
    country_fr:"Pitcairn",
  },
  {
    country_ca:"Poland",
    country_fr:"Pologne",
  },
  {
    country_ca:"Portugal",
    country_fr:"Portugal",
  },
  {
    country_ca:"Puerto Rico",
    country_fr:"Porto Rico",
  },
  {
    country_ca:"Qatar",
    country_fr:"Qatar",
  },
  {
    country_ca:"Reunion Island",
    country_fr:"Ile de la Réunion",
  },
  {
    country_ca:"Romania",
    country_fr:"Roumanie",
  },
  {
    country_ca:"Russian Federation",
    country_fr:"Russie, Fédération de",
  },
  {
    country_ca:"Rwanda",
    country_fr:"Rwanda",
  },
  {
    country_ca:"Saint Kitts and Nevis",
    country_fr:"Saint-Kitts-et-Nevis",
  },
  {
    country_ca:"Saint Lucia",
    country_fr:"Sainte-Lucie",
  },
  {
    country_ca:"Saint Vincent and the Grenadines",
    country_fr:"Saint Vincent and the Grenadines",
  },
  {
    country_ca:"Samoa",
    country_fr:"Samoa",
  },
  {
    country_ca:"San Marino",
    country_fr:"Saint-Marin",
  },
  {
    country_ca:"Sao Tome and Principe",
    country_fr:"Sao Tomé-et-Principe",
  },
  {
    country_ca:"Saudi Arabia",
    country_fr:"Arabie Saoudite",
  },
  {
    country_ca:"Senegal",
    country_fr:"Sénégal",
  },
  {
    country_ca:"Serbia",
    country_fr:"Serbie",
  },
  {
    country_ca:"Seychelles",
    country_fr:"Seychelles",
  },
  {
    country_ca:"Sierra Leone",
    country_fr:"Sierra Leone",
  },
  {
    country_ca:"Singapore",
    country_fr:"Singapour",
  },
  {
    country_ca:"Slovakia (Slovak Republic)",
    country_fr:"Slovaquie",
  },
  {
    country_ca:"Slovenia",
    country_fr:"Slovénie",
  },
  {
    country_ca:"Solomon Islands",
    country_fr:"Salomon, Îles	S",
  },
  {
    country_ca:"Somalia",
    country_fr:"Somalie",
  },
  {
    country_ca:"South Africa",
    country_fr:"Afrique du Sud",
  },
  {
    country_ca:"South Sudan",
    country_fr:"Soudan du Sud",
  },
  {
    country_ca:"Spain",
    country_fr:"Espagne",
  },
  {
    country_ca:"Sri Lanka",
    country_fr:"Sri Lanka",
  },
  {
    country_ca:"Sudan",
    country_fr:"Soudan",
  },
  {
    country_ca:"Sudan",
    country_fr:"Soudan",
  },
  {
    country_ca:"Suriname",
    country_fr:"Suriname",
  },
  {
    country_ca:"Swaziland (Eswatini)",
    country_fr:"Swaziland",
  },
  {
    country_ca:"Sweden",
    country_fr:"	Suède",
  },
  {
    country_ca:"Switzerland",
    country_fr:"Suisse",
  },
  {
    country_ca:"Syria, Syrian Arab Republic",
    country_fr:"Syrienne, République Arabe",
  },
  {
    country_ca:"Taiwan (Republic of China)",
    country_fr:"Taïwan, Province de Chine",
  },
  {
    country_ca:"Tajikistan",
    country_fr:"Tajikistan",
  },
  {
    country_ca:"Tanzania; officially the United Republic of Tanzania",
    country_fr:"Tanzanie, République-Unie de",
  },
  {
    country_ca:"Thailand",
    country_fr:"Thaïlande",
  },
  {
    country_ca:"Tibet",
    country_fr:"Tibet",
  },
  {
    country_ca:"Timor-Leste (East Timor)",
    country_fr:"Timor-Leste (Timor Oriental)",
  },
  {
    country_ca:"Togo",
    country_fr:"Togo",
  },
  {
    country_ca:"Tokelau",
    country_fr:"Tokelau",
  },
  {
    country_ca:"Tonga",
    country_fr:"Tonga",
  },
  {
    country_ca:"Trinidad and Tobago",
    country_fr:"Trinité-et-Tobago",
  },
  {
    country_ca:"Tunisia",
    country_fr:"Tunisie",
  },
  {
    country_ca:"Turkey",
    country_fr:"Turquie",
  },
  {
    country_ca:"Turkmenistan",
    country_fr:"Turkménistan",
  },
  {
    country_ca:"Turks and Caicos Islands",
    country_fr:"Turks et Caïques, Îles",
  },
  {
    country_ca:"Tuvalu",
    country_fr:"Tuvalu",
  },
  {
    country_ca:"Tuvalu",
    country_fr:"Tuvalu",
  },
  {
    country_ca:"Uganda",
    country_fr:"Ouganda",
  },
  {
    country_ca:"Ukraine",
    country_fr:"Ukraine",
  },
  {
    country_ca:"United Arab Emirates",
    country_fr:"Émirats Arabes Unis",
  },
  {
    country_ca:"United Kingdom",
    country_fr:"Royaume-Uni",
  },
  {
    country_ca:"United States",
    country_fr:"États-Unis",
  },
  {
    country_ca:"Uruguay",
    country_fr:"Uruguay",
  },
  {
    country_ca:"Uzbekistan",
    country_fr:"Ouzbékistan",
  },
  {
    country_ca:"Vanuatu",
    country_fr:"Vanuatu",
  },
  {
    country_ca:"Vatican City State (Holy See)",
    country_fr:"Saint-Siège (État de la Cité du Vatican)",
  },
  {
    country_ca:"Venezuela",
    country_fr:"Venezuela",
  },
  {
    country_ca:"Vietnam",
    country_fr:"Viêt Nam",
  },
  {
    country_ca:"Virgin Islands (British)",
    country_fr:"Îles Vierges Britanniques",
  },
  {
    country_ca:"Virgin Islands (U.S.)",
    country_fr:"Îles Vierges des États-Unis",
  },
  {
    country_ca:"Wallis and Futuna Islands",
    country_fr:"Wallis et Futuna",
  },
  {
    country_ca:"Western Sahara",
    country_fr:"Sahara Occidental",
  },
  {
    country_ca:"Yemen",
    country_fr:"Yémen",
  },
  {
    country_ca:"Zambia",
    country_fr:"Zambie",
  },
  {
    country_ca:"Zimbabwe",
    country_fr:"Zimbabwe",
  }
  
]


export {
  delivery,
  equipment,
  space,
  transport,
  services,
  youPropose,
  currencies,
  countrynumbers,
  vegetables,
  cereal,
  fruits,
  liveStock,
  inputCategory,
  animal,
  leaf,
  countries
};

