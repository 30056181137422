import React from "react";

import TopNavigation from "../Shared/TopNavigation";
import SideNavigation from "../Shared/SideNavigation";
import { Radio, Layout } from "antd";
import axios from "axios";
import "./styles.css";
const { Content } = Layout;

class LangCurrency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      language: "en-us",
      currency: "dollar",
    };
  }
  componentDidMount() {
    this.setState({
      currency: localStorage.getItem("currency"),
      language: localStorage.getItem("language"),
    });
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onChangeLanguage = (e) => {
    this.setState({ language: e.target.value, },
      () => {
        localStorage.setItem("language", this.state.language);
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        axios.get(`${process.env.REACT_APP_DEVELOPMENT_URL}/user/addNotiLanguage?_id=${currentUser._id}&noti_lan=${this.state.language} `)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  onChangeCurrency = (e) => {
    this.setState(
      {
        currency: e.target.value,
      },
      () => {
        localStorage.setItem("currency", this.state.currency);
      }
    );
  };

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    return (
      <Layout>
        <SideNavigation toggle={this.toggle} collapsed={this.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.collapsed}
            headingTitle={
              localStorage.getItem("language") === "fr-ca"
                ? "Langue et devise"
                : "Language & Currency"
            }
          />
          <Content className="lang-content content-style">
            <div className="lang-container">
              <div>
                <div className="lang-title">Language / Langue</div>
                <Radio.Group
                  onChange={this.onChangeLanguage}
                  value={this.state.language}
                >
                  <Radio style={radioStyle} value="en-us">
                    English/Anglaise
                  </Radio>
                  <Radio style={radioStyle} value="fr-ca">
                    French/Française
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="lang-currency-container">
              <div>
                <div className="lang-title">Currency / Devise</div>
                <Radio.Group
                  onChange={this.onChangeCurrency}
                  value={this.state.currency}
                >
                  <Radio style={radioStyle} value="dollar">
                    U.S. Dollar
                  </Radio>
                  <Radio style={radioStyle} value="euro">
                    Euro
                  </Radio>
                  <Radio style={radioStyle} value="fr">
                    Africian Franc
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default LangCurrency;
