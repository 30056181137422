import React, { useEffect, useState } from "react";
import { Form, Icon, Input, Button, Modal, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../actions";
import SetLanguage from "../../Shared/setLanguge";
import { countrynumbers, countries } from "../../../mockData";

import "./styles.css";


const RegisterCountry = props => {
  const history = useHistory();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const [countryCode, setCountryCode] = useState("+93");
  const [mobileNo, setMobileNo] = useState("");
  const [disForError, setDisForError] = useState("none");
  const [disForError2, setDisForError2] = useState("none");
  const [disForMain, setDisForMain] = useState("block");
  const [disForNum, setDisForNum] = useState("block");
  const [disForEmail, setDisForEmail] = useState("block");
  const [emailText, setEmailText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Option } = Select;


  useEffect(() => {
    if (query.get("email") === "exists") {
      setDisForError("block");
      setDisForMain("none");
      setDisForEmail("none");
      setDisForNum("none");
    } else if (
      query.get("email") === "undefined" ||
      query.get("email") === "null"
    ) {
      setDisForEmail("block");
      setDisForError("none");
      setDisForMain("block");
      if (query.get("num") == "true") {
        setDisForNum("none");
      } else {
        setDisForNum("flex");
      }
    } else {
      setDisForError("none");
      setDisForMain("block");
      setDisForEmail("none");
      if (query.get("num") == "true") {
        setDisForNum("none");
      } else {
        setDisForNum("flex");
      }
    }
  }, []);


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const signUpViaFBNumm = async () => {
    var email, num;
    var params;
    if (query.get("email") != "null") {
      email = query.get("email");
    } else email = emailText;
    if (query.get("num") != "false") {
      num = true;
      params = {
        email: email,
        country: selectedCountry,
      };
    } else {
      num = false;
      params = {
        email: email,
        mobile_no: `${countryCode}${mobileNo}`,
        country: selectedCountry,
      };
    }
    if (
      (params.mobile_no && params.country) ||
      (num === true && params.country)
    ) {
      if ((mobileNo.length > 6 && selectedCountry.length > 3) || num == true) {
        await props.signUpViaGoogle(
          params,
          props.history,
          query.get("email"),
          query.get("id")
        );
        setDisForError2("none");
      } else {
        setDisForError2("block");
      }
    } else {
      if (query.get("email") == "null") {
        if (emailText.length > 0 && selectedCountry.length > 3) {
          await props.signUpViaGoogle(
            params,
            props.history,
            query.get("email"),
            query.get("id")
          );
          setDisForError2("none");
        }
      } else {
        setDisForError2("block");
      }
    }
  };
  return (
    <div className="register-container">
      <div className="form-wrapper form-signup-wrapper">
        <div className="register-heading-container">
          <h1 className="register-heading">
            <FormattedMessage id="register" defaultMessage="Register" />
          </h1>
        </div>
        <div
          style={{ textAlign: "center", color: "red", display: disForError }}
        >
          User already exists.
        </div>
        <div
          style={{ textAlign: "center", color: "red", display: disForError2 }}
        >
          {localStorage.getItem("language") === "fr-ca"
            ? "Veuillez remplir tous les champs"
            : "Please complete all the fields"}
        </div>

        <div className='acc_div'
        >
          <div style={{ display: disForMain }}>
            <Form.Item
              style={{
                display: disForEmail,
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              <FormattedMessage id="email" defaultMessage="Email">
                {(placeholder) => (
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={(e) => {
                      setEmailText(e.target.value);
                      setDisForError2("none");
                    }}
                    name="email"
                    placeholder={placeholder}
                    type="email"
                  />
                )}
              </FormattedMessage>
            </Form.Item>
            <Form.Item>
              <div style={{ display: disForNum }}>
                <Select
                  onChange={(e) => {
                    setCountryCode(e);
                    setDisForError2("none");
                  }}
                  defaultValue="+93"
                  className='line196'
                >
                  {countrynumbers &&
                    countrynumbers.map((number, i) => (
                      <Option
                        value={number.dial_code}
                        key={i}
                      >{`${number.name} ${number.dial_code}`}</Option>
                    ))}
                </Select>
                <Input
                  onChange={(e) => setMobileNo(e.target.value)}
                  name="mobileNo"
                  placeholder="3221212454"
                  type="tel"
                />
                <br />
              </div>

              <Form.Item>
                {localStorage.getItem("language") === "fr-ca" ? (
                  <Select
                    placeholder={
                      localStorage.getItem("language") === "fr-ca"
                        ? "Sélectionnez votre pays"
                        : "Select your country"
                    }
                    onChange={(e) => {
                      setSelectedCountry(e);
                      setDisForError2("none");
                    }}
                    className='line196'
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  >
                    {countries &&
                      countries.map((i, idx) => (
                        <Option value={i.country_fr} key={idx}>
                          {i.country_fr}
                        </Option>
                      ))}
                  </Select>
                ) : (
                  <Select
                    placeholder={
                      localStorage.getItem("language") === "fr-ca"
                        ? "Sélectionnez votre pays"
                        : "Select your country"
                    }
                    onChange={(e) => setSelectedCountry(e)}
                    className='line196'
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  >
                    {countries &&
                      countries.map((i, id) => (
                        <Option value={i.country_ca} key={id}>
                          {i.country_ca}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <div className="register-button-container">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="register-form-button"
                    onClick={() => signUpViaFBNumm()}
                  >
                    <FormattedMessage id="register" defaultMessage="Register" />
                  </Button>
                </div>
              </Form.Item>
            </Form.Item>
          </div>
          <Form.Item>
            <div className="create-account-container">
              <Link to="/login"
                className="create-account-link borderbtn">
                <FormattedMessage
                  id="loginIntoAccount"
                  defaultMessage="Login to Account"
                />
              </Link>
            </div>
          </Form.Item>
        </div>
      </div>
      <SetLanguage value="Id" />
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    codeVerificationStatus: state.auth.codeVerificationStatus,
    authError: state.auth.authSignupError,
    validtionErrors: state.auth.validtionErrors,
    registerMobile: state.auth.registerMobile,
  }
}

const WrappedRegisterCountry = Form.create({ name: "signup-form" })(
  RegisterCountry);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedRegisterCountry);
