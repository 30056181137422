import React, { Fragment } from "react";
import { Drawer, Icon } from "antd";
import MenuItems from "./MenuItems";
import logo from "../../Assests/logo.png";
import "./shared.css";
import "../../App.css";
import MenuItemsWithoutLogin from "./MenuItemsWithoutLogin";

class DrawerNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <Fragment>
        <div className="drawer-container">
          <Icon
            className="trigger icon-style"
            type={this.state.visible ? "menu-unfold" : "menu-fold"}
            style={{ fontSize: "30px", marginRight: "10px", color: "white" }}
            onClick={this.showDrawer}
          />
        </div>
        <Drawer
          className="drawer-wrapper"
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div style={{ textAlign: "center" }}>
            <div className="logo-wrapper">
              <img src={logo} className="logo" alt="Logo" />
            </div>
          </div>
          {localStorage.getItem("currentUser") ? (
            <MenuItems {...this.props} />
          ) : (
            <MenuItemsWithoutLogin {...this.props} />
          )}  
        </Drawer>
      </Fragment>
    );
  }
}

export default DrawerNavigation;
