import createReducer from "../store/createReducer";
import * as types from "../utils/constants";

const initialState = {
  currentUser: JSON.parse(localStorage.getItem("currentUser")),
  isAuthenticated: false,
  loginMessage: false,
  flag: null,
  coords: null,
  tooltip: false
};

const auth = createReducer(initialState, {
  [types.SET_CODE_MESSAGE](state, action) {
    return {
      ...state,
      codeVerificationStatus: action.codeVerificationStatus,
    };
  },
  [types.SET_CURRENT_USER](state, action) {
    return {
      ...state,
      currentUser: action.currentUser,
    };
  },
  [types.LOC_ENABLED_MODAL](state, action) {
    return {
      ...state,
      coords: action.payload
    };
  },
  [types.TOOLTIP_OFF](state, action) {
    return {
      ...state,
      tooltip: action.payload
    };
  },
  [types.SET_AUTH_SIGN_UP_ERROR](state, action) {
    return {
      ...state,
      authSignupError: action.authSignupError,
    };
  },
  [types.SET_AUTH_ERROR](state, action) {
    return {
      ...state,
      authError: action.authError,
    };
  },
  [types.SET_ALL_USERS](state, action) {
    return {
      ...state,
      allUsers: action.allUsers,
    };
  },
  [types.SET_ISAUTHENTICATED](state, action) {
    return {
      ...state,
      isAuthenticated: action.isAuthenticated,
    };
  },
  [types.SET_EXPIRATION_ERROR](state, action) {
    return {
      ...state,
      expirationError: action.expirationError,
    };
  },
  [types.SET_LOGIN_MESSAGE](state, action) {
    return {
      ...state,
      loginMessage: action.loginMessage,
      flag: false
    };
  },
  [types.SET_VALIDATION_ERRORS](state, action) {
    return {
      ...state,
      validtionErrors: action.validtionErrors,
    };
  },
  [types.SET_REGISTER_MOBILE](state, action) {
    return {
      ...state,
      registerMobile: action.registerMobile,
    };
  },
});
export default auth;
