import React from "react";
import { Form, Button } from "antd";
import ReactCodeInput from "react-verification-code-input";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../../../actions";
import SetLanguage from "../../Shared/setLanguge";

import "./styles.css";

class VerifyPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    const params = {
      mobile_no: this.props.location.state.mobileNumber,
      code: this.state.verificationCode
    };
    this.props.codeValidationCheck(
      params,
      this.props.history,
      "codeVerification"
    );
  };

  handleChangecodeVerification = value => {
    this.setState({
      verificationCode: value
    });
  };

  render() {
    return (
      <div className="verify-code-container">
        <div className="form-wrapper">
          <div className="verify-code-heading-container">
            <h1 className="verify-code-heading">
              <FormattedMessage
                id="verifyPasswordTitle"
                defaultMessage="Verify Code"
              />
            </h1>
          </div>
          <Form onSubmit={this.handleSubmit} className="verify-code-form">
            <Form.Item>
              <ReactCodeInput
                onChange={value => this.handleChangecodeVerification(value)}
              />
            </Form.Item>
            <Form.Item>
              <div className="verify-code-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="verify-code-form-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage
                    id="verifyCode"
                    defaultMessage="Verify Code"
                  />
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <SetLanguage />
      </div>
    );
  }
}

const WrappedVerifyPasswordForm = Form.create({ name: "verify-password-form" })(
  VerifyPasswordForm
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    codeVerificationStatus: state.auth.codeVerificationStatus
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedVerifyPasswordForm);
