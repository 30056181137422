import createReducer from "../store/createReducer";
import * as types from "../utils/constants";

const notifications = createReducer(
  {},
  {
    [types.SET_NOTIFICATIONS](state, action) {
      return {
        ...state,
        notifications: action.notifications
      };
    }
  }
);
export default notifications;
