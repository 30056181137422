import * as types from "../utils/constants";
import Api from "../services/api";
import axios from "axios";
import moment from "moment";
import { getNotifications } from "./notifications";
const api = process.env.REACT_APP_PRODUCTION_URL;

function setVerifyCodeFlag(codeVerificationStatus) {
  return {
    type: types.SET_CODE_MESSAGE,
    codeVerificationStatus: codeVerificationStatus,
  };
}

function setCurrentUser(params) {
  return {
    type: types.SET_CURRENT_USER,
    currentUser: params,
  };
}

function setAllUsers(params) {
  return {
    type: types.SET_ALL_USERS,
    allUsers: params,
  };
}

function setIsAuthenticated(params) {
  return {
    type: types.SET_ISAUTHENTICATED,
    isAuthenticated: params,
  };
}

function setLoginMessage(value) {
  return {
    type: types.SET_LOGIN_MESSAGE,
    loginMessage: value,
  };
}

function setAuthError(params) {
  return {
    type: types.SET_AUTH_ERROR,
    authError: params,
  };
}

function setAuthSignUpError(params) {
  return {
    type: types.SET_AUTH_SIGN_UP_ERROR,
    authSignupError: params,
  };
}

function setValidationErrors(params) {
  return {
    type: types.SET_VALIDATION_ERRORS,
    validtionErrors: params,
  };
}

function setRegisterMobile(params) {
  return {
    type: types.SET_REGISTER_MOBILE,
    registerMobile: params,
  };
}

export function signUp(params, history) {
  return (dispatch) =>
    axios
      .post(`${api}/user/register`, params)
      .then(resp => {
        history.push("/login");
      })
      .catch((err) => {
        // console.log(err.message);
        if (err.message == "Request failed with status code 401") {
          dispatch(setAuthSignUpError("Invalid number or Email"));
        } else if (err.message == "Request failed with status code 409") {
          dispatch(setAuthSignUpError("User already exist"));
        }
      });
}

export function signUpViaGoogle(params, history, email, id) {
  return (dispatch) => axios
    .put(
      `https://pickant-production-server.herokuapp.com/user/register-via-fb/${email}/${id}`, params)
    .then(resp => {
      // console.log('ress101', resp)
      this.loginViaGoogle(email, history, id);
    })
    .catch((err) => {
      if (err.message == "Request failed with status code 401") {
        dispatch(setAuthSignUpError("Invalid number or Email"));
      } else if (err.message == "Request failed with status code 409") {
        dispatch(setAuthSignUpError("User already exist"));
      }
    });
}

export function login(params, history) {
  return (dispatch) =>
    Api.post("user/login", params)
      .then((resp) => {
        dispatch(getNotifications(resp.data._id));
        if (resp.data.country) {
          localStorage.setItem("isCountry", true);
        } else {
          localStorage.setItem("isCountry", false);
        }
        if (
          moment(resp.data.subscription.subscription_end_date).diff(
            moment(resp.data.subscription.subscription_start_date),
            "days"
          ) -
          1 ===
          0
        ) {
          dispatch(
            setFreeTrail(
              {
                user_id: resp.data._id,
                auth_key: resp.data.auth_key,
                status: false,
                trail_complete: true,
              },
              history,
              null,
              null
            )
          );
        }
        dispatch(setCurrentUser(resp.data));
        dispatch(setIsAuthenticated(true));
        history.push("/dashboard");
      })
      .catch((err) => {
        dispatch(setAuthError(err.message));
        dispatch(setIsAuthenticated(false));
      });
}

export function loginViaGoogle(email, history, id) {
  return (dispatch) => axios.post(
    `https://pickant-production-server.herokuapp.com/user/loginViaGoogle/` +
    email + "/" + id)
    .then(resp => {
      // console.log('line160', resp.data)
      dispatch(getNotifications(resp.data.user._id));
      if (resp.data?.user.country) {
        localStorage.setItem("isCountry", true);
      } else {
        localStorage.setItem("isCountry", false);
      }
      if (
        moment(resp.data?.user.subscription.subscription_end_date).diff(
          moment(resp.data?.user.subscription.subscription_start_date),
          "days"
        ) - 1 === 0) {
        dispatch(
          setFreeTrail({
            user_id: resp.data.user._id,
            auth_key: resp.data.user.auth_key,
            status: false,
            trail_complete: true,
          },
            history, null, null));
      }
      localStorage.setItem("currentUser", JSON.stringify(resp.data.user));
      localStorage.setItem("isAuthenticated", true);
      if (localStorage.getItem('currentUser')) {
        window.location.reload();
      }
    })
    .catch(err => {
      dispatch(setAuthError(err.message));
      dispatch(setIsAuthenticated(false));
    });
}

export function emptyValidationErrors() {
  return (dispatch) => dispatch(setValidationErrors(""));
}

export const Coords = coords => {
  return {
    type: types.LOC_ENABLED_MODAL,
    payload: coords
  }
}

export const tooltipoff = data => {
  return {
    type: types.TOOLTIP_OFF,
    payload: data
  }
}
export function sendCodeMessage(params, history, component) {
  return (dispatch) =>
    Api.post("user/sendMessage", params)
      .then(resp => {
        if (component === "forgetPassword") {
          history.push({
            pathname: `/verifycode`,
            state: {
              mobileNumber: params.mobile_no,
            },
          });
        } else dispatch(setRegisterMobile(resp.data.mobile_no));
      })
      .catch((err) => {
        dispatch(setValidationErrors(err.message));
      });
}

export function codeValidationCheck(params, history, component) {
  return (dispatch) =>
    Api.post("user/codeValidation", params)
      .then((resp) => {
        if (component === "codeVerification") {
          history.push({
            pathname: `/resetpassword`,
            state: {
              mobileNumber: params.mobile_no,
            },
          });
        } else {
          dispatch(setVerifyCodeFlag(resp.message));
          // setTimeout(() => {
          //   history.push("/login");
          // }, 2000);
        }
      })
      .catch((err) => {
        dispatch(setValidationErrors(err.message));
      });
}

export function emptyVerifyCodeFlag() {
  return (dispatch) => dispatch(setVerifyCodeFlag(""));
}

export function resetPassword(params, history) {
  return (dispatch) =>
    Api.put("user/updatePassword", params)
      .then((resp) => {
        history.push("/login");
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateCountry(params) {
  return (dispatch) =>
    Api.patch("user/updateCountry", params)
      .then((resp) => {
        // history.push("/login");
      })
      .catch((err) => {
        console.log(err);
      });
}


export function getAllUsers(params, history) {
  return (dispatch) =>
    Api.get("user/getAllUsers", params)
      .then((resp) => {
        dispatch(setAllUsers(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateUser(data, history) {
  return (dispatch) =>
    axios
      .put(`${api}/user/updateUser`, data)
      .then((resp) => {
        dispatch(setCurrentUser(resp.data.data));
        localStorage.setItem("currentUser", JSON.stringify(resp.data.data));
        window.location.reload();
      })
      .catch((error) => {
        alert("Oops some error happened, please try again");
      });
}

export function emptyCodeVerifyMessage() {
  return (dispatch) => dispatch(setVerifyCodeFlag(" "));
}

export function handleLoginMessage(flag) {
  return (dispatch) => dispatch(setLoginMessage(flag));
}

export function setAuthErrors(params) {
  return (dispatch) => dispatch(setAuthError(params));
}


export function setFreeTrail(params, history, NotificationManager, displayFlag = true) {
  return (dispatch) =>
    Api.post(`user/updateSubscription`, params)
      .then((resp) => {
        localStorage.setItem("currentUser", JSON.stringify(resp.data));
        if (displayFlag === true) {
          NotificationManager.success(
            localStorage.getItem("language") === "fr-ca"
              ? "Vous avez souscrit avec succès la version d'essai, veuillez sélectionner à nouveau la solution"
              : "You have successfully subscribed trial version,Please select solution again"
          );
        }
        setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
}
