import React from "react";
import { useHistory } from 'react-router-dom'
import { Form, Icon, Input, Button } from "antd";
import { FormattedMessage } from "react-intl";
import Zoom from 'react-medium-image-zoom';
import { NotificationManager } from "react-notifications";
import 'react-medium-image-zoom/dist/styles.css'
import phone from "../../../Assests/phone.png";
import email from "../../../Assests/email.jpg";
import whatsapp from "../../../Assests/whatsapp.png";
import message2 from "../../../Assests/message2.png";

import "./styles.css";
const { TextArea } = Input;

function setfrench(category) {
  if (category === "Delivery") {
    return "Livraison";
  } else if (
    category === "Equipment" ||
    category === "Equipments" ||
    category === "equipment"
  ) {
    return "Équipement";
  } else if (category === "Space") {
    return "Espace";
  } else if (category === "Transport") {
    return "Transport";
  } else if (category === "Services") {
    return "Prestations de service";
  } else if (category === "Vegetables") {
    return "Des légumes";
  } else if (category === "Cereals" || category === "cereal") {
    return "céréale";
  } else if (category === "Fruits" || category === "fruits") {
    return "Des fruits";
  } else if (category === "Input" || category === "inputCategory") {
    return "Des Intrants";
  } else if (category === "Herbs") {
    return "Herbes";
  } else if (category === "Animal Feed" || category === "animal") {
    return "Aliments de bétail";
  } else if (category === "Live Stock" || category === "liveStock") {
    return "Bétail";
  } else {
    return category;
  }
}

const PackageDetailsForm = ({ navigateToDetails, solutionData }) => {
  const his = useHistory();
  const toast = () => {
    return NotificationManager.success(
      localStorage.getItem("language") === "fr-ca"
        ? "Envoyé"
        : "Sent"
    );
  }
  return (
    <div>
      {(solutionData && solutionData.category === "Equipments") ||
        (solutionData && solutionData.category === "Services") ||
        (solutionData && solutionData.category === "Space") ||
        (solutionData && solutionData.category === "Cereals") ||
        (solutionData && solutionData.category === "Vegetables") ||
        (solutionData && solutionData.category === "Live Stock") ||
        (solutionData && solutionData.category === "Fruits") ||
        (solutionData && solutionData.category === "Input") ||
        (solutionData && solutionData.category === "Animal Feed") ||
        (solutionData && solutionData.category === "Herbs") ? (
        <div className="package-detail-solution-container">
          <div className="package-detail-form-wrapper">
            <div className="package-detail-form-container">
              <div className="contact-details">
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"tel:" + solutionData.user.mobile_no}>
                    <img
                      src={phone} alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"https://api.whatsapp.com/send?phone=" + solutionData.user.mobile_no}
                    rel="noopener noreferrer" target="_blank">
                    <img
                      src={whatsapp}
                      alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.email && (
                  <a href={"mailto:" + solutionData.user.email}>
                    <img
                      src={email}
                      alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"sms:" + solutionData.user.mobile_no}>
                    <img
                      src={message2}
                      alt=''
                    />
                  </a>
                )}
              </div>
              <Form className="package-detail-solution-form">
                <div
                  style={{
                    display: "flex",
                    marginRight: "13px",
                    justifyContent: "center",
                  }}
                >
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[0] && (
                      <>
                        <div>
                          <img alt=''
                            style={{ height: "500px", width: "500px" }}
                            src={solutionData.images[0]}
                          />
                        </div>
                      </>
                    )
                    : null}
                </div>
                <div className="otherImages">
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[1] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img alt=''
                              src={solutionData.images[1]}
                              className="img"
                              width='100'
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[2] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img
                              src={solutionData.images[2]}
                              className="img"
                              alt=''
                              width='100'
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[3] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img
                              src={solutionData.images[3]}
                              className="img"
                              width='100'
                              alt=''
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                </div>
                <div className="package-detail-input-title detailTitle">
                  <FormattedMessage
                    id="detailText"
                    defaultMessage="Details"
                  />
                </div>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="categoryName"
                    defaultMessage=" Category Name"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={
                      localStorage.getItem("language") === "fr-ca"
                        ? setfrench(solutionData && solutionData.category)
                        : solutionData && solutionData.category
                    }
                    placeholder="Category name"
                    readOnly={true}
                  />
                </Form.Item>
                 
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="addressText"
                    defaultMessage="Address"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_street_address}
                    placeholder="Address"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="cityText"
                    defaultMessage="City"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_city}
                    placeholder="City"
                  />
                </Form.Item>
                <div>
                  <FormattedMessage
                    id="regionText"
                    defaultMessage="Region"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_region}
                    placeholder="Region"
                  />
                </Form.Item>
                <div className="package-detail-input-title">Postal</div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_postal_address}
                    placeholder="Postal"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="countryText"
                    defaultMessage="Country"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_country}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="dateText"
                    defaultMessage="Date"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_date}
                    placeholder="Date"
                  />
                </Form.Item>
                {
                  // (solutionData &&
                  //   solutionData &&
                  //   solutionData.status === "Accepted") &&
                  JSON.parse(localStorage.getItem("currentUser")).subscription
                    .subscription_flag === true ? (
                    <div>
                      <div className="package-detail-input-title">
                        User Mobile.no
                      </div>
                      <Form.Item>
                        <Input
                          prefix={
                            <Icon
                              type="home"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          value={solutionData && solutionData.user.mobile_no}
                          placeholder="mobile no"
                        />
                      </Form.Item>
                      <div className="package-detail-input-title">
                        <FormattedMessage
                          id="userEmail"
                          defaultMessage="User email"
                        />
                      </div>
                      <Form.Item>
                        <Input
                          prefix={
                            <Icon
                              type="home"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          value={solutionData && solutionData.user.email}
                          placeholder="email"
                        />
                      </Form.Item>
                    </div>
                  ) : null
                }
                {solutionData &&
                  solutionData.sub_category.en === "Your own proposition..." ? (
                  <>
                    <div className="package-detail-input-title">
                      <FormattedMessage
                        id="priceText"
                        defaultMessage="Price"
                      />
                    </div>
                    <Form.Item>
                      <Input
                        value={solutionData && solutionData.display_price}
                        placeholder="price"
                      />
                    </Form.Item>
                  </>
                ) : null}
                <Form.Item>
                  <TextArea
                    name="description"
                    rows={4}
                    value={solutionData && solutionData.description}
                    placeholder="Description"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="package-detail-solution-button-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="package-detail-solution-form-button"
                      // onClick={navigateToDetails}
                      onClick={() => {
                        if (solutionData?.user_role === 'provideSolution' ||
                          solutionData?.user_role === 'fournirSolution') {
                          return navigateToDetails()
                        } else { toast(); his.push('/') }
                      }}
                    >
                      <FormattedMessage id="acceptOffer" defaultMessage="Accept Offer" />
                    </Button>
                  </div>
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div className="package-detail-solution-container">
          <div className="package-detail-form-wrapper">
            <div className="package-detail-form-container">
              <div className="contact-details">
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"tel:" + solutionData.user.mobile_no}>
                    <img
                      src={phone}
                      alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"https://api.whatsapp.com/send?phone=" + solutionData.user.mobile_no}
                    rel="noopener noreferrer"
                    target="_blank">
                    <img
                      src={whatsapp}
                      alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.email && (
                  <a href={"mailto:" + solutionData.user.email}>
                    <img
                      src={email}
                      alt=''
                    />
                  </a>
                )}
                {solutionData && solutionData.user && solutionData.user.mobile_no && (
                  <a href={"sms:" + solutionData.user.mobile_no}>
                    <img
                      src={message2}
                      alt=''
                    />
                  </a>
                )}
              </div>
              <Form className="package-detail-solution-form">
                <div
                  style={{
                    display: "flex",
                    marginRight: "13px",
                    justifyContent: "center",
                  }}
                >
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[0] && (
                      <>
                        <div>
                          <img alt=''
                            style={{ height: "500px", width: "500px" }}
                            src={solutionData.images[0]}
                          />
                        </div>
                      </>
                    )
                    : null}
                </div>
                <div className="otherImages">
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[1] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img
                              src={solutionData.images[1]}
                              alt=''
                              className="img"
                              width='100'
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[2] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img
                              src={solutionData.images[2]}
                              className="img"
                              alt=''
                              width='100'
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                  {solutionData && solutionData.images
                    ? solutionData &&
                    solutionData.images[3] && (
                      <>
                        <div className="divImage">
                          <Zoom zoomMargin={4}>
                            <img
                              src={solutionData.images[3]}
                              className="img"
                              width='100'
                              alt=''
                            />
                          </Zoom>
                        </div>
                      </>
                    )
                    : null}
                </div>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="detailText"
                    defaultMessage="Details"
                  />
                </div>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="categoryName"
                    defaultMessage="Category Name"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={
                      localStorage.getItem("language") === "fr-ca"
                        ? setfrench(solutionData && solutionData.category)
                        : solutionData && solutionData.category
                    }
                    placeholder="Category name"
                    readOnly={true}
                  />
                </Form.Item>
                {/*<div className="package-detail-input-title">
                  <FormattedMessage
                    id="subCategoryName"
                    defaultMessage="Subcategory Name"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={
                      localStorage.getItem("language") === "fr-ca"
                        ? solutionData && solutionData.sub_category.fr
                        : solutionData && solutionData.sub_category.en
                    }
                    placeholder=""
                  />
                </Form.Item>*/}
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="pickupAddress"
                    defaultMessage="Pickup Address"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_street_address}
                    placeholder="City"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="pickupCity"
                    defaultMessage="Pickup City"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_city}
                    placeholder="Region"
                  />
                </Form.Item>
                <div>
                  <FormattedMessage
                    id="pickupRegion"
                    defaultMessage="Pickup Region"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_region}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">Postal</div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_postal_address}
                    placeholder="Region"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="pickupCountry"
                    defaultMessage="Pickup Country"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_country}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="pickupDate"
                    defaultMessage="Pickup Date"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.pickup_date}
                    placeholder="Street Address"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="deliveryAddress"
                    defaultMessage="Delivery Address"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_street_address}
                    placeholder="City"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="deliveryCity"
                    defaultMessage="Delivery City"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_city}
                    placeholder="Region"
                  />
                </Form.Item>
                <div>
                  <FormattedMessage
                    id="deliveryRegion"
                    defaultMessage="Delivery Region"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_region}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">Postal</div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_postal_address}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="deliveryCountry"
                    defaultMessage="Delivery Country"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_country}
                    placeholder="Country"
                  />
                </Form.Item>
                <div className="package-detail-input-title">
                  <FormattedMessage
                    id="deliveryDate"
                    defaultMessage="Delivery Date"
                  />
                </div>
                <Form.Item>
                  <Input
                    prefix={
                      <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    value={solutionData && solutionData.delivery_date}
                    placeholder="Country"
                  />
                </Form.Item>
                {
                  // (solutionData &&
                  //   solutionData &&
                  //   solutionData.status === "Accepted") ||
                  JSON.parse(localStorage.getItem("currentUser")).subscription
                    .subscription_flag ? (
                    <div>
                      <div className="package-detail-input-title">
                        User Mobile.no
                      </div>
                      <Form.Item>
                        <Input
                          prefix={
                            <Icon
                              type="home"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          value={solutionData && solutionData.user.mobile_no}
                          placeholder="mobile no"
                        />
                      </Form.Item>
                      <div className="package-detail-input-title">
                        <FormattedMessage
                          id="userEmail"
                          defaultMessage="User Email"
                        />
                      </div>
                      <Form.Item>
                        <Input
                          prefix={
                            <Icon
                              type="home"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          value={solutionData && solutionData.user.email}
                          placeholder="email"
                        />
                      </Form.Item>
                    </div>
                  ) : null
                }

                <div className="package-detail-input-title">
                  <FormattedMessage id="price" defaultMessage="Price" />
                </div>
                {solutionData &&
                  solutionData.sub_category.en === "Your own proposition..." ? (
                  <Form.Item>
                    <Input
                      value={solutionData && solutionData.display_price}
                      placeholder="price"
                    />
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <TextArea
                    name="description"
                    rows={4}
                    value={solutionData && solutionData.description}
                    placeholder="Description"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="package-detail-solution-button-container">
                    {/* yeh accepted ofoer ka btn hai */}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="package-detail-solution-form-button"
                      // onClick={navigateToDetails}
                      onClick={() => {
                        if (solutionData?.user_role === 'provideSolution' ||
                          solutionData?.user_role === 'fournirSolution') {
                          return navigateToDetails()
                        } else { toast(); his.push('/') }
                      }}
                    >
                      <FormattedMessage id="acceptOffer" defaultMessage="Accept Offer" />
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageDetailsForm;
