import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import { FormattedMessage } from "react-intl";

import "./styles.css";
import { ExchangeUserListItem } from "../CommonComponents";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";

import { Layout, Modal } from "antd";

class MyOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.props.getUserSolutions(this.props.userId, this.props.auth_key);
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleDetails = (id, userId, role) => {
    if (role === "needSolution") {
      this.props.history.push(`/clientsdetails/${id}/${userId}`);
    } else if (role === "provideSolution")
      this.props.history.push(`/supplierdetails/${id}/${userId}`);
  };
  handleDelete = async () => {
    const params = {
      auth_key: this.props.auth_key,
      user_id: this.props.userId,
      solution_id: this.state.solution_id,
    };
    await this.props.deleteSolution(params);
    this.setState({
      modalVisible: false,
    });
  };
  handleConfirmationModal = (id) => {
    this.setState({
      modalVisible: !this.state.modalVisible,
      solution_id: id,
    });
  };
  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout style={{ backgroundColor: "white" }}>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            headingTitle={
              localStorage.getItem("language") === "fr-ca"
                ? "Mes offres"
                : "My Offers"
            }
          />
          <div
            style={{ height: "100vh", overflow: "scroll", paddingTop: "70px" }}
          >
            {this.props.userSolutions &&
              this.props.userSolutions.map((solution, i) => (
                <ExchangeUserListItem
                  key={i}
                  handleDetails={this.handleDetails}
                  component="myOffers"
                  deleteBtn={true}
                  solution={solution}
                  handleConfirmationModal={this.handleConfirmationModal}
                  modalVisible={this.state.modalVisible}
                />
              ))}
          </div>
        </Layout>
        <Modal
          visible={this.state.modalVisible}
          onOk={this.handleDelete}
          onCancel={this.handleConfirmationModal}
          okText={localStorage.getItem("language") === "fr-ca" ? "Oui" : "Yes"}
          cancelText={
            localStorage.getItem("language") === "fr-ca" ? "Non" : "No"
          }
        >
          <h2>
            <FormattedMessage
              id="confirmationText"
              defaultMessage="Are you sure you want to delete the solution?"
            />
          </h2>
        </Modal>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userId: state.auth.currentUser._id,
    auth_key: state.auth.currentUser.auth_key,
    userSolutions: state.solutions.userSolutions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOffers);
