import React from "react";
import { FormattedMessage } from "react-intl";

import { ExchangeUserListItem } from "../CommonComponents";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import GoogleMaps from "../googleMaps";
import "./styles.css";

import { Layout, Icon } from "antd";
import SearchModal from "../CommonComponents/SearchModal";
const { Content } = Layout;

const Clients = (props, { toggle, collapsed }) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          headingTitle={
            localStorage.getItem("language") === "fr-ca"
              ? "Un besoin de clients"
              : "A Need of a Clients"
          }
        />
        <Content
          className="content-style"
          style={{
            padding: "10px",
            background: "#fff",
            height: "100vh",
            minHeight: "100vh",
          }}
        >
          <GoogleMaps allNeedSolutions={props.allNeedSolutions} height="50vh" />
          <div className="title-exchange-user">
            <FormattedMessage
              id="listOfClients"
              defaultMessage="List of Clients"
            />
            <div onClick={props.handleModal} className="search-heading">
              <Icon
                type="search"
                style={{
                  color: "rgba(0,0,0,.25)",
                  fontSize: "20px",
                  marginRight: "5px",
                  marginLeft: "10px",
                }}
              />
              <div>
                <FormattedMessage id="search" defaultMessage="Search" />
              </div>
            </div>
          </div>

          <div className="exchange-list-item--container">
           {props.allNeedSolutions && props.allNeedSolutions.length !== 0 ? 
            props.allNeedSolutions &&
              props.allNeedSolutions.map((solution) => {
                return (
                  <div key={solution._id}>
                    <ExchangeUserListItem
                      solution={solution}
                      deleteBtn={false}
                      handleDetails={props.handleDetails}
                    />
                  </div>
                );
              }):
              <h2 style={{display:'flex',justifyContent:'center',color:"#a1947a"}}><FormattedMessage id="NoResult" defaultMessage="No Results" /></h2>}
          </div>
        </Content>
      </Layout>
      <SearchModal
        handleChange={props.handleChange}
        handleSubmit={props.handleSubmit}
        onChangeSearchRadio={props.onChangeSearchRadio}
        valueRadio={props.valueRadio}
        handleModal={props.handleModal}
        visible={props.visible}
      />
    </Layout>
  );
};

export default Clients;
