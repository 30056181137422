import React from "react";
import { Layout } from "antd";
import { FormattedMessage } from "react-intl";
import deleteBasket from "../../../Assests/deletebasket.png";

import "./styles.css";

const { Content } = Layout;

const ExchangeUserListItem = ({
  handleDetails,
  solution,
  component,
  handleConfirmationModal,
  modalVisible,
  handleDelete,
  deleteBtn,
}) => {
  return (
    <Content className="exchange-user-content--wrapper">
      <div className="exchnage-user-info-row">
        <div>
          <div
            onClick={() =>
              handleDetails(
                solution._id,
                solution.user && solution.user._id,
                solution.user_role
              )
            }
          >
            <div className="exchange-user-flex">
              <div className="exchange-user-price">
                {solution &&
                  solution.sub_category.en === "Your own proposition..." ? (
                  <>{solution.display_price}</>
                ) : (
                  <>
                    {localStorage.getItem("currency") === "dollar"
                      ? `${solution && solution.sub_category_price_dollar} $`
                      : localStorage.getItem("currency") === "euro"
                        ? `${solution && solution.sub_category_price_euro} €`
                        : `${solution && solution.sub_category_price_fr} CFA`}
                  </>
                )}
              </div>
              <div className="exchange-user-from">
                <div className="exchange-userform-div">
                  <FormattedMessage id="from" defaultMessage="From" />:{" "}
                  {solution && solution.pickup_street_address},
                  {solution && solution.pickup_city},
                  {solution && solution.pickup_country}
                </div>
              </div>
              <div className="exchange-user-category">
                {localStorage.getItem("language") === "fr-ca"
                  ? solution && solution.sub_category.fr
                  : solution && solution.sub_category.en}
              </div>
              {component === "myOffers" ? (
                <div
                  style={{
                    color:
                      solution && solution.status === "Pending"
                        ? "red"
                        : "green",
                    fontSize: "10px",
                    fontWeight: "300",
                    marginLeft: "3px",
                  }}
                >
                  {solution && solution.status}
                </div>
              ) : null}
            </div>
            <div className="exchange-user-flex">
              <div className="exchange-user-to"></div>
              {(solution && solution.category === "Equipments") ||
                (solution && solution.category === "Space") ||
                (solution && solution.category === "Services") ? null : (
                <div>
                  <FormattedMessage id="to" defaultMessage="To" />:
                  {solution && solution.delivery_street_address},
                  {solution && solution.delivery_city},
                  {solution && solution.delivery_country}
                </div>
              )}
            </div>
            <div className="exchange-user-flex">
              <div className="exchange-user-to"></div>
              <div className="exchange-user-dep">
                Dep: {solution && solution.pickup_date}
              </div>
              {(solution && solution.category === "Equipments") ||
                (solution && solution.category === "Space") ||
                (solution && solution.category === "Services") ? null : (
                <div>Arrive: {solution && solution.delivery_date}</div>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ maxWidth: "100%" }}>
              <div className="exchange-user-to"></div>{" "}
              <span style={{ fontWeight: "bold" }}>
                <FormattedMessage
                  id="description"
                  defaultMessage="Description"
                />
                :
              </span>
              {solution && solution.description}{" "}
            </div>
            {deleteBtn ? (
              <span
                style={{
                  color: "red",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                onClick={() => handleConfirmationModal(solution._id)}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={deleteBasket} alt=''
                />
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ExchangeUserListItem;
