import React, { Fragment } from "react";
import { Layout } from "antd";
import MediaQuery from "react-responsive";
import "./shared.css";
import "../../App.css";
import MenuItems from "./MenuItems";
import MenuItemsWithOutLogin from "./MenuItemsWithoutLogin";
import logo from "../../Assests/logo.png";
const { Sider } = Layout;

class SideNavigation extends React.Component {
  render() {
    return (
      <Fragment>
        <MediaQuery minDeviceWidth={500}>
          <Sider
            className="menuWrapper"
            trigger={null}
            collapsible
            collapsed={this.props.collapsed}>
            <div style={{ textAlign: "center" }}>
              <img src={logo} className="logo" alt="Logo" />
            </div>
            {localStorage.getItem("currentUser") ? (
              <div>
                <MenuItems {...this.props}
                  collapsed={this.props.collapsed} />
              </div>
            ) : (
              <div>
                <MenuItemsWithOutLogin {...this.props}
                  handleOk={this.props.handleOk}
                  vis={this.props.vis}
                  handleOkabout={this.props.handleOkabout}
                  collapsed={this.props.collapsed} />
              </div>
            )}
          </Sider>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default SideNavigation;
