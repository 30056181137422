import React from "react";
import LiveStock from "../Components/LiveStock";
import { liveStock } from "../mockData";

class LiveStockContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visible: false
    };
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleModal = (string, data) => {
    if (string === "data") {
      this.setState(
        {
          category: data
        });
    }
    this.setState({
      visible: !this.state.visible
    });
  };

  handlClick = value => {
    if (value === "need") {
      this.props.history.push("/needsolutionForm");
    } else if (value === "provide") {
      this.props.history.push("/providesolutionForm");
    }
  };

  render() {
    return (
      <LiveStock
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        liveStock={liveStock}
        visible={this.state.visible}
        handleModal={this.handleModal}
        handleClick={this.handlClick}
        category={this.state.category}
      />
    );
  }
}

export default LiveStockContainer;
