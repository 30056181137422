import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import {
  NotificationManager
} from "react-notifications";

import ActionCreators from "../../actions";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import "./styles.css";

import { Layout } from "antd";
import { Link } from "react-router-dom";
// import Subscription from "../Subscription";
const { Content } = Layout;

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleAccept = () => {
    const { solutionData } = this.props.location.state;
    const comissionAmount = (solutionData.sub_category_price_dollar * 15) / 100;
    const paramsForPay = {
      return_url: `https://pickantapp.com/clientofferaccept?total_price=${comissionAmount}&currency=USD&payment_type=onetime&accpeted_by_id=${this.props.currentUser._id
        }&solution_id=${solutionData &&
        solutionData._id}&user_id=${solutionData && solutionData.user._id}`,
      cancel_url: `https://pickantapp.com/cancel`,
      name: `${solutionData.sub_category.en}`,
      sku: "001",
      // price: `${comissionAmount}`,
      quantity: "1",
      currency: "USD",
      total_price: `${comissionAmount.toFixed(2)}`,
      description: "Pay to get user detail",
      user_id: `${this.props.currentUser._id}`
    };
    this.props.createsOneTimePayment(
      paramsForPay,
      this.props.history,
      solutionData,
      "clientNeedsMatch"
    );
  };

  handleAcceptSubscribeUser = () => {
    const { solutionData } = this.props.location.state;
    const params = {
      user_id: solutionData && solutionData.user._id,
      auth_key: this.props.currentUser.auth_key,
      accepted_by: this.props.currentUser._id,
      solution_id: solutionData && solutionData._id
    };
    this.props.solutionAccepted(params, this.props.history, solutionData);
  };

  handleFreeTrail = () => {
    const params = {
      user_id: this.props.currentUser._id,
      auth_key: this.props.currentUser.auth_key,
      status: true,
      trail_complete: false
    };
    this.props.setFreeTrail(params, this.props.history, NotificationManager);
  };

  render() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const subscription_flag = currentUser.subscription.subscription_flag;
    const { solutionData } = this.props.location.state;
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <Content
            className="content-style"
            style={{
              padding: 10,
              background: "#3B5999",
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div style={{ color: "white" }}>
              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="serviceRequested"
                    defaultMessage="Service requested"
                  />
                  :
                </div>
                <div className="detail-text">
                  {localStorage.getItem("language") === "fr-ca"
                    ? solutionData.sub_category.fr
                    : solutionData.sub_category.en}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupAddress"
                    defaultMessage="Pickup Address"
                  />
                  :
                </div>
                <div className="detail-text">
                  {solutionData.pickup_street_address},
                  {solutionData.pickup_city},{solutionData.pickup_country}
                </div>
              </div>

              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="pickupAddressTime"
                    defaultMessage="Pickup address and time"
                  />
                  :
                </div>
                <div className="detail-text">{solutionData.pickup_date}</div>
              </div>
              {JSON.parse(localStorage.getItem("currentUser")).subscription
                .subscription_flag ? (
                <div>
                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="userEmail"
                        defaultMessage="User Email"
                      />
                      :
                    </div>
                    <div className="detail-text">{solutionData.user.email}</div>
                  </div>

                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="userMobile"
                        defaultMessage="User Mobile.no"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {solutionData.user.mobile_no}
                    </div>
                  </div>
                </div>
              ) : null}

              {!solutionData.category === "Equipment" ||
                !solutionData.category === "Space" ||
                !solutionData.category === "Services" ? (
                <>
                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="deliveryAddress"
                        defaultMessage="Delivery Address"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {" "}
                      {solutionData.delivery_street_address},
                      {solutionData.delivery_city},
                      {solutionData.delivery_country}
                    </div>
                  </div>

                  <div className="details-container">
                    <div className="title-space">
                      <FormattedMessage
                        id="deliveryAddressTime"
                        defaultMessage="Delivery address and time"
                      />
                      :
                    </div>
                    <div className="detail-text">
                      {solutionData.delivery_date}
                    </div>
                  </div>
                  {JSON.parse(localStorage.getItem("currentUser")).subscription
                    .subscription_flag ? (
                    <div>
                      <div className="details-container">
                        <div className="title-space">
                          <FormattedMessage
                            id="userEmail"
                            defaultMessage="User Email"
                          />
                          :
                        </div>
                        <div className="detail-text">
                          {solutionData.user.email}
                        </div>
                      </div>

                      <div className="details-container">
                        <div className="title-space">
                          <FormattedMessage
                            id="userMobile"
                            defaultMessage="User Mobile.no"
                          />
                          :
                        </div>
                        <div className="detail-text">
                          {solutionData.user.mobile_no}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {solutionData.sub_category.en === "Your own proposition..." ? (
                <div className="details-container">
                  <div className="title-space">
                    <FormattedMessage id="price" defaultMessage="Price" />:
                  </div>
                  <div className="detail-text">
                    {solutionData.display_price}
                  </div>
                </div>
              ) : null}
              <div className="details-container">
                <div className="title-space">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                  :
                </div>
                <div className="detail-text">{solutionData.description}</div>
              </div>

              {!subscription_flag ? (
                <div style={{ marginTop: "30px" }}>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <FormattedMessage
                      id="messagePerMonth"
                      defaultMessage="-Get full access to this + all other users contacts"
                    />
                    <br />{" "}
                    <FormattedMessage
                      id="messagePerMonth2"
                      defaultMessage="for only 1.1$/1€/1,850CDF/660CFA a month!"
                    />
                  </div>
                  <div className="choose-plan-div">
                    <FormattedMessage
                      id="chooseAPlan"
                      defaultMessage="Choose a Plan"
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="packageBorderContainer">
                      <Link
                        to="/subscriptions"
                        style={{ color: "white" }}
                        className="package-text"
                      >
                        <div style={{ textAlign: "center", fontSize: "13px" }}>
                          <FormattedMessage
                            id="Subscription"
                            defaultMessage="Subscription"
                          />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "45px",
                            fontWeight: "bold",
                            marginTop: "-12px"
                          }}
                        >
                          12
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "-12px"
                          }}
                        >
                          <FormattedMessage
                            id="months"
                            defaultMessage="months"
                          />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize:
                              localStorage.getItem("currency") === "fr"
                                ? "18px"
                                : "25px"
                          }}
                        >
                          {localStorage.getItem("currency") === "dollar"
                            ? "13$"
                            : localStorage.getItem("currency") === "euro"
                              ? "12€"
                              : "7.900CFA"}
                        </div>
                      </Link>
                    </div>
                    {this.props.currentUser &&
                      this.props.currentUser.subscription.trail_complete ===
                      false ? (
                      <div
                        onClick={this.handleFreeTrail}
                        className="packageBorderContainer"
                      >
                        <div className="package-text">
                          <div
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize:
                                localStorage.getItem("language") === "fr-ca"
                                  ? "21px"
                                  : "25px"
                            }}
                          >
                            <FormattedMessage id="free" defaultMessage="Free" />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                display: "block",
                                fontSize: "12px"
                              }}
                            >
                              90{" "}
                              <FormattedMessage
                                id="days"
                                defaultMessage="days"
                              />
                            </span>{" "}
                            <FormattedMessage
                              id="noCharge"
                              defaultMessage="no charge! to help you during"
                            />
                          </div>
                          <div
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <FormattedMessage
                              id="covid19"
                              defaultMessage="COVID19 times"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {solutionData.sub_category.en ===
                      "Your own proposition..." ? (
                      <></>
                    ) : (
                      <div
                        onClick={this.handleAccept}
                        className="packageBorderContainer"
                      >
                        <div className="package-text">
                          <div style={{ textAlign: "center" }}>
                            '
                            <FormattedMessage
                              id="payAsYouGo"
                              defaultMessage="Pay as you go"
                            />
                            '
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: "15px"
                            }}
                          >
                            <FormattedMessage id="only" defaultMessage="Only" />{" "}
                            15%
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <FormattedMessage
                              id="perDeal"
                              defaultMessage="per deal"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="accept-button-container">
                  <div
                    onClick={this.handleAcceptSubscribeUser}
                    className="accept-button-client"
                  >
                    <FormattedMessage id="accept" defaultMessage="Accept" />
                  </div>
                </div>
              )}
              <div className="accept-button-container">
                <Link to="/dashboard" className="accept-button-client">
                  <FormattedMessage id="no" defaultMessage="No Thanks" />
                </Link>
              </div>
              <div></div>
            </div>
          </Content>
        </Layout>
        {/* <NotificationContainer /> */}
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
