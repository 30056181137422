import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import moment from "moment";

import ActionCreators from "../../actions";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import "./styles.css";

import { Layout } from "antd";
const { Content } = Layout;

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      loading: false
    };
  }

  componentDidMount() {
    const params = {
      grant_type: "client_credentials"
    };
    axios
      .post(
        `https://api.paypal.com/v1/oauth2/token`,
        queryString.stringify(params),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic QWFtcEFCUWJ4SmFic1YycExkUzFxNE4wOF9fQXZDTjNKM1J5RG9wQ09zWUxRdENTeWpwd3I5aW1hT3IyWjNxOEh5cjZ2cmRVdDJRQ2R1eEY6RUE5ZXB3U0Q1NVo3eENBMHRDeHFsSVhMSG5CSk9vVjB5UmlSdFBySWoxX3IzMjBUeU4xdlBBYkpXVkFwOWlOcGNDNXJHdE5ZVXEtb1pKTmU="
          }
        }
      )
      .then(resp => {
        this.setState({ accessToken: resp.data.access_token });
      })
      .catch(err => {
        console.log(err);
      });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  subscribe = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        const params = {
          plan_id: "P-58S088532V118951JL2BGOCQ",
          start_time: moment(new Date())
            .add(30, "m")
            .toDate(),
          quantity: "1",
          shipping_amount: {
            currency_code: "USD",
            value: "0.00"
          },
          subscriber: {
            name: {
              given_name: `${this.props.currentUser.firstName}`,
              surname: `${
                this.props.currentUser.lastName
                  ? this.props.currentUser.lastName
                  : this.props.currentUser.firstName
              }`
            },
            email_address: `${this.props.currentUser.email}`
          },
          application_context: {
            brand_name: "Pickant",
            locale: "en-US",
            shipping_preference: "SET_PROVIDED_ADDRESS",
            user_action: "SUBSCRIBE_NOW",
            payment_method: {
              payer_selected: "PAYPAL",
              payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED"
            },
            return_url: `https://pickantapp.com/subscriptionsuccess?user_id=${this.props.currentUser._id}&amount=13.00&currency=USD`,
            cancel_url: `https://pickantapp.com/subscriptioncancel`
          }
        };
        axios
          .post(`https://api.paypal.com/v1/billing/subscriptions`, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.state.accessToken}`
            }
          })
          .then(resp => {
            window.location.href = `${resp.data.links[0].href}`;
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
          />
          <Content
            className="content-style"
            style={{
              padding: 10,
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div>
              {this.state.loading && this.state.loading ? (
                <div className="loading-subsp">
                  <FormattedMessage
                    id="loading..."
                    defaultMessage="LOADING..."
                  />
                </div>
              ) : null}
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px"
                }}
              >
                Subscription
              </div>
              <p style={{ textAlign: "center" }}>
                <FormattedMessage
                  id="subscriptionMessagePayOnly"
                  defaultMessage="Pay only"
                />
                {localStorage.getItem("currency") === "dollar"
                  ? " 13$"
                  : localStorage.getItem("currency") === "euro"
                  ? " 12€"
                  : " 7.900CFA"}
                <FormattedMessage
                  id="subscriptionMessage"
                  defaultMessage=" now for yearly Subscription and get full access to phone number & email of users"
                />
              </p>
              <p style={{ textAlign: "center" }}>
                <FormattedMessage
                  id="subscriptionMessagePayOnly"
                  defaultMessage="Pay only"
                />
                $13 USD/12€/22.160 CDF/7900 cfa
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div className="contact-container">
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    Senegal: 7900 cfa{" "}
                    <FormattedMessage id="to" defaultMessage="to" />:
                    +2211772041679
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Credit Orange: #116#772041679
                  </p>
                  <p style={{ textAlign: "center" }}>Wari: +2211772041679</p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div className="contact-container">
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    NIGER: 7900 cfa{" "}
                    <FormattedMessage id="to" defaultMessage="to" />:
                    +22790079217
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Credit Orange: #122#+22790079217
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Credit Airtel: +22799676634
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div className="contact-container">
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    DR Congo: $13 USD/22.160 CDF{" "}
                    <FormattedMessage id="to" defaultMessage="to" />:
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Orange Money: *144#0891129911
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Vodacomm m-pesa: *1222#0813751009
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Airtel Money: *501#0999406906
                  </p>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px"
                }}
              >
                OR
              </div>

              {this.state.accessToken && this.state.accessToken ? (
                <div
                  onClick={this.subscribe}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="paypal-button">
                    <FormattedMessage
                      id="payWithPaypal"
                      defaultMessage="Pay with Paypal"
                    />
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="paypal-button">
                    <FormattedMessage
                      id="loading..."
                      defaultMessage="LOADING..."
                    />
                  </div>
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
