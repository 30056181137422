import { LOCALES } from "../constants";

export default {
  [LOCALES.ENGLISH]: {
    "edit-file": "Edit {file} and save to reload.",
    hello: "Hello",
    sendCode: "Send Code",
    acceptOffer: "Accept Offer",
    mobileNumber: "Mobile number (+171741XXXXX)",
    sendCodePlaceholder: "Enter Mobile Number (+171741XXXXX)",
    verifyPasswordTitle: "Verify Code",
    verifyCode: "Verify Code",
    resetPasswordTitle: "Reset Password",
    password: "Password",
    confirmPassword: "Confirm Password",
    resetPassword: "ResetPassword",
    loginTitle: "Login",
    userName: `Username`,
    forgetPassword: "Forgot Password?",
    login: `Login`,
    createAnAccount: "Create An Account",
    register: "Register",
    name: "Name",
    familyName: "Family Name",
    email: "Email",
    mobile: "Mobile",
    physicalAddress: "Physical Address",
    DropFiles: " Drop files here or click to upload",
    loginIntoAccount: "Login into account",
    needSolution: " Post as a",
    provideSolution: " Post as a",
    noteforsolution: `Payments for the solutions are being made directly
                      between the users! It is your responsibility to hand over the
                      packages/equipment/space only to people you trust. We have no
                      responsibility over theft or misuse of equipment. By publishing you give your consent for users who subscribed and/or choose your offers, to receive your phone and email!`,
    need: "Client",
    next: "Next",
    pickUpAddress: "Pickup Address",
    address: "Address",
    city: "City",
    postalCode: "Postal Code",
    region: "Region",
    country: "Country",
    publish: "Publish",
    listOfclients: "List of Clients",
    categoryName: "Category Name",
    subCategoryName: "Subcategory Name",
    pickupAddress: "Pickup Address",
    pickupCity: "Pickup City",
    pickupRegion: "Pickup Region",
    pickupCountry: "Pickup Country",
    pickupDate: "Pickup Date",
    // pickupDate: "Delivery Date",
    deliveryAddress: "Delivery Address",
    deliveryCity: "Delivery City",
    deliveryRegion: "Delivery Region",
    deliveryCountry: "Delivery Country",
    userEmail: "User Email",
    serviceRequested: "Service Requested",
    pickupAddressTime: "Pickup address and time",
    pickupDateime: "Pickup address and time",

    deliveryAddressTime: "Delivery address and time",
    accept: "Accept",
    solution: '',
    desirableDeliveryAddress: "Desirable Delivery Address",
    thanksNote: "Thank you for paying. Your Supplier phone number is:",
    youSupplierEmail: "Your Supplier Email:",
    important: "Important",
    solutionMatchMessage:
      " You accepted this offer, a notification/SMS was sent to the solution provider...",
    "pickup/deliveryDate": "Pickup/Delivery Date",
    subCategory: "Sub-category",
    subCategoryFinal: "Details",
    idAccountMessage: `If you wish that more people will make business with you please provide 
                        us with your full name and ID details and add a photo ID. Identified 
                        users get better rating and have more chances of doing business with 
                        others. We don't share your ID details with other users or any other 
                        entity, and it serves us only for security purposes in-case of 
                        disputes.`,
    idAccountCreation: "ID Account Creation",
    createWithId: "Account with id",
    createWithoutId: "Account without id",
    createWithoutGoogleorFacebook: "Account via Google or Facebook",
    privacyPolicy: "Privacy Policy",
    save: "Save",
    userBalance: "User Balance",
    listOfSuppliers: "List of Suppliers",
    to: "To",
    no: "No Thanks",
    from: "From",
    pay: "Pay",
    scannedIdMessage: "Please upload your scanned ID card ",
    successMessage: "Your transcation has been successfuly processed!",
    cancelMessage: "Your transcation cannot be processed!",
    subscriptionRedirectingMessage:
      "Redirecting to dashboard kindly select the service again",
    messagePerMonth: "-Get full access to this + all other users contacts",
    messagePerMonth2: "for only 1.1$/1€/1,850CDF/660CFA a month!",
    Subscription: "Subscription",
    months: "months",
    chooseAPlan: "Choose a Plan",
    noCharge: "no charge! to help you during",
    covid19: "COVID19 times",
    payAsYouGo: "Pay as you go",
    only: "Only",
    perDeal: "per deal",
    subscriptionMessagePayOnly: "Pay only",
    subscriptionMessage:
      " now for yearly Subscription and get full access to phone number & email of users",
    payWithPaypal: "Pay with Paypal",
    free: "Free",
    days: "days",
    delivery: "Delivery",
    policydesp: `I have read, understood and accepted the`,
    policydesp2: "You agree with our",
    rulesForMemberShip: "rules for membership",
    privacyMemberShip: "privacy policy for membership",
    "loading...": "LOADING...",
    or: "OR",
    or2: "-",
    choose: "Choose from the followings pre-set options below",
    leaveText: "Leave",
    price: "Price",
    subsMessage:
      "This feature is available for subscribers only or during trial period",
    featureNow: "Get this feature NOW!",
    "info-message-login":
      "Going somewhere? Take a package on your way. Have unused equipment? Post it and find clients for it! Post your unused resources in Green/'Solution' or your 'Needs' in Blue on the map Share it with all your contacts, get paid for your Solution or find one for your Need!",
    "info-message-heading": "Gain from your resources:",
    "going-somewhere": "-Going somewhere?",
    "take-package": "Take a package on your way!",
    "unused-equipment": "-Have equipment or services for rent?",
    "find-client": "Post it and find clients for it!",
    "post-green": "Post your resources in Green",
    "post-blue": "- Need something? Post your Needs in Blue",
    inBlue: "in Blue",
    shareWithContacts: "- Share it with all your contacts",
    getPaid: "Get paid for your Solution!",
    findSolutionInfo: "Find solutions to your Need!",
    "need-message": "Need",
    "login-message": "Sign Up",
    memberShipRules: "Membership Rules",
    verifyNumber: "Verify your number with the SMS code you received",
    verifyCodeAndRegister: "Verify Code & Register",
    search: "Search",
    byCountry: "By country",
    byCategory: "By category",
    bySubCategory: "By sub-category",
    mobileAndEmail: "Email/phone number (e.g.+92123452)",
    infoLoginMessage:
      "Please enter phone number with your country code like on international calls",
    NoResult: "No Results",
    confirmationText: "Are you sure you want to delete the solution?",
    confirmationTextOrder: "Are you sure you want to delete the order?",
    noDataFound: "No Data Found",
    fillFields: "Please fill the fields",
    solutionBtn: "Solution",
    equipment: "Equipment",
    space: "Space",
    transport: "Transport",
    services: "Services",
    vegetables: "Vegetables",
    cereal: "Cereals",
    fruits: "Fruits",
    liveStock: "Livestock",
    inputs: "Inputs",
    animalFeed: "Animal feeds",
    herbs: "Herbs",
    home: "Home",
    profile: "Profile",
    selectCountry: "Select Country",
    addressText: "Address",
    cityText: "City",
    pincodeText: "Postal",
    countryText: "Country",
    regionText: "Region",
    dateText: "Date",
    priceText: "Price",
    descText: "Description",
    detailText: "Details",
  },
};
