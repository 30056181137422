import React, { useRef } from "react";
import "./style.css";
import { Modal, Tooltip } from 'antd'
import { FormattedMessage } from "react-intl";

import bike from "../../../Assests/transparentLogos/bike.png";
import man_and_tractor from "../../../Assests/transparentLogos/man_and_tractor.png";
import car from "../../../Assests/transparentLogos/car.png";
import home from "../../../Assests/transparentLogos/house.png";
import setting from "../../../Assests/transparentLogos/setting.png";
import vegetables from "../../../Assests/transparentLogos/vegetables.png";
import corn from "../../../Assests/transparentLogos/corn.png";
import fruits from "../../../Assests/transparentLogos/fruits.png";
import cow_and_hen_ from "../../../Assests/transparentLogos/cow_and_hen_.png";
import seeds from "../../../Assests/transparentLogos/seeds.png";
import hen from "../../../Assests/transparentLogos/hen.png";
import leaf from "../../../Assests/transparentLogos/leaf.png";



const MobileNavItem = ({ setRoute }) => {
  const refs = useRef(null);

  const scroll = el => {
    refs.current.scrollBy({
      top: 0,
      left: +150,
      behavior: 'smooth'
    })
  }

  return (
    <div className="fixed-top-navbar">
      <div className="mobile-nav" style={{
        position: 'relative',
      }}>

        <div className="mobile-nav-rows main_div" ref={refs} >
          <div onClick={() => setRoute("delivery", "Delivery")} className="mobile-nav-col">
            <img src={bike} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="delivery" defaultMessage="Delivery" />
            </span>
          </div>
          <div onClick={() => setRoute("equipment",
            "Equipments")} className="mobile-nav-col">
            <img
              src={man_and_tractor}
              className="mobile-nav-image"
              style={{ padding: "2px" }}
              alt=''
            />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="equipment" defaultMessage="Equipment" />
            </span>
          </div>
          <div onClick={() => setRoute("space", "Space")} className="mobile-nav-col">
            <img src={home} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="space" defaultMessage="Space" />
            </span>
          </div>
          <div onClick={() => setRoute("tranport", "Transport")} className="mobile-nav-col">
            <img src={car} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="transport" defaultMessage="Transport" />
            </span>
          </div>
          {/* starts here */}
          <div onClick={() => setRoute("services", "Services")}
            className="mobile-nav-col">
            <img src={setting} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="services" defaultMessage="Services" />
            </span>
          </div>
          <div onClick={() => setRoute("vegetables", "Vegetables")} className="mobile-nav-col">
            <img src={vegetables} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="vegetables" defaultMessage="Vegetables" />
            </span>
          </div>
          <div onClick={() => setRoute("cereal", "Cereals")} className="mobile-nav-col">
            <img src={corn} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="cereal" defaultMessage="Cereals" />
            </span>
          </div>
          <div onClick={() => setRoute("fruits", "Fruits")} className="mobile-nav-col">
            <img src={fruits} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="fruits" defaultMessage="Fruits" />
            </span>
          </div>
          <div onClick={() => setRoute("liveStock", "Live Stock")} className="mobile-nav-col">
            <img src={cow_and_hen_} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="liveStock" defaultMessage="Livestock" />
            </span>
          </div>

          <div onClick={() => setRoute("inputCategory", "Input")} className="mobile-nav-col">
            <img src={seeds} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="inputs" defaultMessage="Inputs" />
            </span>
          </div>

          <div onClick={() => setRoute("animal", "Animal Feeds")}
            className="mobile-nav-col ml-28" >
            <img src={hen} alt='' className="mobile-nav-image abs_img" />
            <span className="mobile-nav-item-text mytxt"  >
              <FormattedMessage id="animalFeeds"
                defaultMessage="Animal Feeds" />
            </span>
          </div>

          <div onClick={() => setRoute("herbs", "Herbs")}
            className="mobile-nav-col ml-28">
            <img src={leaf} alt='' className="mobile-nav-image" />
            <span className="mobile-nav-item-text">
              <FormattedMessage id="herbs" defaultMessage="Herbs" />
            </span>
          </div>

          <div className="mobile-nav-col">
            <img alt='' className="mobile-nav-images" />
          </div>
        </div>
        <div className='fontstyle right_font' >
          <i className="fal fa-chevron-right right_font"
            onClick={() => { scroll('right') }}></i>
        </div>


      </div>
       
    </div>
  );
};

export default MobileNavItem;
