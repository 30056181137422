import React from "react";
import {
  Form, Icon, Input, Button,
  Checkbox, Select, Modal
} from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../../../actions";
import axios from "axios";
import SetLanguage from "../../Shared/setLanguge";
import { countrynumbers, countries } from "../../../mockData";
import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";

import "./styles.css";
const { Option } = Select;

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      visibleModal: false,
      verificationCode: " ",
      allow: false,
      isRules: false,
      countryCode: "+93",
      isPrivacyPolicy: false,
      selectedCountry: "",
      disForNumField: "none",
      dummyData: [],
      via: "",
      loc_modal: false,
    };
  }

  onUnload = (e) => {
    e.returnValue =
      "Before you decide to leave, remember that by using PickantApp you can earn money! Try to subscribe in the easiest way. Use your Gmail/Facebook and start making money ASAP";
  };
  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  responseGoogle = (response) => {
    if (!response.error) {
      axios
        .get(
          " https://people.googleapis.com/v1/people/" +
          response.googleId +
          "?personFields=phoneNumbers,emailAddresses,addresses&access_token=" +
          response.accessToken,
          {
            headers: {
              Authentication: "Bearer" + response.accessToken,
            },
          })
        .then((resFromGetPeople) => {
          const params = {
            firstName: response.profileObj.givenName,
            lastName: response.profileObj.givenName,
            email: response.profileObj.email,
            mobile_no: resFromGetPeople.data.phoneNumbers[0].canonicalForm,
            admin: false,
            google_auth: true,
            identity_flag: false,
          };
          if (
            params.mobile_no !== null ||
            params.mobile_no !== undefined ||
            resFromGetPeople.data.phoneNumbers[0].canonicalForm
          ) {
            const data = new FormData();
            data.append("new_user", JSON.stringify(params));
            this.props.signUpViaGoogle(data, this.props.history, "g-auth");
            this.props.loginViaGoogle(
              response.profileObj.email,
              this.props.history
            );
          } else {
            this.setState({
              dummyData: params,
              disForNumField: "block",
              via: "fb",
            });
          }
        })
        .catch((err) => { });
    }
  };

  fbPassport = () => {
    window.location.replace(`${process.env.REACT_APP_DEVELOPMENT_URL}/user/auth/facebook`);
  };

  responseFB = (response) => {
    if (!response.error) {

      var name = response.name;
      var [x, y] = name.split(" ");
      const params = {
        firstName: x,
        lastName: y,
        email: response.email,
        admin: false,
        fb_auth: true,
        identity_flag: false,
      };

      this.setState({
        dummyData: params,
        disForNumField: "block",
        via: "fb",
      });

    }
  };

  signUpViaGoogleNum = async () => {
    if (this.state.dummyData) {
      const params = {
        firstName: this.state.dummyData.firstName,
        lastName: this.state.dummyData.lastName,
        email: this.state.dummyData.email,
        mobile_no: `${this.state.countryCode}${this.state.mobileNo}`,
        admin: false,
        google_auth: true,
        country: this.state.dummyData.country || this.state.selectedCountry,
        identity_flag: false,
      };

      const data = new FormData();
      data.append("new_user", JSON.stringify(params));
      await this.props.signUpViaGoogle(data, this.props.history, "g-auth");
      await this.props.loginViaGoogle(
        this.state.dummyData.email,
        this.props.history
      );
    }
  };


  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.error) {
          this.setState({
            error: "",
          });
        }
        if (this.props.authError) {
          this.props.setAuthErrors("");
        }
        if (this.props.validtionErrors) {
          this.props.emptyValidationErrors();
        }
        if (this.props.codeVerificationStatus) {
          this.props.emptyVerifyCodeFlag();
        }
      }
    );
  };

  handleChangecodeVerification = (value) => {
    this.setState({
      verificationCode: value,
    });
  };

  handleModal = () => {
    if (
      this.state.firstName &&
      this.state.email &&
      this.state.mobileNo &&
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword
    ) {
      //////-----Facebook Pixel------///////
      // ReactPixel.init("255747152213510");
      // ReactPixel.track("CompleteRegistration");
      //////-----Facebook Pixel------///////
      this.setState(
        {
          visibleModal: !this.state.visibleModal,
        },
        async () => {
          if (this.state.visibleModal === true) {
            await this.props.sendCodeMessage({
              mobile_no: `${this.state.countryCode}${this.state.mobileNo}`,
              email: this.state.email,
            });
            this.setState({
              allow: true,
            });
          }
          if (this.props.validtionErrors) {
            this.setState({
              allow: false,
            });
          }
          if (this.state.visibleModal === false) {
            this.setState({
              verificationCode: null,
            });
            this.props.emptyCodeVerifyMessage();
          }
        }
      );
    } else {
      alert(
        localStorage.getItem("language") === "fr-ca"
          ? "Veuillez remplir tous les champs"
          : "Please fill all the fields"
      );
    }
  };

  onFilesChange = (e) => {
    this.setState({ selectedFile: e.target.files });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.coords?.latitude) {
      return this.setState({ loc_modal: true })
    }
    if (this.state.isPrivacyPolicy && this.state.isRules) {
      const params = {
        firstName: this.state.firstName,
        lastName: this.state.lastName || '',
        email: this.state.email && this.state.email.toLocaleLowerCase().trim(),
        mobile_no: `${this.state.countryCode}${this.state.mobileNo}`,
        physical_address: this.state.physicalAddress || '',
        password: this.state.password,
        admin: false,
        country: this.state.selectedCountry,
        identity_flag: this.state.selectedFile ? true : false,
      };
      const data = new FormData();
      if (this.state.selectedFile) {
        Object.values(this.state.selectedFile).map(file => {
          return data.append("file", file);
        });
      }
      data.append("new_user", JSON.stringify(params));
      this.props.signUp(data, this.props.history)
    } else {
      alert(
        `${localStorage.getItem("language") === "fr-ca"
          ? "veuillez vérifier les règles de politique de confidentialité et les règles d'adhésion"
          : "please check rules for privacy policy and rules for membership"
        }`
      );
    }
  };

  handleSubmitCode = async (e) => {
    await this.props.codeValidationCheck(
      {
        mobile_no: this.props.registerMobile,
        code: this.state.verificationCode,
        forget_password: false,
      },
      this.props.history,
      null
    );
    if (!this.props.validtionErrors) {
      this.handleSubmit(e);
    } else {
      this.props.emptyValidationErrors();
      alert(
        localStorage.getItem("language") === "fr-ca"
          ? "Enregistrement impossible, veuillez consulter le message d'erreur"
          : "Cannot register please see the error message"
      );
    }
  };

  handleUploadFile = () => {
    let reader = new FileReader();
    reader.readAsDataURL(this.state.files[0]);
    reader.onload = (e) => {
      const formData = { file: e.target.result };
      this.props.uploadFile(formData);
    };
  };

  handleChangeCountry = v => {
    this.setState({
      countryCode: v,
    });
  };

  handleSelectCountry = (value) => {
    this.setState({ selectedCountry: value });
  };

  render() {
    const accountType = this.props.location.state?.id || 'withOutID';
    return (
      <div className="register-container">
        <div className="form-wrapper form-signup-wrapper">
          <Modal visible={!this.props.coords?.latitude && this.state.loc_modal}
            className='loc_modal'
            style={{ textAlign: 'center' }}
            cancelText=''
            closable={false}
            centered
            cancelButtonProps={{ disabled: true, }}
            onOk={() => this.setState({ loc_modal: false })}
          >
            <div>
              <div className="info-message-heading" style={{ textAlign: 'center' }}>
                <p>{localStorage.getItem('language') === 'fr-ca' ?
                  "Assurez-vous que l'emplacement est activé, puis actualisez la page pour pouvoir voir les opportunités commerciales sur la carte." :
                  "Make sure that the location is on, and then refresh the page to be able to see Business-Opportunities on the map."}</p>
              </div>
            </div>
          </Modal>
          <div className="register-heading-container">
            <h1 className="register-heading">
              <FormattedMessage id="register" defaultMessage="Register" />
            </h1>
          </div>
          <div style={{ textAlign: "center", color: "red" }}>
            {this.state.error}
          </div>
          <div style={{ textAlign: "center", color: "red" }}>
            {this.props.authError}
          </div>
          {(accountType === "withID" || accountType === "withOutID") && (
            <>
              <Form onSubmit={this.handleSubmit} className="register-form">
                <Form.Item>
                  <FormattedMessage id="name" defaultMessage="Name">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        onChange={this.handleChange}
                        name="firstName"
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                {accountType === "withID" ? (
                  <Form.Item>
                    <FormattedMessage
                      id="familyName"
                      defaultMessage="Family Name"
                    >
                      {(placeholder) => (
                        <Input
                          prefix={
                            <Icon
                              type="usergroup-add"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          onChange={this.handleChange}
                          name="lastName"
                          placeholder={placeholder}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  {localStorage.getItem("language") === "fr-ca" ? (
                    <Select
                      placeholder={
                        localStorage.getItem("language") === "fr-ca"
                          ? "Sélectionnez votre pays"
                          : "Select your country"
                      }
                      onChange={(e) => this.setState({ selectedCountry: e })}
                      className='ln_select'>
                      {countries &&
                        countries.map((i, id) => (
                          <Option value={i.country_fr} key={id}>
                            {i.country_fr}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      placeholder={
                        localStorage.getItem("language") === "fr-ca"
                          ? "Sélectionnez votre pays"
                          : "Select your country"
                      }
                      onChange={(e) => this.setState({ selectedCountry: e })}
                      className='ln_select'
                    >
                      {countries &&
                        countries.map((i, id) => (
                          <Option value={i.country_ca} key={id}>
                            {i.country_ca}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="email" defaultMessage="Email">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="mail"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        onChange={this.handleChange}
                        name="email"
                        placeholder={placeholder}
                        type="email"
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex" }}>
                    <Select
                      onChange={this.handleChangeCountry}
                      defaultValue="+93"
                      style={{ fontSize: "10px" }}
                      className='ln_select'
                    >
                      {countrynumbers &&
                        countrynumbers.map((number, i) => (
                          <Option
                            value={number.dial_code}
                            key={i}
                          >{`${number.name} ${number.dial_code}`}</Option>
                        ))}
                    </Select>
                    <Input
                      onChange={this.handleChange}
                      name="mobileNo"
                      placeholder="3221212454"
                      type="tel"
                    />
                  </div>
                </Form.Item>
                {accountType === "withID" ? (
                  <>
                    <Form.Item>
                      <FormattedMessage
                        id="physicalAddress"
                        defaultMessage="Physical Address"
                      >
                        {(placeholder) => (
                          <Input
                            prefix={
                              <Icon
                                type="home"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            onChange={this.handleChange}
                            name="physicalAddress"
                            placeholder={placeholder}
                          />
                        )}
                      </FormattedMessage>
                    </Form.Item>
                    <Form.Item>
                      <div className="files">
                        <div
                          style={{
                            color: "#A1947A",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          <FormattedMessage
                            id="scannedIdMessage"
                            defaultMessage="Please upload your scanned ID card "
                          />
                        </div>
                        <div>
                          <input
                            onChange={this.onFilesChange}
                            type="file"
                            accept="image/*"
                            multiple
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </>
                ) : null}

                <Form.Item>
                  <FormattedMessage id="password" defaultMessage="Password">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        onChange={this.handleChange}
                        name="password"
                        placeholder={placeholder}
                        type="password"
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage
                    id="confirmPassword"
                    defaultMessage="Confirm Password"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        onChange={this.handleChange}
                        name="confirmPassword"
                        placeholder={placeholder}
                        type="password"
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item className="privacy-check">
                  <FormattedMessage
                    id="memberShipRules"
                    defaultMessage="Membership Rules"
                  />
                  <div style={{ marginTop: "-5px" }}>
                    <Checkbox
                      checked={this.state.isRules}
                      onChange={() => {
                        this.setState({ isRules: !this.state.isRules });
                      }}
                    />{" "}
                    <FormattedMessage
                      id="policydesp"
                      defaultMessage="I have read, understood and accepted the"
                    />{" "}
                    <Link to="/privacypolicy">
                      <FormattedMessage
                        id="rulesForMemberShip"
                        defaultMessage="rules for member ship"
                      />
                    </Link>
                  </div>
                </Form.Item>
                <Form.Item className="privacy-check">
                  <FormattedMessage
                    id="privacyPolicy"
                    defaultMessage="Privacy Policy"
                  />
                  <div style={{ marginTop: "-10px" }}>
                    <Checkbox
                      checked={this.state.isPrivacyPolicy}
                      onChange={() => {
                        this.setState({
                          isPrivacyPolicy: !this.state.isPrivacyPolicy,
                        });
                      }}
                    />{" "}
                    <FormattedMessage
                      id="policydesp"
                      defaultMessage="I have read, understood and accepted the"
                    />{" "}
                    <Link to="/privacypolicy">
                      <FormattedMessage
                        id="privacyMemberShip"
                        defaultMessage="privacy policy for membership"
                      />
                    </Link>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className="register-button-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="register-form-button"
                      onClick={this.handleSubmit}
                    >
                      <FormattedMessage
                        id="register"
                        defaultMessage="Register"
                      />
                    </Button>
                  </div>
                  <div className="create-account-container" >
                    <Link to="/login" style={{ maxHeight: '35px' }} className="create-account-link borderbtn">
                      <FormattedMessage
                        id="loginIntoAccount"
                        defaultMessage="Login to Account"
                      />
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </>
          )}
          {accountType === "withGorFB" && (
            <div className='acc_div'            >
              {localStorage.getItem("language") === "fr-ca" ? (
                <a
                  className="my-google-button-class"
                  href={`https://pickant-production-server.herokuapp.com/auth/google`}
                >
                  Inscrivez-vous avec Google{"    "}
                  <GoogleOutlined
                    style={{
                      fontSize: "18px",
                      color: "#fff",
                    }}
                  />
                </a>
              ) : (
                <a
                  className="my-google-button-class"
                  // target="_blank"
                  href={`https://pickant-production-server.herokuapp.com/auth/google`}
                >
                  Register via Google{"    "}
                  <GoogleOutlined
                    style={{
                      fontSize: "18px",
                      color: "#fff",
                    }}
                  />
                </a>

              )}
              <br />
              <br />
              <FormattedMessage id="or" defaultMessage="Or" />
              <br />
              <br />
              {localStorage.getItem("language") === "fr-ca" ? (
                <a
                  className="my-facebook-button-class"
                  href={`https://pickant-production-server.herokuapp.com/auth/facebook`}
                >
                  Inscrivez-vous avec Facebook{"    "}
                  <FacebookOutlined
                    className='same_fb'
                  />
                </a>
              ) : (
                <a
                  className="my-facebook-button-class"
                  // target="_blank"
                  href={`https://pickant-production-server.herokuapp.com/auth/facebook`}
                >
                  Login with Facebook{"    "}
                  <FacebookOutlined
                    className='same_fb'
                  />
                </a>
              )}
              <br />
              <br />
              {localStorage.getItem("language") === "fr-ca"
                ? "*En cliquant, "
                : "*By clicking, "}
              <FormattedMessage
                id="policydesp2"
                defaultMessage="you agree with our"
              />{" "}
              <Link to="/privacypolicy">
                <FormattedMessage
                  id="rulesForMemberShip"
                  defaultMessage="rules for member ship"
                />
              </Link>{" "}
              &{" "}
              <Link to="/privacypolicy">
                <FormattedMessage
                  id="rulesForMemberShip"
                  defaultMessage="privacy policy for membership"
                />
              </Link>
              <br />
              <br />
              <div style={{ display: this.state.disForNumField }}>
                <Form.Item>
                  <div style={{ display: "flex" }}>
                    <Select
                      onChange={this.handleChangeCountry}
                      defaultValue="+93"
                      style={{ fontSize: "10px", }}
                      className='ln_select'
                    >
                      {countrynumbers &&
                        countrynumbers.map((number, i) => (
                          <Option
                            value={number.dial_code}
                            key={i}
                          >{`${number.name} ${number.dial_code}`}</Option>
                        ))}
                    </Select>
                    <Input
                      onChange={this.handleChange}
                      name="mobileNo"
                      placeholder="3221212454"
                      type="tel"
                    />
                    <br />
                  </div>

                  <Form.Item>
                    {localStorage.getItem("language") === "fr-ca" ? (
                      <Select
                        placeholder={
                          localStorage.getItem("language") === "fr-ca"
                            ? "Sélectionnez votre pays"
                            : "Select your country"
                        }
                        onChange={(e) => this.setState({ selectedCountry: e })}
                        className='ln_select'
                      >
                        {countries &&
                          countries.map((i, id) => (
                            <Option value={i.country_ca} key={id}>
                              {i.country_ca}
                            </Option>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        placeholder={
                          localStorage.getItem("language") === "fr-ca"
                            ? "Sélectionnez votre pays"
                            : "Select your country"
                        }
                        onChange={(e) => this.setState({ selectedCountry: e })}
                        className='ln_select'
                      >
                        {countries &&
                          countries.map((i, id) => (
                            <Option value={i.country_fr} key={id}>
                              {i.country_fr}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form.Item>
              </div>
              <Form.Item>
                <div className="create-account-container">
                  <Link to="/login"
                    className="create-account-link borderbtn">
                    <FormattedMessage
                      id="loginIntoAccount"
                      defaultMessage="Login to Account"
                    />
                  </Link>
                </div>
              </Form.Item>
            </div>
          )}
        </div>
        <SetLanguage value="Id" />
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create({ name: "signup-form" })(SignUpForm);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    codeVerificationStatus: state.auth.codeVerificationStatus,
    authError: state.auth.authSignupError,
    validtionErrors: state.auth.validtionErrors,
    registerMobile: state.auth.registerMobile,
    coords: state.auth.coords,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSignUpForm);
