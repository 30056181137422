import React from "react";
import { Menu } from "antd";
import MediaQuery from "react-responsive";
import { useHistory } from 'react-router-dom'
import Propose from "../../Assests/You_Propose.png";
import Home from "../../Assests/Home-icon.png";
import About from "../../Assests/About.png";
import Clients from "../../Assests/Clients.png";
import Share from "../../Assests/Share_App.jpg";
import PrivacyPolicy from "../../Assests/Security_Policy.png";
import Mapp from "../../Assests/Map.png";
import Suppliers from "../../Assests/Suppliers.png";
import Delivery from "../../Assests/transparentLogos/bike.png";
import Equipment from "../../Assests/transparentLogos/man_and_tractor.png";
import Transport from "../../Assests/transparentLogos/car.png";
import Space from "../../Assests/transparentLogos/house.png";
import Services from "../../Assests/transparentLogos/setting.png";
import vegetables from "../../Assests/transparentLogos/vegetables.png";
import cereal from "../../Assests/transparentLogos/corn.png";
import liveStock from "../../Assests/transparentLogos/cow_and_hen_.png";
import fruits from "../../Assests/transparentLogos/fruits.png";
import animalFeed from "../../Assests/transparentLogos/hen.png";
import herbs from "../../Assests/transparentLogos/leaf.png";
import input from "../../Assests/transparentLogos/seeds.png";
import "./shared.css";

function shareApplication() {
  if (navigator.share) {
    navigator
      .share({
        title: "Pickantapp",
        text: "Check out pickantapp.",
        url: "https://www.pickantapp.com/"
      })
      .then(() => console.log("Successful share"))
      .catch(error => console.log("Error sharing", error));
  }
}

const MenuItemsWithoutLogin = props => {
  const path = window.location.pathname;
  const history = useHistory();


  let handleOk = () => {
    path.includes('about') ? props.handleOkabout('adnan') :
      props.handleOk()
  }

  return (
    <Menu
      className="menu-container"
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      {path && < Menu.Item >
        <div onClick={() => {
          path.includes('about') && history.push('/')
          // handleOk();
        }}>
          <img
            alt=""
            className="sideNav-img"
            src={Home}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Accueil"
                : "Home"}
            </span>
          ) : null}
        </div>
      </Menu.Item>}
      <Menu.Item>
        <div onClick={() => {
          handleOk();

        }}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Delivery}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Livraison"
                : "Delivery"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Equipment}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Équipement"
                : "Equipment"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Space}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Espace"
                : "Space"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Transport}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Transport"
                : "Transport"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={Services}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Prestations de service"
                : "Services"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={vegetables}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des légumes"
                : "Vegetables"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={cereal}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Céréale"
                : "Cereal"}
            </span>
          ) : null}
        </div>
      </Menu.Item>

      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={fruits}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des fruits"
                : "Fruits"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={liveStock}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Bétail"
                : "Live Stock"}
            </span>
          ) : null}
        </div>
      </Menu.Item>

      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={input}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Des Intrants"
                : "Input"}
            </span>
          ) : null}
        </div>
      </Menu.Item>

      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={animalFeed}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Aliments de bétail"
                : "Animal feeds"}
            </span>
          ) : null}
        </div>
      </Menu.Item>

      <Menu.Item >
        <div onClick={handleOk}>
          <img
            alt=""
            className="side-nav-center-image sideNav-img"
            src={herbs}
          />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "Herbes"
                : "Herbs"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Propose} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Vous proposez…"
                  : "You propose…"}
              </span>
            ) : null}
          </div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => {
        history.push('/about')
      }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img alt="" className="sideNav-img" src={About} />
          {!props.collapsed ? (
            <span className="heading-color">
              {localStorage.getItem("language") === "fr-ca"
                ? "À propos"
                : "About"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => {
        history.push('/privacypolicy')
      }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img alt="" className="sideNav-img" src={PrivacyPolicy} />
          {!props.collapsed ? (
            <span className="heading-color">
              {" "}
              {localStorage.getItem("language") === "fr-ca"
                ? "Politique de confidentialité"
                : "Privacy Policy"}
            </span>
          ) : null}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={handleOk}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Mapp} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca" ? "Carte" : "Map"}
              </span>
            ) : null}
          </div>
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Clients} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Clients"
                  : "Clients"}
              </span>
            ) : null}
          </div>
        </div>
      </Menu.Item>
      <Menu.Item >
        <div onClick={handleOk}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="" className="sideNav-img" src={Suppliers} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Fournisseuses"
                  : "Suppliers"}
              </span>
            ) : null}
          </div>
        </div>
      </Menu.Item>

      <Menu.Item >
        <MediaQuery minDeviceWidth={500}>
          <a
            className="mailto"
            href="mailto:?subject=Pickant App&body=Hi,I found this website and thought you might like it https://www.pickantapp.com/"
          >
            <img alt="" className="sideNav-img" src={Share} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Partager l’application"
                  : "Share App"}
              </span>
            ) : null}
          </a>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={500}>
          <div
            onClick={() => shareApplication()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img alt="" className="sideNav-img" src={Share} />
            {!props.collapsed ? (
              <span className="heading-color">
                {localStorage.getItem("language") === "fr-ca"
                  ? "Partager l’application"
                  : "Share App"}
              </span>
            ) : null}
          </div>
        </MediaQuery>
      </Menu.Item>
    </Menu >
  );
};

export default MenuItemsWithoutLogin;