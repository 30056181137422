import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../actions";
import Suppliers from "../Components/Suppliers";

class SuppliersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visible: false,
      valueRadio: "pickup_country",
    };
  }

  componentDidMount() {
    this.props.getAllSolution("provideSolution", this.props.history);
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleDetails = (id, userId) => {
    this.props.history.push(`/supplierdetails/${id}/${userId}`);
  };

  handleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  onChangeSearchRadio = (e) => {
    this.setState({
      valueRadio: e.target.value,
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    },()=>{
      console.log(this.state.searchInput)
    });
  };

  handleSubmit=async()=>{
    await this.props.searching(this.state.searchInput,"provideSolution",this.state.valueRadio,this.props.currentUser.auth_key,this.props.currentUser._id);
    this.setState({
      visible:false
    })
  }

  render() {
    const { allProvideSolutions } = this.props;
    return (
      <Suppliers
        handleModal={this.handleModal}
        handleChange={this.handleChange}
        visible={this.state.visible}
        handleSubmit={this.handleSubmit}
        onChangeSearchRadio={this.onChangeSearchRadio}
        valueRadio={this.state.valueRadio}
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        handleDetails={this.handleDetails}
        allProvideSolutions={allProvideSolutions}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    allProvideSolutions: state.solutions.allProvideSolutions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersContainer);
