import React from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

import ActionCreators from "../actions";
import "../App.css";
import StartAppDashBoard from "../Components/StartAppDashboard";
import { Link } from "react-router-dom";

class DashBoardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visible: false,
      loc_modal: true,
      modalVisible2: false,
    };
  }

  handleOk2 = () => {
    this.setState({ modalVisible2: false });
    this.props.handleLoginMessage(!this.props.LoginMessage);
  };

  handleCancel2 = () => {
    this.setState({ modalVisible2: false });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.props.handleLoginMessage(!this.props.LoginMessage);
  };

  handleCancel = e => {
    this.props.handleLoginMessage(false);
    this.setState({ visible: false })
  };

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const lang = localStorage.getItem("language");
    this.props.getAllSolution("needSolution", currentUser, lang, this.props.history);
    this.props.getAllSolution("provideSolution", currentUser, lang, this.props.history);
    if (this.props.flag === null) {
      this.setState({ visible: true })
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleLanguage = (value) => {
    localStorage.setItem("language", value);
    window.location.reload();
  };

  
  render() {
    return (
      <div >
        <StartAppDashBoard
          handleOk={this.handleOk}
          allNeedSolutions={this.props.allNeedSolutions}
          allProvideSolutions={this.props.allProvideSolutions}
          collapsed={this.state.collapsed}
          toggle={this.toggle}
        />
        <div className="modal-container">
          
          <Modal
            visible={this.props.LoginMessage || this.state.visible}
            onOk={this.handleOk}
            closable={false}
            style={{ zIndex: 999 }}
            footer={null}
            onCancel={this.handleCancel}>
            <div>
              <div className="info-message-heading">
                <FormattedMessage
                  id="info-message-heading"
                  defaultMessage="Gain from your resources:"
                />
              </div>
              <div style={{ textAlign: "center", wordSpacing: "2px" }}>
                <div>
                  <FormattedMessage
                    id="going-somewhere"
                    defaultMessage="-Going somewhere?"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="take-package"
                    defaultMessage="Take a package on your way!"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="unused-equipment"
                    defaultMessage="-Have equipment or services for rent? "
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="find-client"
                    defaultMessage="Post it and find clients for it!"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="post-green"
                    defaultMessage="Post your resources in Green
            as a "
                  /><br />
                  <span>{localStorage.getItem('language') === 'fr-ca' ? <FormattedMessage
                    id="postgreenbelow"
                  /> : 'as a '}</span>
                  <span
                    className="solution-btn-message msg1">
                    <FormattedMessage id="solution"
                      defaultMessage={localStorage.getItem('language') === 'fr-ca' ?
                        'Fournisseur de solutions' : "Solution Provider"} />
                  </span>
                </div>
                <div >
                  <FormattedMessage
                    id="post-blue"
                    defaultMessage="- Need something? Post your Needs in Blue
            As a"
                  /><br />
                  <span>{localStorage.getItem('language') === 'fr-ca' ? 'en bleu en tant que ' : 'as a'}</span>
                  <span
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                    className="need-btn-message"
                  >{localStorage.getItem('language') === 'fr-ca' ?
                    <FormattedMessage id="need-message" defaultMessage="Client" /> :
                    'Client'}
                  </span>
                </div>
                <div>
                  <FormattedMessage
                    id="shareWithContacts"
                    defaultMessage="Share it with all your contacts"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="getPaid"
                    defaultMessage="Get paid for your Solution!"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="findSolutionInfo"
                    defaultMessage="Find solutions to your Need!"
                  />
                </div>
              </div>
              <Link to="/accountcreation" onClick={this.handleCancel} >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="info-message-login">
                    <FormattedMessage
                      id="login-message"
                      defaultMessage="Sign Up"
                    />
                  </div>
                </div>
              </Link>

              <Link to="/login" onClick={this.handleCancel} >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "30%" }} className="info-message-login">
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </div>
                </div>
              </Link>
            </div>
            <br />
            <div
              className='st_style'
            >
              <button
                onClick={() => this.setState({ modalVisible2: true })}
                className="leave-btn st_style"
              >
                <span style={{ color: "#fff", fontWeight: 500 }}>
                  <FormattedMessage id="leaveText" defaultMessage="Leave" />
                </span>
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => this.handleLanguage("en-us")}
                style={{
                  cursor: "pointer",
                  color:
                    localStorage.getItem("language") === "fr-ca"
                      ? "none"
                      : "#A1947A",
                }}
              >
                English
              </div>
              |
              <div
                onClick={() => this.handleLanguage("fr-ca")}
                style={{
                  cursor: "pointer",
                  color: localStorage.getItem("language") !== "fr-ca"
                    ? "none" : "#A1947A",
                }}              >
                French
              </div>
            </div>
          </Modal>
        </div>
        {/* leave modal */}
        <Modal
          title={localStorage.getItem("language") === "fr-ca" ? "Quitter?" : "Leave?"}
          visible={this.state.modalVisible2}
          onOk={this.handleOk2}
          onCancel={this.handleCancel2}
          okText={localStorage.getItem("language") === "fr-ca" ? "Quitter" : "Leave"}
          cancelText={localStorage.getItem("language") === "fr-ca" ? "Gagner" : "Gain"}
          okButtonProps={{
            className: "ant-btn ant-btn-danger",
          }}
          cancelButtonProps={{
            className: "ant-btn ant-btn-primary",
          }}
          footer={[
            <Button key="leave" type="danger" onClick={this.handleOk2}>
              {localStorage.getItem("language") === "fr-ca"
                ? "Quitter"
                : "Leave"}
            </Button>,
            <Button key="gain" type="primary" onClick={this.handleCancel2}>
              {localStorage.getItem("language") === "fr-ca" ? "Gagner" : "Gain"}
            </Button>,
          ]}
        >
          <div className='start_txt'>
            {localStorage.getItem("language") === "fr-ca" ? (
              <div>
                <p className='start_txt'                >
                  Attendez!!
                </p>
                <p>
                  Saviez-vous que vous pouvez vraiment gagner de l'argent avec
                  nous?
                </p>
                <p>
                  Postez des offres sur notre carte et partagez-la avec
                  d'autres, et vous verrez ... Tout est à l'intérieur ...{" "}
                </p>
                <p>Inscrivez vous maintenant !!</p>
              </div>
            ) : (
              <div>
                <p className='start_txt'>
                  Wait!!{" "}
                </p>
                <p>Did you know that you can really earn money with us?</p>
                <p>
                  Post offers on our map and share it with others, and you will
                  see... All is inside...{" "}
                </p>
                <p>Register now!!</p>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    coords: state.auth.coords,
    allNeedSolutions: state.solutions.allNeedSolutions,
    allProvideSolutions: state.solutions.allProvideSolutions,
    LoginMessage: state.auth.loginMessage,
    flag: state.auth.flag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardContainer);
// The app is requesting access to sensitive info in your Google Account.
//  Until the developer (pickantapp@gmail.com) verifies this app with Google, you shouldn't use it.
