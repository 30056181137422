import React from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../../actions";

const columns = [
  {
    title: "Id",
    dataIndex: "_id"
  },
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => a.email - b.email,
      multiple: 3
    }
  },
  //   {
  //     title: "Address",
  //     dataIndex: "address",
  //     sorter: {
  //       compare: (a, b) => a.address - b.address,
  //       multiple: 2
  //     }
  //   },
  {
    title: "Mobile_no",
    dataIndex: "mobile_no",
    sorter: {
      compare: (a, b) => a.mobile_no - b.mobile_no,
      multiple: 1
    }
  }
];


function arrangeData(data) {
  const userData = [];
  data && data.map((user) => {
    return userData.push({
      _id: user._id, name: user.firstName,
      email: user.email, mobile_no: user.mobile_no,
      address: user.physical_address
    })
  })
  return userData;
}
class GetUsers extends React.Component {
  componentDidMount() {
    this.props.getAllUsers();
  }
  render() {
    return <Table columns={columns} dataSource={this.props.allUsers}
      />;
  }
}



function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    allUsers: arrangeData(state.auth.allUsers)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetUsers);
