import React from "react";
import { Form, Icon, Input, Button, Layout } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import profileImage from "../../Assests/dummy-profile.png";
import "./styles.css";

const { Content } = Layout;

const DashBoard = ({ toggle, collapsed, currentUser, onFilesChange }) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          headingTitle={
            localStorage.getItem("language") === "fr-ca" ? "Profil" : "Profile"
          }
        />
        <Content
          className="content-style"
          style={{
            padding: 10,
            background: "#fff",
            paddingTop: "70px",
            minHeight: "100vh"
          }}
        >
          <div onChange={onFilesChange} className="profile-container">
            <div class="image-upload">
              <label for="file-input">
                {currentUser.profile_pic ? (
                  <img
                    alt=""
                    className="profile-img-container "
                    src={currentUser.profile_pic}
                  ></img>
                ) : (
                  <img
                    alt=""
                    className="profile-img-container "
                    src={profileImage}
                  ></img>
                )}
              </label>

              <input name="selectedFile" accept="image/*" id="file-input" type="file" multiple />
            </div>
          </div>
          <div className="profile-form-container">
            <Form class="profile-form-wrapper">
              <Form.Item>
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  disabled={true}
                  value={`${currentUser && currentUser.firstName} ${currentUser && currentUser.lastName === null
                      ? ""
                      : currentUser && currentUser.lastName
                    }`}
                  placeholder="User name"
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  disabled={true}
                  value={currentUser && currentUser.email}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={
                    <Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  disabled={true}
                  value={currentUser && currentUser.mobile_no}
                  placeholder="+9232111111"
                />
              </Form.Item>
            </Form>
          </div>
          <div>
            <div className="profile-container">
              <Button
                type="primary"
                htmlType="submit"
                className="need-solution-form-button"
              // onClick={this.handleSubmit}
              >
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </div>

            {currentUser.subscription.subscription_type === "90 days trail" ? (
              <div className="profile-container">
                <div style={{ marginTop: "13px" }} className="user-balance">
                  {localStorage.getItem("language") === "fr-ca"
                    ? "Votre trace expire dans"
                    : "Your trail expires in"}
                  :{" "}
                  {moment(currentUser.subscription.subscription_end_date).diff(
                    moment(currentUser.subscription.subscription_start_date),
                    "days"
                  ) - 1}{" "}
                  days
                </div>
              </div>
            ) : (
              <>
                <div className="profile-container balance-container">
                  <div className="user-balance">
                    <FormattedMessage
                      id="userBalance"
                      defaultMessage="User Balance"
                    />
                  </div>
                </div>
                <div className="profile-container">
                  <div>$ 0.00</div>
                </div>
              </>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashBoard;
