import React from "react";
import { Form, Icon, Input, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../actions";
import { requestFirebaseNotificationPermission } from "../../../firebaseInit";
import SetLanguage from "../../Shared/setLanguge";
import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import "./styles.css";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowGeoRecall: false,
      email: "",
      loc_modal: false
    };
  }

  componentDidMount() {
    this.getLocation();
    this.requestFirebaseToken();
    setTimeout(() => {
      this.props.coords?.latitude === undefined && this.setState({ loc_modal: true })
    }, 500);
  }
 
  requestFirebaseToken() {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        if (firebaseToken !== null) {
          this.setState({
            device_token: firebaseToken,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation &&
        navigator.geolocation.getCurrentPosition(
          () => {
            this.setState({
              allowGeoRecall: false,
            });
          },
          () => {
            this.setState({
              allowGeoRecall: true,
            });
          }
        );
    } else {
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.props.authError) {
          this.props.setAuthErrors("");
        }
      }
    );
  };
  Auth = path => {
    if (!this.props.coords?.latitude) {
      this.setState({ loc_modal: true })
    }
    else {
      window.location = `${process.env.REACT_APP_DEVELOPMENT_URL}/${path}`
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.coords?.latitude) {
      return this.setState({ loc_modal: true })
    }
    if (this.state.allowGeoRecall) {
      alert(
        localStorage.getItem("language") === "fr-ca"
          ? "Pour utiliser cette application et voir Business-Opportunities sur la carte, vous devez autoriser l'application à accéder à votre position. Veuillez activer votre position depuis les paramètres de votre téléphone ou depuis le navigateur, puis actualisez la page."
          : "To use this application and see Business-Opportunities on the map, you must allow the app to access your location. Please activate your location from your phone's settings or from the browser, and then refresh the page."
      );
    } else {
      const params = {
        email: this.state.email.toLocaleLowerCase().trim(),
        password: this.state.password,
      };
      if (this.state.device_token !== null) {
        params.device_token = this.state.device_token;
      }
      this.props.login(params, this.props.history);
    }
  };

  render() {
    return (
      <div className="login-container">
        <div className="form-wrapper">
          <Modal visible={!this.props.coords?.latitude && this.state.loc_modal}
            className='loc_modal'
            style={{ textAlign: 'center' }}
            cancelText=''
            closable={false}
            centered
            cancelButtonProps={{ disabled: true, }}
            onOk={() => this.setState({ loc_modal: false })}
          >
            <div>
              <div className="info-message-heading" style={{ textAlign: 'center' }}>
                <p>{localStorage.getItem('language') === 'fr-ca' ?
                  "Assurez-vous que l'emplacement est activé, puis actualisez la page pour pouvoir voir les opportunités commerciales sur la carte." :
                  "Make sure that the location is on, and then refresh the page to be able to see Business-Opportunities on the map."}</p>
              </div>
            </div>
          </Modal>
          <div className="login-heading-container">
            <h1 className="login-heading">
              <FormattedMessage id="loginTitle" defaultMessage="Login" />
            </h1>
          </div>
          <div className=''
            style={{ textAlign: "center", color: "#A1947A", fontSize: "11px" }}
          >
            <FormattedMessage
              id="infoLoginMessage"
              defaultMessage="Please enter phone number with your country code like on international calls"
            />
          </div>
          <div style={{ textAlign: "center", color: "red" }}>
            {this.props.authError &&
              localStorage.getItem("language") === "fr-ca"
              ? "Les informations invalids"
              : this.props.authError}
          </div>
          <div style={{ textAlign: "center", color: "red" }}>
            {queryString.parse(this.props.location.search).error ===
              "expirationError"
              ? localStorage.getItem("language") === "fr-ca"
                ? "La session d'authentification a expiré car vous vous êtes connecté avec un autre appareil Veuillez vous reconnecter"
                : "Authentication session expired beacuse you logged in from other device kindly log in again"
              : null}
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              <FormattedMessage
                id="mobileAndEmail"
                defaultMessage="Email/phone number (e.g.+92123452)"
              >
                {(placeholder) => (
                  <Input
                    className="inputEmail"
                    prefix={
                      <Icon
                        type="mobile"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder={placeholder}
                    name="email"
                    onChange={this.handleChange}
                  />
                )}
              </FormattedMessage>
            </Form.Item>
            <Form.Item>
              <FormattedMessage id="password" defaultMessage="Password">
                {(placeholder) => (
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder={placeholder}
                    name="password"
                    type="password"
                    onChange={this.handleChange}
                  />
                )}
              </FormattedMessage>
            </Form.Item>
            <Form.Item>
              <div className="forget-password-container">
                <Link to="forgetpassword" className="forget-password-link">
                  <FormattedMessage
                    id="forgetPassword"
                    defaultMessage="Forgot Password?"
                  />
                </Link>
              </div>
              <div className="login-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage id="login" defaultMessage="Login" />
                </Button>
              </div>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <FormattedMessage id="or" defaultMessage="Or" />
              </div>
              <div className='st_style'
              >
                <div>
                  {localStorage.getItem("language") === "fr-ca" ? (
                    <a
                      className="my-google-button-class"
                      href="javascript:void(0)"
                      onClick={() => this.Auth('auth/google')}
                    >
                      Connectez-vous avec Google{"    "}
                      <GoogleOutlined
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          // padding: "5px",
                        }}
                      />
                    </a>
                  ) : (
                    <a
                      className="my-google-button-class"
                      href="javascript:void(0)"
                      onClick={() => this.Auth('auth/google')}
                    >
                      Sign In with Google{"    "}
                      <GoogleOutlined
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          // padding: "5px",
                        }}
                      />
                    </a>
                  )}
                </div>
                {/* <br /> */}
                {/* <br /> */}

                <FormattedMessage id="or2" defaultMessage="-" />
                {/* <br /> */}
                {/* <br /> */}
                {/* <br /> */}
                <div>
                  {localStorage.getItem("language") === "fr-ca" ? (
                    <a
                      className="my-facebook-button-class"
                      href="javascript:void(0)"
                      onClick={() => this.Auth('auth/facebook')}
                    >
                      Connectez-vous avec Facebook{"    "}
                      <FacebookOutlined
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                        }}
                      />
                    </a>
                  ) : (
                    <a
                      className="my-facebook-button-class"
                      // target="_blank"
                      href="javascript:void(0)"
                      onClick={() => this.Auth('auth/facebook')}
                    >
                      Sign In with Facebook{"    "}
                      <FacebookOutlined
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          // padding: "5px",
                        }}
                      />
                    </a>

                  )}
                </div>
              </div>
              <div className="create-account-container"
              >
                <Link to="/accountcreation"
                  className="create-account-link borderbtn">
                  <FormattedMessage
                    id="createAnAccount"
                    defaultMessage="Create An Account"
                  />
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
        <SetLanguage />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    coords: state.auth.coords
  };
}

const WrappedLoginForm = Form.create({ name: "login-form" })(LoginForm);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedLoginForm);
