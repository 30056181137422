import React from "react";

import "./styles.css";

const ListItem = ({ data, handleModal }) => {
  return (
    <div
      onClick={() => handleModal("data", data)}
      className="list-item-container"
    >
      <div className="img-container">
        <img alt="" className="list-img" src={data.img} />
      </div>
      <div className="item-desp">
        {localStorage.getItem("language") === "fr-ca"
          ? data.desp.fr
          : data.desp.en}
      </div>
      <div className="item-price">
        <div className="item-price-wrapper">
          ={" "}
          {localStorage.getItem("currency") === "dollar"
            ? `${data.dollar_price} $`
            : localStorage.getItem("currency") === "euro"
            ? `${data.euro_price} €`
            : `${data.fr_price} CFA`}
        </div>
      </div>
    </div>
  );
};
export default ListItem;
