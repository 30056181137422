import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { useSelector } from 'react-redux'
import GoogleMaps from "../googleMaps";
import { Layout, Icon, Modal } from "antd";
import { Link } from "react-router-dom";

import MobileNavItem from "../CommonComponents/MobileNavItem";

const { Content } = Layout;

const DashBoard = ({ toggle, collapsed, allNeedSolutions,
  allProvideSolutions, navigate, setRoute, }) => {
  const state = useSelector(state => state.auth);

  const [loc_modal, set_loca_modal] = useState(false);
  useEffect(() => {
    if (!state.coords?.latitude) {
      setTimeout(() => {
        set_loca_modal(true)
      }, 500);
    }
  }, [state.coords])
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation toggle={toggle} collapsed={collapsed} />
        <Modal visible={!state.coords?.latitude && loc_modal}
          className='loc_modal'
          style={{ textAlign: 'center' }}
          cancelText=''
          closable={false}
          centered
          cancelButtonProps={{ disabled: true, }}
          onOk={() => set_loca_modal(false)}
        >
          <div>
            <div className="info-message-heading" style={{ textAlign: 'center' }}>
              <p>{localStorage.getItem('language') === 'fr-ca' ?
                "Assurez-vous que l'emplacement est activé, puis actualisez la page pour pouvoir voir les opportunités commerciales sur la carte." :
                "Make sure that the location is on, and then refresh the page to be able to see Business-Opportunities on the map."}</p>
            </div>
          </div>
        </Modal>
        <Content
          className="content-style"
          style={{
            padding: 10,
            background: "#fff",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <MobileNavItem setRoute={setRoute} />
          <div style={{ position: "relative" }}>
            <div onClick={navigate} className="plus-add-btns-container">
              <div className="plus-add-btns">
                <Icon type="plus" style={{ fontSize: "40px" }} />
              </div>
            </div>
          </div>
          <span className="mobile-nav-tab">
            <div className="mobiile-nav-tab-item">
              <Link
                to={{
                  pathname: "/",
                }}
              >
                <a className="mobiile-nav-tab-item-list">
                  <Icon type="home" />
                  <span>
                    <FormattedMessage id="home" defaultMessage="Home" />
                  </span>
                </a>
              </Link>
              <Link
                to={{
                  pathname: "/profile",
                }}
              >
                <a className="mobiile-nav-tab-item-list">
                  <Icon type="user" />
                  <FormattedMessage id="profile" defaultMessage="Profile" />
                </a>
              </Link>
            </div>
          </span>
          <GoogleMaps
            allProvideSolutions={allProvideSolutions}
            allNeedSolutions={allNeedSolutions}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashBoard;
