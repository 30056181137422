import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Api from "../../services/api";

const MiddleWare = (props) => {
  let loading = true;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    if (query.get("user")) {
      if (query.get("isCountry") == "true") {
        localStorage.setItem("isCountry", true);
      } else {
        localStorage.setItem("isCountry", false);
      }

      const user = JSON.parse(query.get("user"));

      if (
        moment(user.subscription.subscription_end_date).diff(
          moment(user.subscription.subscription_start_date),
          "days"
        ) -
        1 ===
        0
      ) {
        setFreeTrail(
          {
            user_id: user._id,
            auth_key: user.auth_key,
            status: false,
            trail_complete: true,
          },
          props.history,
          null,
          null
        );
      }

      localStorage.setItem("currentUser", query.get("user"));
      localStorage.setItem("isAuthenticated", true);
      window.location.replace("/middleware");
      setTimeout(() => {
        window.location.replace("/dashboard");
      }, 1000);
    }
  });

  function setFreeTrail(
    params,
    NotificationManager,
    displayFlag = true
  ) {
    return (dispatch) =>
      Api.post(`user/updateSubscription`, params)
        .then((resp) => {
          localStorage.setItem("currentUser", JSON.stringify(resp.data));
          if (displayFlag === true) {
            NotificationManager.success(
              localStorage.getItem("language") === "fr-ca"
                ? "Vous avez souscrit avec succès la version d'essai, veuillez sélectionner à nouveau la solution"
                : "You have successfully subscribed trial version,Please select solution again"
            );
          }
          setTimeout(() => {
            props.history.push("/middleware");
          }, 2000);
        })
        .catch((err) => {
          console.log('er');
        });
  }

  return <>{loading ? <></> : <></>}</>;
};

export default MiddleWare;
