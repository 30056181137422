import React from "react";
import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import MobileNavItem from '../CommonComponents/MobileNavItem'
import GoogleMaps from "../googleMaps";
import { Layout } from "antd";
const { Content } = Layout;

const StartAppDashBoard = ({
  toggle,
  collapsed,
  allNeedSolutions,
  allProvideSolutions,
  handleOk
}) => {
  return (
    <Layout>
      <SideNavigation handleOk={handleOk} collapsed={collapsed} />
      <Layout>
        <TopNavigation handleOk={handleOk} toggle={toggle}
          collapsed={collapsed} />
        <Content
          className="content-style"
          style={{
            padding: 10,
            background: "#fff",
            minHeight: "100vh",
          }}
        >
          <MobileNavItem setRoute={handleOk} />
          <GoogleMaps
            handleOk={handleOk}
            allProvideSolutions={allProvideSolutions}
            allNeedSolutions={allNeedSolutions}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default StartAppDashBoard;
