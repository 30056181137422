import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../actions";
import Clients from "../Components/Clients";

class ClientsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visible:false,
      valueRadio:'pickup_country'
    };
  }

  componentDidMount() {
    this.props.getAllSolution(
      "needSolution",
      this.props.history
    );
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleDetails = (id,userid) => {
    this.props.history.push(`/clientsdetails/${id}/${userid}`);
  };

  handleModal=()=>{
    this.setState({
      visible:!this.state.visible
    })
  }

  onChangeSearchRadio= e => {
    this.setState({
      valueRadio: e.target.value,
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    },()=>{
      // console.log(this.state.searchInput)
    });
  };

  handleSubmit=async()=>{
    await this.props.searching(this.state.searchInput,"needSolution",this.state.valueRadio,this.props.currentUser.auth_key,this.props.currentUser._id);
    this.setState({
      visible:false
    })
  }

  render() {
    return (
      <Clients
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        onChangeSearchRadio={this.onChangeSearchRadio}
        valueRadio={this.state.valueRadio}
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        handleModal={this.handleModal}
        visible={this.state.visible}
        handleDetails={this.handleDetails}
        allNeedSolutions={this.props.allNeedSolutions}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    allNeedSolutions: state.solutions.allNeedSolutions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);
