import React, { Component } from "react";

import englandFlag from '../../../Assests/englandFlag.png';
import frenchFlag from '../../../Assests/frenchFlag.jpg';
import "./styles.css";

class SetLanguage extends Component {
  handleChangeLanguage = async lng => {
    localStorage.setItem("language", lng);
    window.location.reload();
  };
  render() {
    const currentLanguage = localStorage.getItem("language");
    return (
      <div className={this.props.value === 'Id' ? "language-container-id" : "language-container"}>


        <span

          onClick={() => this.handleChangeLanguage("en-us")}
          className={
            currentLanguage === "en-us" ? "selected-language" : "language"
          }
        >
          <img src={englandFlag} alt='' className="lang-country-flag" />
          English
        </span>{" "}
        <div className="lang-line-divider">
          |{" "}
        </div>

        <span
          onClick={() => this.handleChangeLanguage("fr-ca")}
          className={
            currentLanguage === "fr-ca" ? "selected-language" : "language"
          }
        >
          <img src={frenchFlag} alt='' className="lang-country-flag" />
          French
        </span>
      </div>
    );
  }
}

export default SetLanguage;
