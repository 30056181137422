import React from "react";
import { Layout } from "antd";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import SideNavigation from "../Shared/SideNavigation";
import TopNavigation from "../Shared/TopNavigation";
import { ListItem } from "../CommonComponents";
import { SolutionModal } from "../CommonComponents/Modals";
import { youPropose } from "../../mockData";
const { Content } = Layout;

const Delivery = ({
  toggle,
  collapsed,
  delivery,
  visible,
  handleModal,
  handleClick,
  category
}) => {
  return (
    <Layout>
      <SideNavigation collapsed={collapsed} />
      <Layout>
        <TopNavigation
          toggle={toggle}
          collapsed={collapsed}
          check="delivery"
          headingTitle={
            localStorage.getItem("language") === "fr-ca"
              ? "Livraison"
              : "Delivery"
          }
        />
        <Content className="delivery-container content-style">
          <div className="list-container">
            <div onClick={() => handleModal("data", youPropose)} className="you-propose-container">
              <div className="you-propose">
                <div className="img-container">
                  <img alt="" className="list-img" src={youPropose.img} />
                </div>
                <div className="item-desp">
                  {localStorage.getItem("language") === "fr-ca"
                    ? youPropose.desp.fr
                    : youPropose.desp.en}
                </div>
                <div className="item-price">
                  <div className="item-price-wrapper">
                    = ???
                    {/* {localStorage.getItem("currency") === "dollar"
            ? `${data.dollar_price} $`
            : localStorage.getItem("currency") === "euro"
            ? `${data.euro_price} €`
            : `${data.fr_price} CFA`} */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginBottom:'13px',display:'flex',flexDirection:'column',textAlign:'center'}}>
              <div style={{fontWeight:'bold'}}>
              <FormattedMessage id="or" defaultMessage="OR" />
              </div>
              <div>
              <FormattedMessage id="choose" defaultMessage="Choose from the followings pre-set options below" />
              </div>
            </div>
            {delivery.map(item => (
              <ListItem handleModal={handleModal} key={item.id} data={item} />
            ))}
            <SolutionModal
              visible={visible}
              handleModal={handleModal}
              handleClick={handleClick}
              category="Delivery"
              categoryData={category && category}
              subCategoryId={category && category.id}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Delivery;
