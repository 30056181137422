import React from "react";
import { Modal, Input, Radio } from "antd";
import { FormattedMessage } from "react-intl";

import "./styles.css";

const SearchModal = ({
  visible,
  handleModal,
  onChangeSearchRadio,
  valueRadio,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div>
      <Modal
        visible={visible}
        onOk={handleModal}
        onCancel={handleModal}
        closable={false}
        footer={null}
        className="search-modal"
      >
        <div style={{ padding: "25px" }}>
          <h3>
            <FormattedMessage id="search" defaultMessage="Search" />
          </h3>
          <Input onChange={handleChange} name="searchInput" />
          <div onClick={handleSubmit} style={{ display: "flex", justifyContent: "center" }}>
            <div className="search-button">
              <FormattedMessage id="search" defaultMessage="Search" />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Radio.Group
              className="radio-group-style"
              onChange={onChangeSearchRadio}
              value={valueRadio}
            >
               <Radio value="pickup_country">
                <FormattedMessage id="byCountry" defaultMessage="By Country" />
              </Radio>
              <Radio value="category">
                <FormattedMessage
                  id="byCategory"
                  defaultMessage="By Category"
                />
              </Radio>
              <Radio value="sub_category">
                <FormattedMessage
                  id="bySubCategory"
                  defaultMessage="By Sub-category"
                />
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchModal;
