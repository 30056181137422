import React, { useState, useEffect } from "react";
// import { useDispatch } from 'react-redux'
// import { LOC_ENABLED_MODAL } from "../../../utils/constants";


const getUserLocationWrapper = ChildComponent => {
  const GetUserLocation = props => {
    const [userLocation, setuserLocation] = useState();
    // const dis = useDispatch();

    useEffect(() => {
      navigator.permissions &&
        navigator.permissions
          .query({ name: "geolocation" })
          .then(PermissionStatus => {
            if (PermissionStatus.state === "granted") {
              //allowed
            } else {
              //denied
            }
          });
      navigator.geolocation.getCurrentPosition(
        position => {
          clearTimeout(setTimeout(10000));
          const { latitude, longitude } = position.coords;
          setuserLocation({ lat: latitude, lng: longitude });
          // dis({ type: LOC_ENABLED_MODAL, payload: position.coords })
        },
        () => { },
        { timeout: 60000 }
      );
    }, [setuserLocation]);

    return <ChildComponent {...props} userLocation={userLocation} />;
  };
  return GetUserLocation;
};

export default getUserLocationWrapper;
