import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "react-notifications/lib/notifications.css";
import configureStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./App";
import "antd/dist/antd.css";

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,

  document.getElementById("root")
);
// if (process.env.REACT_APP_NODE_ENV === "development") {
//   serviceWorker.unregister();
// } else {
  serviceWorker.register();
// }
