import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { getLocation } from "../HOC";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../src/actions";

import { connect } from 'react-redux'
import { Marker, Marker3, Marker4 } from "../customMarker";
import { Checkbox } from "antd";
import '../../App.css'

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 15,
      userLocation: {},
      show: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tooltip !== prevProps.tooltip) {
      setTimeout(() => {
        this.setState({ show: false })
      }, 1000);
    }
  }
  render() {
    console.log('toltip=>', this.props.tooltip)
    return (
      <div
        style={{
          height: this.props.height ? this.props.height : "100vh",
          width: "100%",
        }} >
        {localStorage.getItem('tooltip') === null && window.location.pathname.includes('dashboard') && <div className='popup' style={{
          display: (this.state.show === false || this.props.tooltip === true) && 'none'
        }} >
          <CloseCircleFilled onClick={() => this.setState({ show: false })}
            className='close_pop'
          />
          <div className='iblue' style={{ zIndex: 1000 }} ></div>
          {localStorage.getItem("language") === "fr-ca" ?
            <p style={{ marginRight: '20px' }}>Pour offrir une solution à des millions de personnes autour de vous ou en chercher une, cliquez sur un service dans la barre supérieure, puis cliquez sur <PlusCircleFilled style={{ color: '#A1947A' }} /> ci-dessous</p> :
            <p style={{ marginRight: '20px' }}> To start gaining money or find a solution, publish to millions around you! Click on a service on the top bar, then click <PlusCircleFilled style={{ color: '#A1947A' }} /> below</p>
          }
          <Checkbox checked={this.props.tooltip}
            onChange={() => {
              this.props.tooltipoff(true);
              localStorage.setItem("tooltip", false);
            }} />
          <span style={{ marginLeft: '5px', fontSize: '12px' }}>{localStorage.getItem("language") === "fr-ca" ?
            'Ne plus afficher ce message.' :
            "Don't show this message  again."}</span>
        </div>}
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCuqrwSC0RU7K29q45HOELe3wEG0oIthBo" }}
          center={this.props.userLocation && this.props.userLocation}
          zoom={localStorage.getItem("currentUser") ? this.state.zoom : 2}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{
            lat: this.props.userLocation?.lat,
            lng: this.props.userLocation?.lng
          }}>
          <Marker
            lat={this.props.userLocation && this.props.userLocation.lat}
            lng={this.props.userLocation && this.props.userLocation.lng}
          />

          {this.props.allNeedSolutions &&
            this.props.allNeedSolutions.map((solution, i) =>
              localStorage.getItem("currentUser") ? (
                <Marker3
                  solution={solution}
                  color="#3B5999"
                  key={solution._id}
                  lat={
                    solution.pickup_location &&
                    solution.pickup_location.lat.$numberDecimal
                  }
                  lng={
                    solution.pickup_location &&
                    solution.pickup_location.lng.$numberDecimal
                  }
                />
              ) : (
                <Marker4
                  solution={solution}
                  color="#3B5999"
                  handleOk={this.props.handleOk}
                  key={solution._id}
                  lat={
                    solution.pickup_location &&
                    solution.pickup_location.lat.$numberDecimal
                  }
                  lng={
                    solution.pickup_location &&
                    solution.pickup_location.lng.$numberDecimal
                  }
                />
              )
            )}

          {this.props.allProvideSolutions &&
            this.props.allProvideSolutions.map((solution) =>
              localStorage.getItem("currentUser") ? (
                <Marker3
                  solution={solution}
                  color="green"
                  key={solution._id}
                  lat={
                    solution.pickup_location &&
                    solution.pickup_location.lat.$numberDecimal
                  }
                  lng={
                    solution.pickup_location &&
                    solution.pickup_location.lng.$numberDecimal
                  }
                />
              ) : (
                <Marker4
                  solution={solution}
                  color="green"
                  handleOk={this.props.handleOk}
                  key={solution._id}
                  lat={
                    solution.pickup_location &&
                    solution.pickup_location.lat.$numberDecimal
                  }
                  lng={
                    solution.pickup_location &&
                    solution.pickup_location.lng.$numberDecimal
                  }
                />
              )
            )}
        </GoogleMapReact>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    tooltip: state.auth.tooltip,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(getLocation(GoogleMap));
