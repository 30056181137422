import React from "react";
import { Form, Icon, Input, Button, DatePicker, Select } from "antd";
import { FormattedMessage } from "react-intl";

import camera from "../../../Assests/camera.svg";
import cameraCheck from "../../../Assests/checkMark.png";
import "./styles.css";
import { currencies } from "../../../mockData";
// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const NeedSolutionForm = ({
  handleChangeDate,
  handleChange,
  handleSubmit,
  onFilesChange,
  loading,
  category,
  categoryData,
  handleSelect,
  customPriceSymbol,
  selectedFile,
  error,
}) => {
  return (
    <div>
      {category === "Equipments" ||
        category === "Space" ||
        category === "Services" ||
        category === "Vegetables" ||
        category === "Cereals" ||
        category === "Fruits" ||
        category === "Live Stock" || category === "Input" || category === "Animal Feed" || category === "Herbs" ? (
        <div
          style={{ position: "relative" }}
          className="need-solution-container"
        >
          <div
            style={{ justifyContent: "center", display: "flex" }}
            className="need-form-wrapper"
          >
            {loading && loading ? (
              <div className="loading">
                <FormattedMessage id="loading..." defaultMessage="LOADING..." />
              </div>
            ) : null}
            <div className="need-form-container">
              <Form className="need-solution-form">
                {error ? (
                  <p style={{ color: "red" }}>
                    <FormattedMessage
                      id="fillFields"
                      defaultMessage="Please fill the fields"
                    />
                  </p>
                ) : null}

                <Form.Item>
                  <div class="image-uploads">
                    {selectedFile ? (
                      <img className="camera-check-image" alt='' src={cameraCheck} />
                    ) : null}
                    <label for="file-input">
                      <img className="camera-images" alt='' src={camera} />
                    </label>
                    <input
                      style={{ display: "none" }}
                      name="selectedFile"
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={onFilesChange}
                      multiple
                    />
                  </div>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage
                    id="address"
                    defaultMessage="Street Address"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder={placeholder}
                        name="pickUpAddress"
                        onChange={handleChange}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="city" defaultMessage="City">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpCity"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage
                    id="postalCode"
                    defaultMessage="Postal code"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpPostal"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="region" defaultMessage="Region">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpRegion"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="country" defaultMessage="Country">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpCountry"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    onChange={(date) => handleChangeDate(date, "pickUpDate")}
                    size="middle"
                    placeholder="Date"
                    className="date-picker-need"
                  />
                </Form.Item>

                <div style={{ position: "relative" }}>
                  <Form.Item>
                    <FormattedMessage
                      id="description"
                      defaultMessage="description"
                    >
                      {(placeholder) => (
                        <TextArea
                          name="description"
                          onChange={handleChange}
                          rows={4}
                          placeholder={placeholder}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Item>

                </div>
                {categoryData &&
                  categoryData.desp.en === "Your own proposition..." ? (
                  <Form.Item>
                    <div className="custom-price-container">
                      <div className="custom-price-wrapper">
                        <div style={{ color: "#A1947A" }}>
                          <FormattedMessage id="price" defaultMessage="Price" />
                        </div>
                        <div>
                          <Select
                            defaultValue={customPriceSymbol}
                            style={{ width: 100 }}
                            onChange={handleSelect}
                          >
                            {currencies.map((currency, i) => (
                              <Option value={currency.currencySymbol} key={i}>
                                {`${currency.currencyCode} ${currency.currencySymbol}`}
                              </Option>
                            ))}
                          </Select>
                          <Input
                            type="number"
                            onChange={handleChange}
                            name="customPrice"
                            style={{ marginLeft: "5px" }}
                            className="custom-price-input"
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <div className="need-solution-button-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="need-solution-form-button"
                      onClick={handleSubmit}
                    >
                      <FormattedMessage id="publish" defaultMessage="Publish" />
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ position: "relative" }}
          className="need-solution-container"
        >
          <div
            style={{ justifyContent: "center", display: "flex" }}
            className="need-form-wrapper"
          >
            {loading && loading ? (
              <div className="loading">
                <FormattedMessage id="loading..." defaultMessage="LOADING..." />
              </div>
            ) : null}
            <div className="need-form-container">
              <div style={{ fontWeight: "bold", color: "#FFFF" }}>
                <FormattedMessage
                  id="pickUpAddress"
                  defaultMessage="Pickup Address"
                />
              </div>
              <Form className="need-solution-form">
                {error ? (
                  <p style={{ color: "red" }}>
                    <FormattedMessage
                      id="fillFields"
                      defaultMessage="Please fill the fields"
                    />
                  </p>
                ) : null}
                <Form.Item>
                  <div class="image-uploads">
                    {selectedFile ? (
                      <img className="camera-check-image" alt='' src={cameraCheck} />
                    ) : null}
                    <label for="file-input">
                      <img className="camera-images" alt='' src={camera} />
                    </label>
                    <input
                      style={{ display: "none" }}
                      name="selectedFile"
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={onFilesChange}
                      multiple
                    />
                  </div>
                </Form.Item>
                {localStorage.getItem("language") === "fr-ca" && (
                  <FormattedMessage
                    id="from"
                    defaultMessage="De"
                  />
                )}
                {localStorage.getItem("language") === "en-us" && (
                  <FormattedMessage
                    id="from"
                    defaultMessage="From"
                  />
                )}
                <Form.Item>
                  <FormattedMessage
                    id="address"
                    defaultMessage="Street Address"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder={placeholder}
                        name="pickUpAddress"
                        onChange={handleChange}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="city" defaultMessage="City">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpCity"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage
                    id="postalCode"
                    defaultMessage="Postal code"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpPostal"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="region" defaultMessage="Region">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpRegion"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="country" defaultMessage="Country">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="pickUpCountry"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    onChange={(date) => handleChangeDate(date, "pickUpDate")}
                    size="middle"
                    placeholder="Date"
                    className="date-picker-need"
                  />
                </Form.Item>
                {localStorage.getItem("language") === "fr-ca" && (
                  <FormattedMessage
                    id="to"
                    defaultMessage="À"
                  />
                )}
                {localStorage.getItem("language") === "en-us" && (
                  <FormattedMessage
                    id="to"
                    defaultMessage="To"
                  />
                )}
                <Form.Item>
                  <FormattedMessage
                    id="address"
                    defaultMessage="Street Address"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="deliveryAddress"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="city" defaultMessage="City">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="deliveryCity"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage
                    id="postalCode"
                    defaultMessage="Postal code"
                  >
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="deliveryPostal"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="region" defaultMessage="Region">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="deliveryRegion"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <FormattedMessage id="country" defaultMessage="Country">
                    {(placeholder) => (
                      <Input
                        prefix={
                          <Icon
                            type="home"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="deliveryCountry"
                        onChange={handleChange}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    onChange={(date) => handleChangeDate(date, "deliveryDate")}
                    size="middle"
                    placeholder="Date"
                    className="date-picker-need date-pic"
                  // disabledDate={disabledDates}
                  />
                </Form.Item>

                <div style={{ position: "relative" }}>
                  <Form.Item>
                    <FormattedMessage
                      id="description"
                      defaultMessage="description"
                    >
                      {(placeholder) => (
                        <TextArea
                          name="description"
                          onChange={handleChange}
                          rows={4}
                          placeholder={placeholder}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Item>

                </div>
                {categoryData &&
                  categoryData.desp.en === "Your own proposition..." ? (
                  <Form.Item>
                    <div className="custom-price-container">
                      <div className="custom-price-wrapper">
                        <div style={{ color: "#A1947A" }}>
                          <FormattedMessage id="price" defaultMessage="Price" />
                        </div>
                        <div>
                          <Select
                            defaultValue={customPriceSymbol}
                            style={{ width: 100 }}
                            onChange={handleSelect}
                          >
                            {currencies.map((currency) => (
                              <Option value={currency.currencySymbol}>
                                {`${currency.currencyCode} ${currency.currencySymbol}`}
                              </Option>
                            ))}
                          </Select>
                          <Input
                            type="number"
                            onChange={handleChange}
                            name="customPrice"
                            style={{ marginLeft: "5px" }}
                            className="custom-price-input"
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <div className="need-solution-button-container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="need-solution-form-button"
                      onClick={handleSubmit}
                    >
                      <FormattedMessage id="publish" defaultMessage="Publish" />
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NeedSolutionForm;
