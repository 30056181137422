import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../../../actions";
import SetLanguage from "../../Shared/setLanguge";

import "./styles.css";

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.password === this.state.confirmPassword) {
      const params = {
        mobile_no: this.props.location.state.mobileNumber,
        password: this.state.password
      };
      this.props.resetPassword(params, this.props.history);
    } else {
      this.setState({
        error: "Password doesnot match"
      });
    }
  };

  render() {
    return (
      <div className="reset-password-container">
        <div className="form-wrapper">
          <div className="reset-password-heading-container">
            <h1 className="reset-password-heading">
              <FormattedMessage
                id="resetPasswordTitle"
                defaultMessage="Reset Password"
              />
            </h1>
          </div>
          <Form onSubmit={this.handleSubmit} className="reset-password-form">
            <div style={{ color: "red", textAlign: "center" }}>
              {this.state.error}
            </div>
            <Form.Item>
              <FormattedMessage id="password" defaultMessage="Password">
                {placeholder => (
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Form.Item>
            <Form.Item>
              <FormattedMessage
                id="confirmPassword"
                defaultMessage="Confirm Password"
              >
                {placeholder => (
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Form.Item>
            <Form.Item>
              <div className="reset-password-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="reset-password-form-button"
                >
                  <FormattedMessage
                    id="resetPassword"
                    defaultMessage="Reset Password"
                  />
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <SetLanguage />
      </div>
    );
  }
}

const WrappedResetPasswordForm = Form.create({ name: "reset-password-form" })(
  ResetPasswordForm
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    codeVerificationStatus: state.auth.codeVerificationStatus
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedResetPasswordForm);
