import * as types from "../utils/constants";
import Api from "../services/api";
import axios from "axios";
// const api = process.env.REACT_APP_PRODUCTION_URL;

function setNeedSolutions(params) {
  return {
    type: types.SET_NEED_SOLUTIONS,
    allNeedSolutions: params,
  };
}

function setProvideSolutions(params) {
  return {
    type: types.SET_PROVIDE_SOLUTIONS,
    allProvideSolutions: params,
  };
}

function setSolution(params) {
  return {
    type: types.SET_SOLUTION,
    solution: params,
  };
}

function setUserSolutions(params) {
  return {
    type: types.SET_USER_SOLUTIONS,
    userSolutions: params,
  };
}

function setUserAcceptedSolutions(params) {
  return {
    type: types.SET_USER_ACCEPTED_SOLUTIONS,
    userAcceptedSolutions: params,
  };
}

function setSolutionAccepted(params) {
  return {
    type: types.SOLUTION_ACCEPTED,
    solutionAccepted: params,
  };
}

function setIsLoading(loading) {
  return {
    type: types.IS_LOADING,
    loading,
  };
}

export function solution(params, NotificationManager) {
  return (dispatch) => {
    dispatch(setIsLoading(true));
    axios
      .post(
        `${process.env.REACT_APP_PRODUCTION_URL}/solution/addSolution`,
        params
      )
      .then((resp) => {
        dispatch(setIsLoading(false));
        dispatch(setSolution(resp.data.data));
        NotificationManager.success(localStorage.getItem("language") === "fr-ca" ? "Publié avec succès" : "Successfully Published");
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        } else {
          NotificationManager.error(
            localStorage.getItem("language") === "fr-ca"
              ? "Veuillez remplir tous les champs de saisie"
              : "Please fill all input fields"
          );
        }
      });
  };
}

export function getAllSolution(solutionType, userID, language, his) {
  return (dispatch) =>
    Api.get(`solution/getAllSolutions/${solutionType}`)
      .then((resp) => {
        if (solutionType === "needSolution") {
          dispatch(setNeedSolutions(resp.data));
        } else if (solutionType === "provideSolution") {
          dispatch(setProvideSolutions(resp.data));
        }
        // axios.get(`${process.env.REACT_APP_DEVELOPMENT_URL}/user/addNotiLanguage?_id=${userID}&noti_lan=${language} `)
        //   .then((resp) => {
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((err) => {
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function getSolution(userId, authKey, solutionId, role) {
  return (dispatch) =>
    Api.get(`solution/getSolutionDetails/${userId}/${solutionId}/${role}`)
      .then((resp) => {
        dispatch(setSolution(resp.data));
      })
      .catch((err) => {
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function getUserSolutions(userId, authKey) {
  return (dispatch) =>
    Api.get(`solution/getUserSolutions/${userId}/${authKey}`)
      .then((resp) => {
        dispatch(setUserSolutions(resp.data));
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function getUserAcceptedSolutions(userId, authKey) {
  return (dispatch) =>
    Api.get(`solution/getUserAcceptedSolutions/${userId}/${authKey}`)
      .then((resp) => {
        dispatch(setUserAcceptedSolutions(resp.data));
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function updateSubscription(params, history) {
  return (dispatch) =>
    Api.post(`payment/updateSubscription`, params)
      .then((resp) => {
        localStorage.setItem("currentUser", JSON.stringify(resp.data));
        history.push("/dashboard");
      })
      .catch((err) => {
        // console.log(err);
      });
}

export function solutionAccepted(params, history, solutionData, type) {
  return (dispatch) =>
    Api.put(`solution/solutionAccepted`, params)
      .then((resp) => {
        dispatch(setSolutionAccepted(resp.data));
        if (type === "supplierSolutionMatch") {
          history.push({
            pathname: `/suppliers`,
          });
        } else {
          history.push({
            pathname: `/clientofferaccept/${resp.data._id}`,
            solutionData: solutionData,
          });
        }
      })
      .catch((err) => {
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function successPaymentMessage(params) {
  return (dispatch) =>
    Api.post(`payment/success`, params)
      .then((resp) => {
        dispatch(setSolutionAccepted(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });
}

export function createsOneTimePayment(params, history, solutionData, type) {
  return (dispatch) =>
    Api.post(`payment/pay`, params)
      .then((resp) => {
        // console.log(resp.url);
        window.location.href = `${resp.url}`;
        // dispatch(setSolutionAccepted(resp.data));
        if (type === "supplierSolutionMatch") {
          history.push({
            pathname: `/suppliers`,
          });
        } else {
          // history.push({
          //   pathname: `/clientofferaccept/${resp.data._id}`,
          //   solutionData: solutionData
          // });
          localStorage.setItem("solutionData", solutionData);
        }
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function searching(
  searchString,
  solutionType,
  searchBy,
  authKey,
  userId
) {
  return (dispatch) =>
    Api.get(
      `solution/searchSolution/${userId}/${authKey}/${solutionType}/${searchBy}/${searchString}`
    )
      .then((resp) => {
        if (solutionType === "needSolution") {
          dispatch(setNeedSolutions(resp.data));
        } else if (solutionType === "provideSolution") {
          dispatch(setProvideSolutions(resp.data));
        }
      })
      .catch((err) => {
        if (
          err.message === "Authentication failed" ||
          err.message === "User authentication failed"
        ) {
          window.location.href = "/login?error=expirationError";
        }
      });
}

export function deleteSolution(params) {
  return (dispatch) =>
    Api.delete(
      `solution/deleteSolution/${params.user_id}/${params.solution_id}`
    )
      .then((resp) => {
        dispatch(getUserSolutions(params.user_id, params.auth_key));
      })
      .catch((err) => {
        console.log(err);
      });
}

export function visitedSolution(params) {
  return (dispatch) =>
    Api.post(`solution/solutionClicked`, params)
      .then((resp) => {
      })
      .catch((err) => {
        console.log(err);
      });
}

export function rejectOrder(params) {
  return (dispatch) =>
    Api.put(`solution/rejectSolution`, params)
      .then((resp) => {
        dispatch(getUserAcceptedSolutions(params.user_id, params.auth_key));
      })
      .catch((err) => {
        console.log(err);
      });
}

export function getCategoryData(categoryName, solutionType) {
  return (dispatch) =>
    Api.get(`solution/getCategorySolutions/${categoryName}/${solutionType}`)
      .then((resp) => {
        if (solutionType === "needSolution") {
          dispatch(setNeedSolutions(resp.data));
        } else if (solutionType === "provideSolution") {
          dispatch(setProvideSolutions(resp.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}
