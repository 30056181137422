import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "./actions";


import DashBoard from "./Containers/DashboardContainer";
import StartinApplication from "./Containers/StartingAppContainer";
import Delivery from "./Containers/DeliveryContainer";
import Vegetables from "./Containers/VegetablesContainer";
import Cereal from "./Containers/CerealContainer";
import Space from "./Containers/SpaceContainer";
import Equipment from "./Containers/EquipmentContainer";
import Services from "./Containers/ServicesContainer";
import YouPropose from "./Containers/YouProposeContainer";
import Transport from "./Containers/TransportContainer";
import Clients from "./Containers/ClientsContainer";
import Suppliers from "./Containers/SuppliersContainer";
import Profile from "./Containers/ProfileContainer";
import Fruits from "./Containers/FruitsContainer";
import LiveStock from "./Containers/LiveStockContainer";
import InputContainer from "./Containers/InputContainer";
import Animal from "./Containers/AnimalContainer";
import Herbal from "./Containers/HearbalContainer";

import NeedSolution from "./Components/NeedSolution";
import ProvideSolution from "./Components/ProvideSolution";

import LanguageCurrency from "./Components/Language&Currency";
import ClientsDetail from "./Components/ClientsDetails";
import SupplierDetails from "./Components/SupplierDetails";
import About from "./Components/About";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ClientNeedMatch from "./Components/ClientNeedsMatch";
import SupplierSolutionMatch from "./Components/SupplierSolutionMatch";
import ClientOfferAccepted from "./Components/ClientOfferAccepted";
import SelectionMap from "./Components/Maps/SelectionMap";
import MyOffers from "./Components/MyOffers";
import MyOrders from "./Components/MyOrders";
import GetUsers from "./Components/getAllUsers";
import Subscriptions from "./Components/Subscription";
import SuccessUrl from "./Components/SuccessUrl";
import CancelUrl from "./Components/CancelUrl";
import SubscriptionScreen from "./Components/SubscriptionScreen";
import YearlySubscriptionSuccessUrl from "./Components/YearlySubscriptionSuccess";

import {
  Login,
  ForgetPassword,
  CodeVerification,
  ResetPassword,
  SignUpWithId,
  RegisterCountry,
  IDAccountCreation,
} from "./Components/Auth";

import { NotificationContainer } from "react-notifications";

import RestrictedRoute from "./utils/routes/restrictedRoutes";
import PublicRoute from "./utils/routes/publicRoutes";

import { I18nPropvider } from "./i18nProvider";
import MiddleWare from "./Components/Auth/middleware";

function checkAuthenticated() {
  return localStorage.getItem("isAuthenticated");
}

class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInterval: false,
    };
  }
  componentDidMount() {
    this.clearCache();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!localStorage.getItem("currency")) {
      localStorage.setItem("currency", "dollar");
    }
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en-us");
    }
    if (!this.state.timeInterval && currentUser && currentUser._id) {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.props.getNotifications(currentUser._id);
    }
    this.fetchNotification();
    navigator.geolocation.getCurrentPosition(
      position => {
        clearTimeout(setTimeout(10000));
        this.props.Coords(position.coords)
      },
      () => { },
      { timeout: 60000 }
    );
  }
  componentWillReceiveProps() {
    this.fetchNotification();
  }

  clearCache = () => {
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
  };

  fetchNotification = () => {
    this.setState(
      {
        timeInterval: true,
      },
      () => {
        if (this.state.timeInterval) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));

          if (currentUser && currentUser?._id) {
            setInterval(() => {
              const currentUser = JSON.parse(
                localStorage.getItem("currentUser")
              );
              this.props.getNotifications(currentUser?._id);
            }, 300000);
          }
        }
      }
    );
  };
  // const [locale, setLocale] = useState(LOCALES.ENGLISH);
  render() {
    const authenticate = checkAuthenticated();
    const { isAuthenticated } = this.props;
    return (
      <I18nPropvider locale={localStorage.getItem("language")}>
        <Switch>
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/subscriptionscreen"
            component={SubscriptionScreen}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/subscriptionsuccess"
            component={YearlySubscriptionSuccessUrl}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/selectionmap/:type/:id"
            component={SelectionMap}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/myoffers"
            component={MyOffers}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/myorders"
            component={MyOrders}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/clientofferaccept"
            component={ClientOfferAccepted}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/suppliersolution/:id"
            component={SupplierSolutionMatch}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/clientneedsmatch/:id"
            component={ClientNeedMatch}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/supplierdetails/:id/:userId"
            component={SupplierDetails}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/clientsdetails/:id/:userId"
            component={ClientsDetail}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/profile"
            component={Profile}
          />
          <Route
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/languageCurrency"
            component={LanguageCurrency}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/successurl"
            component={SuccessUrl}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/cancelUrl"
            component={CancelUrl}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/suppliers"
            component={Suppliers}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/clients"
            component={Clients}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/equipment"
            component={Equipment}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/needsolutionform"
            component={NeedSolution}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/providesolutionform"
            component={ProvideSolution}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/space"
            component={Space}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/youpropose"
            component={YouPropose}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/tranport"
            component={Transport}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/services"
            component={Services}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/delivery"
            component={Delivery}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/map"
            component={DashBoard}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/subscriptions"
            component={Subscriptions}
          />
          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/dashboard"
            component={DashBoard}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/vegetables"
            component={Vegetables}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/cereal"
            component={Cereal}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/fruits"
            component={Fruits}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/liveStock"
            component={LiveStock}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/inputCategory"
            component={InputContainer}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/animal"
            component={Animal}
          />

          <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/herbs"
            component={Herbal}
          />
          <Route path="/accountcreation" component={IDAccountCreation} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/getUsers" component={GetUsers} />
          <Route path="/about" component={About} />
          {/* <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/startapp"
            component={StartinApplication}
          /> */}
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/login"
            component={Login}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/registerCountry"
            component={RegisterCountry}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/middleware"
            component={MiddleWare}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/register"
            component={SignUpWithId}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/forgetpassword"
            component={ForgetPassword}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/verifycode"
            component={CodeVerification}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/resetpassword"
            component={ResetPassword}
          />
          <PublicRoute
            storeAuthenticate={isAuthenticated}
            allowed={authenticate}
            path="/"
            component={StartinApplication}
          />
        </Switch>
        <NotificationContainer />
      </I18nPropvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
