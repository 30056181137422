import React from "react";
import { connect } from "react-redux";
import ActionCreators from "../actions";
import DashBoard from "../Components/Dashboard";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CountryModal from "../Components/CommonComponents/Modals/CountryModal";
import { SolutionModal } from "../Components/CommonComponents/Modals";
import { youPropose } from "../mockData";

class DashBoardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      selectedRoute: null,
      countryModal: false,
      selectedCountry: "",
      visible: false
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const lang = localStorage.getItem("language");
    this.props.getAllSolution(
      "needSolution",
      currentUser._id,
      lang,
      this.props.history
    );
    this.props.getAllSolution(
      "provideSolution",
      currentUser._id,
      lang,
      this.props.history
    );
    const country = JSON.parse(localStorage.getItem("isCountry"));
    if (!country) {
      this.setState({ countryModal: true });
    }
  }
  setRoute = (route, categoryName) => {
    this.setState(
      {
        selectedRoute: route,
      },
      () => {
        this.getCategory(categoryName);
      }
    );
  };

  getCategory = (categoryName) => {
    const { getCategoryData } = this.props;
    getCategoryData(categoryName, "needSolution");
    getCategoryData(categoryName, "provideSolution");
  };

  navigate = () => {
    const { selectedRoute } = this.state;
    if (selectedRoute) {
      this.setState({
        visible: !this.state.visible
      });
    } else {
      alert(
        localStorage.getItem("language") === "fr-ca"
          ? "Veuillez sélectionner la catégorie dans la barre ci-dessus"
          : "please select the category from above bar"
      );
    }
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  selectCountry = (val) => {
    this.setState({ selectedCountry: val });
  };

  handleCountryModal = async () => {
    if (this.state.selectedCountry !== "") {
      const params = {
        user_id: this.props.userId,
        country: this.state.selectedCountry,
      };
      await this.props.updateCountry(params);
      localStorage.setItem("isCountry", true);
      this.setState({ countryModal: false });
    }
  };

  handleCancelBtn = () => {
    this.setState({ countryModal: true });
  };

  handleModal = (string, data) => {
    if (string === "data") {
      this.setState(
        {
          category: youPropose
        });
    }
    this.setState({
      visible: !this.state.visible
    });
  };

  handlClick = value => {
    if (value === "need") {
      this.props.history.push("/needsolutionForm");
    } else if (value === "provide") {
      this.props.history.push("/providesolutionForm");
    }
  };

  render() {
    // const { match, location, history } = this.props;


    return (
      <>
        <DashBoard
          currentUser={this.props.currentUser}
          allNeedSolutions={this.props.allNeedSolutions}
          allProvideSolutions={this.props.allProvideSolutions}
          collapsed={this.state.collapsed}
          toggle={this.toggle}
          setRoute={this.setRoute}
          navigate={this.navigate}
        />
        {this.state.countryModal ? (
          <CountryModal
            visible={this.state.countryModal}
            selectCountry={this.selectCountry}
            handleModal={this.handleCountryModal}
            selectedCountry={this.state.selectedCountry}
            handleCancelBtn={this.handleCancelBtn}
          />
        ) : null}

        {this.state.selectedRoute === 'delivery' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Delivery"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'equipment' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Equipments"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}
        {this.state.selectedRoute === 'space' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Space"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}
        {this.state.selectedRoute === 'tranport' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Transport"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'services' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Services"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'vegetables' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Vegetables"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'cereal' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Cereals"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'fruits' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Fruits"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'liveStock' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Live Stock"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'inputCategory' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Input"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'animal' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Animal Feed"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}

        {this.state.selectedRoute === 'herbs' ? (
          <SolutionModal
            visible={this.state.visible}
            handleModal={this.handleModal}
            handleClick={this.handleClick}
            category="Herbs"
            subCategoryId='1'
            categoryData={youPropose}
          />) : null}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userId: state.auth.currentUser._id,
    allNeedSolutions: state.solutions.allNeedSolutions,
    allProvideSolutions: state.solutions.allProvideSolutions,
    coords: state.auth.coords
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashBoardContainer));
