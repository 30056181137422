import React, { Component } from "react";
import { Layout, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "../../../src/actions";
import SideNavigation from "../Shared/SideNavigation";
// import Menuite
import { Link } from "react-router-dom";
import TopNavigation from "../Shared/TopNavigation";
import "./styles.css";
import "../../../src/App.css";

const { Content } = Layout;

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      vis: false
    };
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };



  handleOkabout = () => {
    this.setState({ vis: true })
    this.props.handleLoginMessage(!this.props.LoginMessage);
  };

  handleCancel = () => {
    this.setState({ vis: false })
    this.props.handleLoginMessage(!this.props.LoginMessage);
  };

  render() {
    const { vis, collapsed } = this.state;
    return (
      <Layout >
        <SideNavigation collapsed={this.state.collapsed}
          handleOkabout={this.handleOkabout} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={collapsed}
            headingTitle="About"
          />
          <Content
            className="content-style thisstyle">
            <div className="modal-container">
              <Modal
                visible={vis}
                onOk={() => console.log('onok')}
                closable={true}
                footer={null}
                onCancel={this.handleCancel}>
                <div>
                  <div className="info-message-heading">
                    <FormattedMessage
                      id="info-message-heading"
                      defaultMessage="Gain from your resources:"
                    />
                  </div>
                  <div style={{ textAlign: "center", wordSpacing: "2px" }}>
                    <div>
                      <FormattedMessage
                        id="going-somewhere"
                        defaultMessage="-Going somewhere?"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="take-package"
                        defaultMessage="Take a package on your way!"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="unused-equipment"
                        defaultMessage="-Have equipment or services for rent? "
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="find-client"
                        defaultMessage="Post it and find clients for it!"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="post-green"
                        defaultMessage="Post your resources in Green
                    as a "
                      />
                      <span
                        className="solution-btn-message"
                        style={{ textAlign: "center", marginLeft: "5px", whiteSpace: 'nowrap' }}
                      >
                        <FormattedMessage id="solution" defaultMessage="Solution Provider" />
                      </span>
                    </div>
                    <div>
                      <FormattedMessage
                        id="post-blue"
                        defaultMessage="- Need something? Post your Needs in Blue
                    As a"
                      />
                      <span
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        className="need-btn-message"
                      >
                        <FormattedMessage id="need-message" defaultMessage="Client" />
                      </span>
                    </div>
                    <div>
                      <FormattedMessage
                        id="shareWithContacts"
                        defaultMessage="Share it with all your contacts"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="getPaid"
                        defaultMessage="Get paid for your Solution!"
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="findSolutionInfo"

                        defaultMessage="Find solutions to your Need!"
                      />
                    </div>
                  </div>
                  <Link to="/accountcreation">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="info-message-login">
                        <FormattedMessage
                          id="login-message"
                          defaultMessage="Sign Up"
                        />
                      </div>
                    </div>
                  </Link>
                  <Link to="/login">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div style={{ width: "30%" }} className="info-message-login">
                        <FormattedMessage id="login" defaultMessage="Login" />
                      </div>
                    </div>
                  </Link>
                </div>
                <br />
                <div
                  className='acha'
                >
                  <button
                    onClick={() => this.handleCancel()}
                    className="leave-btn acha">
                    <span style={{ color: "#fff", fontWeight: 500 }}>
                      <FormattedMessage id="leaveText" defaultMessage="Leave" />
                    </span>
                  </button>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => this.handleLanguage("en-us")}
                    style={{
                      cursor: "pointer",
                      color:
                        localStorage.getItem("language") === "fr-ca"
                          ? "none"
                          : "#A1947A",
                    }}
                  >
                    English
                  </div>
                  |
                  <div
                    onClick={() => this.handleLanguage("fr-ca")}
                    style={{
                      cursor: "pointer",
                      color:
                        localStorage.getItem("language") !== "fr-ca"
                          ? "none"
                          : "#A1947A",
                    }}
                  >
                    French
                  </div>
                </div>
              </Modal>
            </div>
            <div className="about-title">FR: À propos de Pickant App</div>
            <div>
              <p>
                Gagnez de l'argent maintenant! Gagnez de l'argent «au fait»! à
                travers Pickant App - une plate-forme de partage de livraison,
                de matériel et d'espace!
              </p>
              <p>
                Imaginez que vous puissiez gagner de l'argent à chaque fois que
                vous sortez de votre maison, à votre retour, ou que vous
                n'utilisez pas votre équipement ou votre espace supplémentaire
                ...!
              </p>
              <p>
                Utilisez cette application et effectuez des livraisons à
                destination, ou louez du matériel et de l'espace que vous
                n'utilisez pas
              </p>
              <p>OU</p>
              <p>Économisez de l'argent maintenant!</p>
              <p>
                Utilisez cette application et économisez de l'argent sur les
                livraisons effectuées par les utilisateurs qui vous entourent,
                ou louez du matériel et de l'espace non utilisés
              </p>
            </div>
            <div className="about-title">La description:</div>
            <p>
              Publiez des offres gratuitement en tant qu'utilisateur cherchant
              une solution (offre bleue) ou en tant qu'utilisateur proposant une
              solution (offre verte). Recherchez les utilisateurs ayant publié
              un besoin bleu et fournissez-leur votre solution payante. Ou
              recherchez des utilisateurs qui publient une solution verte qui
              pourrait répondre à votre besoin, choisissez-en une et payez-la.
            </p>
            <p>
              Gagnez de l'argent en proposant des solutions «au passage» lorsque
              vous vous rendez quelque part ou que vous n'utilisez pas
              l'équipement / l'espace que vous possédez, ou économisez de
              l'argent en trouvant des utilisateurs autour de vous qui offrent
              des solutions abordables de livraison ou de location d'équipement
              / d'espace.
            </p>
            <p>
              Publier un BESOIN / une SOLUTION est gratuit! Le paiement pour le
              partage se fait directement entre les utilisateurs. Pickant App
              prend une petite commission du fournisseur uniquement, via un
              abonnement annuel ou une approche «Pay as you go»! ('Payez comme
              vous allez')!
            </p>
            <p className="about-bold-paragraph">
              *Partagez cette application avec tous vos contacts et utilisez
              cette plate-forme pour gagner de l'argent ou économiser de
              l'argent. Plus il y a d'utilisateurs autour de vous, plus vous
              avez d'options à gagner et à économiser!
            </p>
            <div className="about-title">EN: Pickant App About Info</div>
            <p>
              Make money now! Make money "by the way"! through Pickant App - a
              platform for sharing delivery, equipment and space!
            </p>
            <p>
              Imagine you could make money every time you go out of your home or
              when coming back, or while not using equipment or space you
              own...!
            </p>
            <p>
              Use this app and take deliveries to your destination, or rent
              equipment & space you don’t use
            </p>
            <p>OR</p>
            <p>Save money NOW!</p>
            <p>
              Use this app and save money on deliveries through users around
              you, or rent equipment & space they don’t use
            </p>
            <div className="about-title">Description:</div>
            <p>
              Publish offers for free as a user who search for a solution (blue
              offer), or as a user who provides a solution (green offer). Look
              for users who published a blue need and provide them with your
              paid solution. Or look for users who publish a green solution that
              might answer your need, choose one and pay for it.
            </p>
            <p>
              Make money by providing solutions 'by the way' when you go
              somewhere or not using equipment/space you own, or save money by
              finding users around you who offer affordable solutions to
              delivery or equipment/space rent.
            </p>
            <p>
              Publishing a NEED/SOLUTION is for free! The payment for sharing is
              being done directly between the users. Pickant App takes a small
              commission from the supplier only, through a yearly subscription
              or 'Pay as you go' approach!
            </p>
            <p className="about-bold-paragraph">
              *Share this app with all of your contacts, and use this platform
              to make money or save money. The more users around you, the more
              options you have to gain and to save!
            </p>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    LoginMessage: state.auth.loginMessage,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(About);
