import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "../../../actions";
import SetLanguage from "../../Shared/setLanguge";

import "./styles.css";

class ForgetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const params = {
      mobile_no: this.state.mobileNumber,
      forget_password: true
    }
    this.props.sendCodeMessage(params, this.props.history, "forgetPassword")
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <div className="forget-container">
        <div className="form-wrapper">
          <div className="forget-heading-container">
            <h1 className="forget-heading">  <FormattedMessage
              id="forgetPassword"
              defaultMessage="Forget Password"
            /></h1>
          </div>
          <Form onSubmit={this.handleSubmit} className="forget-form">
            <Form.Item>
              <FormattedMessage
                id="sendCodePlaceholder"
                defaultMessage="Enter Mobile Number (+171741XXXXX)"
              >
                {placeholder => (
                  <Input
                    prefix={
                      <Icon
                        type="mobile"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    name="mobileNumber"
                    onChange={this.handleChange}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Form.Item>

            <Form.Item>
              <div className="forget-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="forget-form-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage id="sendCode" defaultMessage="Send Code" />
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <SetLanguage />
      </div>
    );
  }
}

const WrappedforgetPasswordForm = Form.create({ name: "forget-password-form" })(
  ForgetPasswordForm
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedforgetPasswordForm);
