import createReducer from "../store/createReducer";
import * as types from "../utils/constants";

const solutions = createReducer(
  { loading: false },
  {
    [types.SET_NEED_SOLUTIONS](state, action) {
      return {
        ...state,
        allNeedSolutions: action.allNeedSolutions,
      };
    },
    [types.SET_PROVIDE_SOLUTIONS](state, action) {
      return {
        ...state,
        allProvideSolutions: action.allProvideSolutions,
      };
    },
    [types.SET_SOLUTION](state, action) {
      return {
        ...state,
        solution: action.solution,
      };
    },
    [types.SET_USER_SOLUTIONS](state, action) {
      return {
        ...state,
        userSolutions: action.userSolutions,
      };
    },
    [types.SET_USER_ACCEPTED_SOLUTIONS](state, action) {
      return {
        ...state,
        userAcceptedSolutions: action.userAcceptedSolutions,
      };
    },

    [types.SOLUTION_ACCEPTED](state, action) {
      return {
        ...state,
        solutionAccepted: action.solutionAccepted,
      };
    },
    [types.IS_LOADING](state, action) {
      return {
        ...state,
        loading: action.loading,
      };
    },
    [types.SET_CATEGORY_DATA](state, action) {
      return {
        ...state,
        categoryName: action.categoryName,
      };
    },

  }
);
export default solutions;
