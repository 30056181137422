import React from "react";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import delivery from "../../Assests/transparentLogos/bike.png";
import equipment from "../../Assests/transparentLogos/man_and_tractor.png";
import transport from "../../Assests/transparentLogos/car.png";
import home from "../../Assests/transparentLogos/house.png";
import services from "../../Assests/transparentLogos/setting.png";
import googleMarker from "../../Assests/google-marker.png";

import vegetables from "../../Assests/transparentLogos/vegetables.png";
import cereal from "../../Assests/transparentLogos/corn.png";
import liveStock from "../../Assests/transparentLogos/cow_and_hen_.png";
import fruits from "../../Assests/transparentLogos/fruits.png";
import animalFeed from "../../Assests/transparentLogos/hen.png";
import herbs from "../../Assests/transparentLogos/leaf.png";
import input from "../../Assests/transparentLogos/seeds.png";
import vipImage from "../../Assests/vip_symbol.png";

import "./styles.css";

const Marker = () => {
  return (
    <div>
      <div className="pulse" />
    </div>
  );
};

const Marker3 = ({ color, solution }) => {
  return (
    <Popover
      title=" "
      content={
        <Link
          to={solution && solution.user_role === "needSolution" ? `clientsdetails/${solution && solution?._id}/${solution && solution?.user?._id}` : `supplierdetails/${solution && solution?._id}/${solution && solution?.user?._id}`}
        >

          <p className="marker3-desp-paragraph">
            <span className="marker3-title">
              <FormattedMessage id="from" defaultMessage="From" />:
            </span>{" "}
            {solution && solution.pickup_street_address}
          </p>
          <p className="marker3-desp-paragraph">
            <span className="marker3-title">
              <FormattedMessage id="to" defaultMessage="To" />:
            </span>{" "}
            {solution && solution.delivery_street_address}
          </p>
          <p className="marker3-desp-paragraph">
            <span className="marker3-title">
              <FormattedMessage
                id="pickup/deliveryDate"
                defaultMessage="Pickup/Delivery Date"
              />
              :
            </span>{" "}
            {solution && solution.pickup_date}/
            {solution && solution.delivery_date}
          </p>
          <p className="marker3-desp-paragraph">
            <span className="marker3-title">
              <FormattedMessage
                id="subCategoryFinal"
                defaultMessage="Details"
              />
              :
            </span>{" "}
            {solution.description}

          </p>
        </Link>
      }
      trigger="click"
    >
      <div className="main">
        <div style={{ backgroundColor: color }} className="box">
          {solution && solution.images[0] ? (
            <div>
              <img
                className="mapUserImage"
                alt=''
                src={solution && solution.images[0]}
              />
            </div>
          ) : (
            <img alt=''
              src={
                solution && solution.category === "Delivery"
                  ? delivery
                  : solution && solution.category === "Equipments"
                    ? equipment
                    : solution && solution.category === "Space"
                      ? home
                      : solution && solution.category === "Transport"
                        ? transport
                        : solution && solution.category === "Services"
                          ? services
                          : solution && solution.category === "Vegetables"
                            ? vegetables
                            : solution && solution.category === "Herbs"
                              ? herbs
                              : (solution && solution.category === "Live Stock") ||
                                (solution && solution.category === "liveStock")
                                ? liveStock
                                : (solution && solution.category === "Inputs") ||
                                  (solution && solution.category === "inputCategory")
                                  ? input
                                  : (solution && solution.category === "Cereals") ||
                                    (solution && solution.category === "cereal")
                                    ? cereal
                                    : (solution && solution.category === "animal") ||
                                      (solution && solution.category === "Animal Feeds")
                                      ? animalFeed
                                      : (solution && solution.category === "fruits") ||
                                        (solution && solution.category === "Fruits")
                                        ? fruits
                                        : null
              }
              className="box-image"
            />
          )}

          {solution && solution?.user?.verified ? (
            <div className="verifiedId">+ID</div>
          ) : null}

          {solution?.user?.vip ? (
            <img src={vipImage} alt='' className="vipImage" />
          ) : null}
          <img alt=''
            src={
              solution && solution.category === "Delivery"
                ? delivery
                : solution && solution.category === "Equipments"
                  ? equipment
                  : solution && solution.category === "Space"
                    ? home
                    : solution && solution.category === "Transport"
                      ? transport
                      : solution && solution.category === "Services"
                        ? services
                        : solution && solution.category === "Vegetables"
                          ? vegetables
                          : solution && solution.category === "Herbs"
                            ? herbs
                            : (solution && solution.category === "Live Stock") ||
                              (solution && solution.category === "liveStock")
                              ? liveStock
                              : (solution && solution.category === "Inputs") ||
                                (solution && solution.category === "inputCategory")
                                ? input
                                : (solution && solution.category === "Cereals") ||
                                  (solution && solution.category === "cereal")
                                  ? cereal
                                  : (solution && solution.category === "animal") ||
                                    (solution && solution.category === "Animal Feeds")
                                    ? animalFeed
                                    : (solution && solution.category === "fruits") ||
                                      (solution && solution.category === "Fruits")
                                      ? fruits
                                      : null
            }
            className="mapTopImage"
          />
          <button className="mapDollarBtn">
            {solution.display_price &&
              solution.display_price.split(" ")[1] !== "undefined" ? (
              solution.display_price
            ) : (
              <>
                {localStorage.getItem("currency") === "dollar"
                  ? `$ ${solution && solution.sub_category_price_dollar} `
                  : localStorage.getItem("currency") === "euro"
                    ? `€ ${solution && solution.sub_category_price_euro} `
                    : `CFA ${solution && solution.sub_category_price_fr} `}
              </>
            )}
          </button>
          <div style={{ backgroundColor: color }} className="arrow"></div>
        </div>
      </div>
    </Popover>
  );
};

const Marker4 = ({ color, solution, handleOk }) => {
  return (
    <div onClick={handleOk} className="main">
      <div className="main">
        <div style={{ backgroundColor: color }} className="box">
          {solution && solution.images[0] ? (
            <div>
              <img
                className="mapUserImage"
                alt=''
                src={solution && solution.images[0]}
              />
            </div>
          ) : (
            <img
              alt=''
              src={
                solution && solution.category === "Delivery"
                  ? delivery
                  : solution && solution.category === "Equipments"
                    ? equipment
                    : solution && solution.category === "Space"
                      ? home
                      : solution && solution.category === "Transport"
                        ? transport
                        : solution && solution.category === "Services"
                          ? services
                          : solution && solution.category === "Vegetables"
                            ? vegetables
                            : solution && solution.category === "Herbs"
                              ? herbs
                              : (solution && solution.category === "Live Stock") ||
                                (solution && solution.category === "liveStock")
                                ? liveStock
                                : (solution && solution.category === "Inputs") ||
                                  (solution && solution.category === "inputCategory")
                                  ? input
                                  : (solution && solution.category === "Cereals") ||
                                    (solution && solution.category === "cereal")
                                    ? cereal
                                    : (solution && solution.category === "animal") ||
                                      (solution && solution.category === "Animal Feeds")
                                      ? animalFeed
                                      : (solution && solution.category === "fruits") ||
                                        (solution && solution.category === "Fruits")
                                        ? fruits
                                        : null
              }
              className="box-image"
            />
          )}

          {solution && solution?.user?.verified ? (
            <div className="verifiedId">+ID</div>
          ) : null}

          {solution?.user?.vip ? (
            <img src={vipImage} alt='' className="vipImage" />
          ) : null}
          <img alt=''
            className="mapTopImage"
            src={
              solution && solution.category === "Delivery"
                ? delivery
                : solution && solution.category === "Equipments"
                  ? equipment
                  : solution && solution.category === "Space"
                    ? home
                    : solution && solution.category === "Transport"
                      ? transport
                      : solution && solution.category === "Services"
                        ? services
                        : solution && solution.category === "Vegetables"
                          ? vegetables
                          : solution && solution.category === "Herbs"
                            ? herbs
                            : (solution && solution.category === "Live Stock") ||
                              (solution && solution.category === "liveStock")
                              ? liveStock
                              : (solution && solution.category === "Inputs") ||
                                (solution && solution.category === "inputCategory")
                                ? input
                                : (solution && solution.category === "Cereals") ||
                                  (solution && solution.category === "cereal")
                                  ? cereal
                                  : (solution && solution.category === "animal") ||
                                    (solution && solution.category === "Animal Feeds")
                                    ? animalFeed
                                    : (solution && solution.category === "fruits") ||
                                      (solution && solution.category === "Fruits")
                                      ? fruits
                                      : null
            }
          />
          <button className="mapDollarBtn">
            {solution.display_price &&
              solution.display_price.split(" ")[1] !== "undefined" ? (
              solution.display_price
            ) : (
              <>
                {localStorage.getItem("currency") === "dollar"
                  ? `$ ${solution && solution.sub_category_price_dollar} `
                  : localStorage.getItem("currency") === "euro"
                    ? `€ ${solution && solution.sub_category_price_euro} `
                    : `CFA ${solution && solution.sub_category_price_fr} `}
              </>
            )}
          </button>
          <div style={{ backgroundColor: color }} className="arrow"></div>
        </div>
      </div>
    </div>
  );
};

const Marker2 = () => {
  return (
    <div>
      <div className="marker-2" />
      <img className="marker2-img" alt='' src={googleMarker} />
    </div>
  );
};

export { Marker, Marker2, Marker3, Marker4 };
