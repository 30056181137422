import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Layout } from "antd";
import { FormattedMessage } from "react-intl";

import SideNavigation from "../../Shared/SideNavigation";
import TopNavigation from "../../Shared/TopNavigation";
import { getLocation } from "../../HOC";
import { Marker, Marker2 } from "../../customMarker";
import "./styles.css";

const { Content } = Layout;

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 15,
      userLocation: {},
      collapsed: false
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  onClickMap = key => {
    this.setState({
      userLocation: {
        lat: key.lat,
        lng: key.lng
      }
    });
  };

  handleNext = () => {
    const url = this.props.location.state.url;
    if (this.state.userLocation.lng && this.state.userLocation.lat) {
      this.props.history.push({
        pathname: `/${url}`,
        state: {
          lng: this.state.userLocation.lng,
          lat: this.state.userLocation.lat,
          category: this.props.match.params.type,
          subcategoryId: this.props.match.params.id,
          categoryData: this.props.location.state.categoryData
        }
      });
    } else {
      this.props.history.push({
        pathname: `/${url}`,
        state: {
          lng: this.props.userLocation.lng,
          lat: this.props.userLocation.lat,
          category: this.props.match.params.type,
          subcategoryId: this.props.match.params.id,
          categoryData: this.props.location.state.categoryData
        }
      });
    }
  };

  render() {
    return (
      <Layout>
        <SideNavigation collapsed={this.state.collapsed} />
        <Layout>
          <TopNavigation
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            headingTitle={
              localStorage.getItem("language") === "fr-ca"
                ? "Sélectionnez l'emplacement sur la carte"
                : "Select Location Click On The Map"
            }
            selectionPage={true}
          />
          <Content
            style={{
              padding: 10,
              height: "100vh",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                height: this.props.height ? this.props.height : "100vh",
                width: "100%",
                position: "relative"
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyC7G5Aa4ej2G7IMOocrMwiyIWlIc_0W__I"
                }}
                center={
                  this.state.userLocation.lat && this.state.userLocation.lng
                    ? this.state.userLocation
                    : this.props.userLocation && this.props.userLocation
                }
                defaultZoom={this.state.zoom}
                yesIWantToUseGoogleMapApiInternals
                onClick={this.onClickMap}
                onChildClick={this._onChildClick}
              >
                <Marker2
                  lat={
                    this.state.userLocation.lat
                      ? this.state.userLocation.lat
                      : this.props.userLocation && this.props.userLocation.lat
                  }
                  lng={
                    this.state.userLocation.lng
                      ? this.state.userLocation.lng
                      : this.props.userLocation && this.props.userLocation.lng
                  }
                />

                <Marker
                  lat={this.props.userLocation && this.props.userLocation.lat}
                  lng={this.props.userLocation && this.props.userLocation.lng}
                />
              </GoogleMapReact>
              <div onClick={this.handleNext} className="next-button-map">
                <FormattedMessage id="next" defaultMessage="Next" />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default getLocation(GoogleMap);
