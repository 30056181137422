import React from "react";

import Transport from "../Components/Transport";
import { transport } from "../mockData";

class TransportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      visible: false,
    };
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleModal = (string, data) => {
    if (string === "data") {
      this.setState({
        category: data,
      });
    }
    this.setState({
      visible: !this.state.visible,
    });
  };

  handlClick = (value) => {
    if (value === "need") {
      this.props.history.push("/needsolutionForm");
    } else if (value === "provide") {
      this.props.history.push("/providesolutionForm");
    }
  };

  render() {
    return (
      <Transport
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        transport={transport}
        handleModal={this.handleModal}
        visible={this.state.visible}
        handleClick={this.handlClick}
        category={this.state.category}
      />
    );
  }
}

export default TransportContainer;
